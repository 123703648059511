<template>
  <section class="price" id="price">
    <div class="container">
      <div class="section__header">
        <h1 class="section__title">Ценность и <span class="text-blue">стоимость</span></h1>
        <p class="section__subtitle"><span class="text-gray">5 дней бесплатно, тестовый период с полным функционалом</span></p>
      </div>
      <div class="section__body">
        <div class="price__tarifs">
          <div class="price__tarif-items -green">
            <div class="price__tarif-head">
              <h3 class="price__tarif-name">Тариф «АРЕНДА»</h3>
              <svg class="price__tarif-head-bg" width="372" height="124" viewBox="0 0 372 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.7893 108.539C9.3214 107.978 2.98641 104.982 1.42007 102.736C0.843364 101.909 1.02391 100.807 1.02391 99.7992L1.02391 16C1.02391 7.16345 8.18736 0 17.0239 0L354.976 0C363.813 0 370.976 7.16344 370.976 16V99.7991C370.976 100.807 371.157 101.909 370.58 102.736C369.014 104.982 362.679 107.978 345.211 108.539C318.23 109.406 318.959 112.441 314.827 114.897C310.695 117.353 304.132 119.81 281.04 117.859C257.949 115.908 235.343 115.258 218.085 119.882C204.279 123.581 190.914 124 186 124C181.086 124 167.721 123.581 153.915 119.882C136.657 115.258 114.051 115.908 90.9597 117.859C67.8681 119.81 61.3052 117.353 57.173 114.897C53.0408 112.441 53.7701 109.406 26.7893 108.539Z" fill="#1D1534"/>
                <path d="M26.7893 108.539C9.3214 107.978 2.98641 104.982 1.42007 102.736C0.843364 101.909 1.02391 100.807 1.02391 99.7992L1.02391 16C1.02391 7.16345 8.18736 0 17.0239 0L354.976 0C363.813 0 370.976 7.16344 370.976 16V99.7991C370.976 100.807 371.157 101.909 370.58 102.736C369.014 104.982 362.679 107.978 345.211 108.539C318.23 109.406 318.959 112.441 314.827 114.897C310.695 117.353 304.132 119.81 281.04 117.859C257.949 115.908 235.343 115.258 218.085 119.882C204.279 123.581 190.914 124 186 124C181.086 124 167.721 123.581 153.915 119.882C136.657 115.258 114.051 115.908 90.9597 117.859C67.8681 119.81 61.3052 117.353 57.173 114.897C53.0408 112.441 53.7701 109.406 26.7893 108.539Z" fill="#0C9E05"/>
              </svg>
            </div>
            <div class="price__tarif-body">
              <ul class="price__tarif-list">
                <li>Ежемесячные платежи</li>
                <li>Поддержка платформы</li>
                <li>Личный менеджер</li>
                <li>Бесплатная консультация</li>
                <li>Онлайн прием платежей</li>
                <li>Тестирование</li>
                <li>Генерация сертификатов</li>
              </ul>
              <div class="price__tarif-count">
                <!-- <p>45 000 &#8376; /мес</p> -->
              </div>
              <!-- <button class="price__tarif-btn" @click="freeApplicationFormOpen('«АРЕНДА»')">Выбрать тариф</button> -->
              <a href="/tariffs" class="price__tarif-btn">Посмотреть</a>
            </div>
          </div>
          <div class="price__tarif-items -blue">
            <div class="price__tarif-head">
              <h1 class="price__tarif-name">Тариф «ПОКУПКА» </h1>
              <svg class="price__tarif-head-bg" width="372" height="124" viewBox="0 0 372 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.7893 108.539C9.3214 107.978 2.98641 104.982 1.42007 102.736C0.843364 101.909 1.02391 100.807 1.02391 99.7992L1.02391 16C1.02391 7.16345 8.18736 0 17.0239 0L354.976 0C363.813 0 370.976 7.16344 370.976 16V99.7991C370.976 100.807 371.157 101.909 370.58 102.736C369.014 104.982 362.679 107.978 345.211 108.539C318.23 109.406 318.959 112.441 314.827 114.897C310.695 117.353 304.132 119.81 281.04 117.859C257.949 115.908 235.343 115.258 218.085 119.882C204.279 123.581 190.914 124 186 124C181.086 124 167.721 123.581 153.915 119.882C136.657 115.258 114.051 115.908 90.9597 117.859C67.8681 119.81 61.3052 117.353 57.173 114.897C53.0408 112.441 53.7701 109.406 26.7893 108.539Z" fill="#1D1534"/>
                <path d="M26.7893 108.539C9.3214 107.978 2.98641 104.982 1.42007 102.736C0.843364 101.909 1.02391 100.807 1.02391 99.7992L1.02391 16C1.02391 7.16345 8.18736 0 17.0239 0L354.976 0C363.813 0 370.976 7.16344 370.976 16V99.7991C370.976 100.807 371.157 101.909 370.58 102.736C369.014 104.982 362.679 107.978 345.211 108.539C318.23 109.406 318.959 112.441 314.827 114.897C310.695 117.353 304.132 119.81 281.04 117.859C257.949 115.908 235.343 115.258 218.085 119.882C204.279 123.581 190.914 124 186 124C181.086 124 167.721 123.581 153.915 119.882C136.657 115.258 114.051 115.908 90.9597 117.859C67.8681 119.81 61.3052 117.353 57.173 114.897C53.0408 112.441 53.7701 109.406 26.7893 108.539Z" fill="#0C9E05"/>
              </svg>
            </div>
            <div class="price__tarif-body">
              <ul class="price__tarif-list">
                <li>Интегрированная платежная система для рассрочки 0*0*12 <span class="-new">NEW</span></li>
                <li>Мобильное приложение V2 <span class="-new">NEW</span></li>
                <li>Загрузка одного курса на платформу <span class="-new">NEW</span></li>
                <li>Выбор модулей для приложения <span class="-new">NEW</span></li>
                <li>Интеграция платежной системы <span class="-new">NEW</span></li>
                <li>Защита персональных данных <span class="-new">NEW</span></li>
                <li>Регистрация действия пользователей <span class="-new">NEW</span></li>
                <li>Интеграция с внешними кросс платформами <span class="-new">NEW</span></li>
                <li>Интеграция с социальными сетями <span class="-new">NEW</span></li>
                <li>Неограниченное количество курсов</li>
                <li>Онлайн прием платежей
                  <span class="-pay">
                        <img src="../../../../assets/icons/pay/ic-paybox.png" alt="icon ">
                        <img src="../../../../assets/icons/pay/ic-paypost.png" alt="icon ">
                        <img src="../../../../assets/icons/pay/ic-robokassa.png" alt="icon ">
                        <img src="../../../../assets/icons/pay/ic-wallet-one.png" alt="icon ">
                        <img src="../../../../assets/icons/pay/ic-cloudpayments.png" alt="icon ">
                        <img src="../../../../assets/icons/pay/ic-kaspi.png" alt="icon ">
                  </span>
                </li>
                <li>Свой домен и бесплатный хостинг на год (до 10 GB хранилище)</li>
                <li>Собственная платформа с ежеквартальным обновлением</li>
                <li>Рейтинг</li>
                <li>Техническая поддержка вашей платформы</li>
                
                <li>Мобильное приложение в подарок
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.84375 7.43672C3.84375 7.43672 2.33203 7.55625 2.13047 7.8961C1.92656 8.23594 1.91484 8.7586 1.91484 9.33282C1.91484 9.90938 1.8914 11.2313 1.8914 11.2313L2.25234 12.007C2.25234 12.007 2.32031 21.8953 2.32031 22.2023C2.32031 22.507 2.32031 22.8797 2.76093 23.1164C3.20156 23.3531 14.4 28.7625 14.7492 28.9078C15.2766 29.1258 15.5273 29.0438 15.5273 29.0438L27.9703 8.2336C27.9703 8.2336 27.9305 7.66407 27.4125 7.52813C27.1148 7.45313 3.84375 7.43672 3.84375 7.43672V7.43672Z" fill="#F5B03E"/>
                    <path d="M15.3937 14.2781C15.2039 14.8008 15.2906 20.2055 15.3234 24.1359C15.3562 28.0641 15.2226 28.8773 15.3234 29.0133C15.4242 29.1492 15.6984 29.018 16.4742 28.6734C17.9156 28.0359 24.8391 24.6094 25.3476 24.3047C25.8562 24 27.0984 23.3695 27.3023 23.0297C27.5062 22.6898 27.6164 19.5281 27.6844 16.9898C27.7523 14.4492 27.8203 12.4172 27.8203 12.4172L28.125 11.5031C28.125 11.5031 28.0594 9.03282 28.0476 8.76094C28.0266 8.31094 27.9773 8.07891 27.8625 8.13516C27.6984 8.2125 26.3297 9.33516 22.7062 11.0977C20.4305 12.2016 15.525 13.9172 15.3937 14.2781V14.2781Z" fill="#E07F14"/>
                    <path d="M15.4266 2.62735C14.4117 2.58047 11.6344 4.18594 8.95781 5.06485C6.28125 5.94375 2.24062 7.71094 2.20547 7.81407C2.17265 7.91485 3.83203 8.65782 4.8914 9.16641C6.15 9.76875 10.9547 12.0445 11.8336 12.4172C12.7148 12.7898 14.8148 13.6617 15.1523 13.6359C15.6258 13.6031 20.0977 11.8406 22.3664 10.7578C24.6352 9.675 27.4711 7.99688 27.5086 7.58907C27.525 7.40625 23.3367 5.84297 21.2484 4.89844C17.2172 3.07266 16.1695 2.6625 15.4266 2.62735V2.62735Z" fill="#FDD717"/>
                    <path d="M1.90312 11.6648C1.93828 12.1055 2.17968 12.2625 2.79374 12.5297C3.40781 12.7969 15.0164 18.8273 15.2836 18.8273C15.5508 18.8273 15.6305 17.8711 15.3375 17.8969C15.1781 17.9109 11.3648 16.0992 7.80234 14.2898C4.7789 12.757 1.88437 11.2266 1.88437 11.2266L1.90312 11.6648V11.6648Z" fill="#E37D14"/>
                    <path d="M28.1274 11.482C28.1274 11.482 15.7313 17.7867 15.6094 17.8477C15.4875 17.9086 15.293 17.8875 15.293 17.8875L15.2836 18.8273C15.2836 18.8273 15.3984 18.8531 15.5274 18.8133C15.6024 18.7898 27.7688 12.4922 27.8227 12.4734C27.8766 12.4547 28.1109 12.3141 28.1602 12.0164C28.2024 11.7773 28.1274 11.482 28.1274 11.482V11.482Z" fill="#BA5E0D"/>
                    <path d="M6.76406 22.8328L9.18984 26.4422L10.2516 26.8055C10.2516 26.8055 10.3383 22.868 10.3734 19.95C10.4226 15.6867 10.4039 11.7984 10.4648 11.5781C10.5656 11.2055 15.2836 9.675 15.2836 9.675C15.2836 9.675 20.6133 11.3461 20.782 11.7188C20.857 11.8828 20.7984 14.6438 20.782 17.8617C20.7609 21.9258 20.768 26.618 20.768 26.618C20.768 26.618 21.3187 26.6156 21.3844 26.4117C21.4523 26.2078 21.7242 24.6352 21.7242 24.6352L23.7914 10.8563L24.8414 9.50156L23.3836 6.72656L17.4023 3.78281L15.3469 4.24453L13.1227 3.90469L6.06796 8.97422L6.89062 10.643L8.90859 11.6719L6.76406 22.8328V22.8328Z" fill="#AF0F1B"/>
                    <path d="M21.3937 11.5758C21.2648 11.8594 21.3398 26.4727 21.3398 26.4727C21.3398 26.4727 21.9844 26.1164 22.8141 25.718C23.6391 25.3219 24.232 25.0758 24.2836 24.8695C24.3352 24.6633 24.4266 10.2117 24.4266 10.2117L21.3937 11.5758Z" fill="#DC0D28"/>
                    <path d="M9.6914 11.2734C9.76874 11.3578 9.64218 26.5219 9.48515 26.557C9.36093 26.5875 6.53671 25.3266 6.53671 25.1977C6.53671 25.0688 6.76406 10.5773 6.76406 10.5773C6.76406 10.5773 9.46171 11.0156 9.6914 11.2734ZM15.2883 3.33984C14.5078 3.33984 13.6453 3.5625 13.3945 4.21641C13.1437 4.87031 13.0875 5.84297 13.0875 6.56953C13.0875 7.26563 12.975 9.04688 15.1195 9.01875C17.2641 8.99063 17.3273 8.19609 17.3601 6.44297C17.3883 4.98047 17.1937 4.27031 16.9992 3.96563C16.8258 3.69141 16.2492 3.33984 15.2883 3.33984V3.33984Z" fill="#FF2A23"/>
                    <path d="M14.0484 6.95859C14.5359 6.98672 14.7445 5.91562 15.1195 5.32969C15.4945 4.74609 15.8859 4.35469 15.7453 4.05C15.6305 3.79687 14.6109 3.71484 14.0602 4.48125C13.6734 5.02266 13.6055 6.93281 14.0484 6.95859Z" fill="#FCC9D2"/>
                    <path d="M17.1867 3.84844C17.1867 3.84844 17.8781 4.55157 18.0586 5.92969C18.2391 7.30782 18.0305 8.37891 18.0305 8.37891C18.0305 8.37891 21.4664 8.27578 22.6523 8.50313C23.8219 8.73047 24.2297 9.2461 24.2297 9.6211C24.2297 9.9961 23.4609 10.2984 22.6805 10.2422C21.9 10.1859 21.3727 10.1438 21.2742 10.3406C21.1758 10.5375 21.3164 10.8422 22.4016 10.9242C23.4867 11.0086 24.7406 10.8539 25.1297 9.50391C25.5188 8.15391 25.5328 5.81485 25.3945 4.12969C25.2563 2.44453 24.9516 1.48125 24.1969 1.11563C23.4023 0.733596 21.4125 0.670315 19.5328 1.72735C17.6531 2.78438 17.1867 3.84844 17.1867 3.84844V3.84844Z" fill="#FF2A23"/>
                    <path d="M21.0797 2.24531C20.8359 1.93125 20.0508 1.94062 19.6312 2.42578C19.1859 2.9414 19.0875 4.90312 19.6594 5.01562C20.3062 5.14219 20.1891 4.08281 20.6625 3.4289C21.1266 2.78906 21.3164 2.55234 21.0797 2.24531V2.24531Z" fill="#FCC9D2"/>
                    <path d="M13.2141 3.91641C13.2141 3.91641 10.582 0.84844 7.57499 0.895315C5.76562 0.92344 5.30624 1.96641 5.20781 2.39766C5.10937 2.82891 4.35937 7.11797 5.36015 9.44063C6.36093 11.7633 8.43749 11.1609 8.76327 11.0859C9.18046 10.9875 10.0687 10.6828 9.98437 10.3617C9.89999 10.0406 6.94921 11.1961 6.92109 9.87422C6.89296 8.55235 9.00937 8.41172 9.98437 8.3836C10.9594 8.35547 12.6305 8.57813 12.6305 8.57813C12.6305 8.57813 12.3375 6.97735 12.532 5.83594C12.7266 4.69453 13.2141 3.91641 13.2141 3.91641V3.91641Z" fill="#FF2A23"/>
                    <path d="M6.76874 2.0086C6.39374 2.39766 6.23906 4.64063 6.47578 5.75391C6.71249 6.86719 6.92109 7.34063 7.40859 7.24453C7.8164 7.1625 8.0625 4.97813 8.25703 4.20938C8.46562 3.38907 8.98125 2.51719 8.77265 2.13516C8.4539 1.54922 7.16015 1.60313 6.76874 2.0086Z" fill="#FCC9D2"/>
                  </svg>

                </li>
              </ul>
              <div class="price__tarif-count">
                <!-- <span>2 999 990 &#8376;</span>
                <p>2 500 000 &#8376;</p> -->
              </div>
              <!-- <button class="price__tarif-btn" @click="freeApplicationFormOpen('«ПОКУПКА»')">Выбрать тариф</button> -->
              <a href="/tariffs" class="price__tarif-btn">Посмотреть</a>
            </div>
          </div>
          <div class="price__tarif-items -yellow">
            <div class="price__tarif-head">
              <h1 class="price__tarif-name">Тариф «ENTERPRISE»</h1>
              <svg class="price__tarif-head-bg" width="372" height="124" viewBox="0 0 372 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.7893 108.539C9.3214 107.978 2.98641 104.982 1.42007 102.736C0.843364 101.909 1.02391 100.807 1.02391 99.7992L1.02391 16C1.02391 7.16345 8.18736 0 17.0239 0L354.976 0C363.813 0 370.976 7.16344 370.976 16V99.7991C370.976 100.807 371.157 101.909 370.58 102.736C369.014 104.982 362.679 107.978 345.211 108.539C318.23 109.406 318.959 112.441 314.827 114.897C310.695 117.353 304.132 119.81 281.04 117.859C257.949 115.908 235.343 115.258 218.085 119.882C204.279 123.581 190.914 124 186 124C181.086 124 167.721 123.581 153.915 119.882C136.657 115.258 114.051 115.908 90.9597 117.859C67.8681 119.81 61.3052 117.353 57.173 114.897C53.0408 112.441 53.7701 109.406 26.7893 108.539Z" fill="#1D1534"/>
                <path d="M26.7893 108.539C9.3214 107.978 2.98641 104.982 1.42007 102.736C0.843364 101.909 1.02391 100.807 1.02391 99.7992L1.02391 16C1.02391 7.16345 8.18736 0 17.0239 0L354.976 0C363.813 0 370.976 7.16344 370.976 16V99.7991C370.976 100.807 371.157 101.909 370.58 102.736C369.014 104.982 362.679 107.978 345.211 108.539C318.23 109.406 318.959 112.441 314.827 114.897C310.695 117.353 304.132 119.81 281.04 117.859C257.949 115.908 235.343 115.258 218.085 119.882C204.279 123.581 190.914 124 186 124C181.086 124 167.721 123.581 153.915 119.882C136.657 115.258 114.051 115.908 90.9597 117.859C67.8681 119.81 61.3052 117.353 57.173 114.897C53.0408 112.441 53.7701 109.406 26.7893 108.539Z" fill="#0C9E05"/>
              </svg>
            </div>
            <div class="price__tarif-body">
              <ul class="price__tarif-list">
                <li>Интегрированная платежная система для рассрочки 0*0*12 <span class="-new">NEW</span></li>
                <li>Дизайн личного бренда <span class="-new">NEW</span></li>
                <li>Мобильное приложение V2 <span class="-new">NEW</span></li>
                <li>Выбор модулей для приложения <span class="-new">NEW</span></li>
                <li>Интеграция платежной системы <span class="-new">NEW</span></li>
                <li>Индивидуальная разработка под ваши требования</li>
                <li>Разработка под определенный аналог или ТЗ по проекту</li>
                <li>Мобильное приложение под ваши требования </li>
              </ul>
              <div class="price__tarif-count">
                <!-- <p style="font-size: 18px; line-height: 120%; color: #ff3b3b;">Под ваши индивидуальные требования после покупки тарифа с мобильным приложением</p> -->
              </div>
              <!-- <button class="price__tarif-btn" @click="freeApplicationFormOpen('«ENTERPRISE»')">Выбрать тариф</button> -->
              <a href="/tariffs" class="price__tarif-btn">Посмотреть</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalForm :isAppForm="isApplicationForm" :modalClose="modalClose" :tariffName="tariffName" :tariffInnerName="'-'"/>

    <ModalSuccess :modalClose="modalClose" />

  </section>
</template>

<script>

import 'maz-ui/css/main.css'
import ModalForm from '@/components/Modals/Form/Tarrifs.vue'
import ModalSuccess from '@/components/Modals/Success.vue'

   export default {
      data() {
        return {
          isApplicationForm: false,
          tariffName: '«АРЕНДА»',
          tariffInnerName: '3 месяца',
        }
      },
      components: {
        ModalForm, ModalSuccess
      },
      methods: {
        freeApplicationFormOpen(tariff_name) {
          this.isApplicationForm = !this.isApplicationForm
          this.tariffName = tariff_name
        },
        modalClose() {
          this.isApplicationForm = false
          document.querySelector('.main__modal.success').style.display = 'none'
        },
      },
   }
</script>

<style scoped>
.price__tarif-items.-green .price__tarif-list li{
  background: url(../../../../assets/icons/ic-list-dot-green.svg) no-repeat left center;
}
.price__tarif-items.-blue .price__tarif-list li{
  background: url(../../../../assets/icons/ic-list-dot-blue.svg) no-repeat left center;
}
.price__tarif-items.-yellow .price__tarif-list li{
  background: url(../../../../assets/icons/ic-list-dot-yellow.svg) no-repeat left center;
}
</style>