<template>
  <section class="tariffs-page" id="tariffs-page">
    <div class="container">
      <div class="page__header">
        <h1 class="page__title">Тарифы</h1>
        <p class="page__subtitle">5 дней бесплатно, тестовый период с полным функционалом</p>
      </div>
      <div class="page__body">
        <div class="left">
          <div class="card">
            <div class="tariffs-page__content">
              <div class="tariffs-page__form">
                <div class="tariffs-page__form-block">
                  <div class="tariffs-page__tabs">
                    <div class="tariffs-page__tab-items">
                      <label @click="openTariff('«АРЕНДА»', '1 месяц', 25000)" :class="{'active': tariffName == '«АРЕНДА»'}">
                        <img src="../assets/images/img-logo-for-radio-blue.svg" class="radio-logo-edu-blue" style="border-radius: 50%; width: 52px; height: 52px;" alt="">
                        <img src="../assets/images/img-logo-for-radio-white.svg" class="radio-logo-edu-white" style="border-radius: 50%; width: 52px; height: 52px;" alt="">
                        <div class="tariffs-page__tab-text">
                          <h3 class="tariffs-page__tab-name">Аренда</h3>
                          <div class="tariffs-page__tab-price">
                            <p>от 25 000 &#8376;</p>
                          </div>
                        </div>
                        <span class="checkmark"></span>
                      </label>
                      <div class="tariffs-page__tab-additional" :class="{'show': tariffAddShow}">
                        <table>
                          <thead>
                            <tr class="tariffs-page__tab-add-sales">
                              <th></th>
                              <th>Память</th>
                              <th>Пользователи</th>
                              <th>Скидка</th>
                              <th>Цена</th>
                              <th>Выбрать</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="tariffs-page__tab-add-sales" @click="tariffInner('1 месяц')">
                              <td>
                                <label for="add-radio0">
                                  <h4 class="tariffs-page__tab-add-month">1 месяц</h4>
                                </label>
                              </td>
                              <td><label for="add-radio0"><span class="addtext">Память: </span> 1 GB</label></td>
                              <td><label for="add-radio0"><span class="addtext">Пользователи: </span> 500</label></td>
                              <td></td>
                              <td>
                                <label for="add-radio0">
                                  <div class="tariffs-page__tab-add-price">
                                    <h3 class="tariffs-page__tab-add-price-new">25 000 &#8376;</h3>
                                  </div>
                                </label>
                              </td>
                              <td>
                                <div class="switch">
                                  <span class="switch-radio" :class="{'checked': tariffInnerName == '1 месяц'}"></span>
                                  <label for="add-radio0" class="switch-label"></label>
                                </div>
                              </td>
                            </tr>
                            <tr class="tariffs-page__tab-add-sales" @click="tariffInner('3 месяца')">
                              <td>
                                <label for="add-radio1">
                                  <h4 class="tariffs-page__tab-add-month">3 месяца</h4>
                                </label>
                              </td>
                              <td><label for="add-radio1"><span class="addtext">Память: </span> 2 GB</label></td>
                              <td><label for="add-radio1"><span class="addtext">Пользователи: </span> 1 000</label></td>
                              <td><p class="tariffs-page__tab-add-price-sale-count">-20%</p></td>
                              <td>
                                <label for="add-radio1">
                                  <div class="tariffs-page__tab-add-price">
                                    <div class="tariffs-page__tab-add-price-sale">
                                      <div class="tariffs-page__tab-add-price-old">
                                        <p>75 000 &#8376;</p>
                                      </div>
                                    </div>
                                    <h3 class="tariffs-page__tab-add-price-new">60 000 &#8376;</h3>
                                  </div>
                                </label>
                              </td>
                              <td>
                                <div class="switch">
                                  <span class="switch-radio" :class="{'checked': tariffInnerName == '3 месяца'}"></span>
                                  <label for="add-radio1" class="switch-label"></label>
                                </div>
                              </td>
                            </tr>
                            <tr class="tariffs-page__tab-add-sales" @click="tariffInner('6 месяцев')">
                              <td>
                                <label for="add-radio2">
                                  <h4 class="tariffs-page__tab-add-month">6 месяцев</h4>
                                </label>
                              </td>
                              <td><label for="add-radio2"><span class="addtext">Память: </span> 3 GB</label></td>
                              <td><label for="add-radio2"><span class="addtext">Пользователи: </span> 2 000</label></td>
                              <td><p class="tariffs-page__tab-add-price-sale-count">-30%</p></td>
                              <td>
                                <label for="add-radio2">
                                  <div class="tariffs-page__tab-add-price">
                                    <div class="tariffs-page__tab-add-price-sale">
                                      <div class="tariffs-page__tab-add-price-old">
                                        <p>150 000 &#8376;</p>
                                      </div>
                                    </div>
                                    <h3 class="tariffs-page__tab-add-price-new">120 000 &#8376;</h3>
                                  </div>
                                </label>
                              </td>
                              <td>
                                <div class="switch">
                                  <span class="switch-radio" :class="{'checked': tariffInnerName == '6 месяцев'}"></span>
                                  <label for="add-radio2" class="switch-label"></label>
                                </div>
                              </td>
                            </tr>
                            <tr class="tariffs-page__tab-add-sales" @click="tariffInner('12 месяцев')">
                              <td>
                                <label for="add-radio3">
                                  <h4 class="tariffs-page__tab-add-month">12 месяцев</h4>
                                </label>
                              </td>
                              <td><label for="add-radio3"><span class="addtext">Память: </span> 5 GB</label></td>
                              <td><label for="add-radio3"><span class="addtext">Пользователи: </span> 3 000</label></td>
                              <td><p class="tariffs-page__tab-add-price-sale-count">-40%</p></td>
                              <td>
                                <label for="add-radio3">
                                  <div class="tariffs-page__tab-add-price">
                                    <div class="tariffs-page__tab-add-price-sale">

                                      <div class="tariffs-page__tab-add-price-old">
                                        <p>300 000 &#8376;</p>
                                      </div>
                                    </div>
                                    <h3 class="tariffs-page__tab-add-price-new">180 000 &#8376;</h3>
                                  </div>
                                </label>
                              </td>
                              <td>
                                <div class="switch">
                                  <span class="switch-radio" :class="{'checked': tariffInnerName == '12 месяцев'}"></span>
                                  <label for="add-radio3" class="switch-label"></label>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                      </div>
                      <div class="tariffs-additional__mobile">
                        <div class="tariffs-additional__item">

                        </div>
                      </div>
                    </div>
                    <div class="tariffs-page__tab-items">
                      <label for="radio2" @click="openTariff('«ПОКУПКА»', '-', 1500000)" :class="{'active': tariffName == '«ПОКУПКА»'}">
                        <img src="../assets/images/img-logo-for-radio-blue.svg" class="radio-logo-edu-blue" style="border-radius: 50%; width: 52px; height: 52px;" alt="">
                        <img src="../assets/images/img-logo-for-radio-white.svg" class="radio-logo-edu-white" style="border-radius: 50%; width: 52px; height: 52px;" alt="">
                        <div class="tariffs-page__tab-text">
                          <h3 class="tariffs-page__tab-name">Покупка</h3>
                          <div class="tariffs-page__tab-price">
                            <div>
                              <span>1 999 990 &#8376;</span>
                            </div>
                            <p>1 500 000 &#8376;</p>
                          </div>
                        </div>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="tariffs-page__tab-items">
                      <label for="radio3" @click="openTariff('«ENTERPRISE»', '-', '300 000 - 1 000 000')" :class="{'active': tariffName == '«ENTERPRISE»'}">
                        <img src="../assets/images/img-logo-for-radio-blue.svg" class="radio-logo-edu-blue" style="border-radius: 50%; width: 52px; height: 52px;" alt="">
                        <img src="../assets/images/img-logo-for-radio-white.svg" class="radio-logo-edu-white" style="border-radius: 50%; width: 52px; height: 52px;" alt="">
                        <div class="tariffs-page__tab-text">
                          <h3 class="tariffs-page__tab-name">Enterprise</h3>
                          <div class="tariffs-page__tab-price">
                            <p style="text-align: right">от 300 000 &#8376; - <br>1 500 000 &#8376;</p>
                          </div>
                        </div>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="card">
            <div class="price-block">
              <p class="tariff-page__price">{{ price }} &#8376;</p>
              <button type="submit" class="tariffs-page__tab-btn btn-green" @click="freeApplicationFormOpen(tariffName)">Выбрать тариф</button>
            </div>
            <div class="tariffs-page__tabs-result">
              <div class="tariffs-page__tab-result-items" v-if="tariffName === '«АРЕНДА»'" id="tariffs-tab-result-1">
                <p class="tariff-page__name">Тариф {{tariffName}} ({{tariffInnerName}})</p>
                <ul>
                  <li>Ежемесячные платежи</li>
                  <li>Поддержка платформы</li>
                  <li>Личный менеджер</li>
                  <li>Бесплатная консультация</li>
                  <li>Онлайн прием платежей</li>
                  <li>Тестирование</li>
                  <li>Генерация сертификатов</li>
                </ul>
              </div>
              <div class="tariffs-page__tab-result-items" v-else-if="tariffName === '«ПОКУПКА»'" id="tariffs-tab-result-2">
                <p class="tariff-page__name">Тариф {{tariffName}}</p>
                <ul>
                  <li>Интегрированная платежная система для рассрочки 0*0*12 <span class="-new">NEW</span></li>
                  <li>Дизайн личного бренда <span class="-new">NEW</span></li>
                  <li>Мобильное приложение V2 <span class="-new">NEW</span></li>
                  <li>Загрузка одного курса на платформу <span class="-new">NEW</span></li>
                  <!-- <li>Два таргетных видеоролика <span class="-new">NEW</span></li> -->
                  <li>Выбор модулей для приложения <span class="-new">NEW</span></li>
                  <li>Интеграция платежной системы <span class="-new">NEW</span></li>
                  <li>Неограниченное количество курсов</li>
                  <li>Личный менеджер</li>
                  <!-- <li>Бесплатная консультация</li> -->
                  <li>Онлайн прием платежей</li>
                  <li>Свой домен и бесплатный хостинг на год</li>
                  <!-- <li>Тестирование</li> -->
                  <li>Выдача сертификатов</li>
                  <li>Собственный портал с бесплатным обновлением</li>
                  <li>Рейтинг</li>
                  <!-- <li>Промокоды</li> -->
                  <li>Техническая поддержка вашей платформы</li>
                  <li>Мобильное приложение в подарок</li>
                </ul>
              </div>
              <div class="tariffs-page__tab-result-items" v-else id="tariffs-tab-result-3">
                <p class="tariff-page__name">Тариф {{tariffName}}</p>
                <ul>
                  <li>Интегрированная платежная система для рассрочки 0*0*12 <span class="-new">NEW</span></li>
                  <li>Дизайн личного бренда <span class="-new">NEW</span></li>
                  <li>Мобильное приложение V2 <span class="-new">NEW</span></li>
                  <li>Загрузка одного курса на платформу <span class="-new">NEW</span></li>
                  <li>Выбор модулей для приложения <span class="-new">NEW</span></li>
                  <li>Интеграция платежной системы <span class="-new">NEW</span></li>
                  <li>Индивидуальная разработка под ваши требования</li>
                  <li>Разработка под определенный аналог или ТЗ по проекту</li>
                  <li>Мобильное приложение под ваши требования </li>
                  <li>Возможность принимать онлайн платежи </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Message />

    <ModalForm :is-app-form="isApplicationForm" :modal-close="modalClose" :tariff-name="tariffName" :tariff-inner-name="tariffInnerName" :tariff-price="price"/>

    <ModalSuccess :modalClose="modalClose" />
  </section>

  <BackToTop />

</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import FaqBody from '@/components/FaqBody.vue';
import BackToTop from '@/components/BackToTop.vue';

import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import { notify  } from '@kyvg/vue3-notification';
import 'vue3-tel-input/dist/vue3-tel-input.css'
import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import ModalForm from '@/components/Modals/Form/Tarrifs.vue'
import ModalSuccess from '@/components/Modals/Success.vue'
import Message from '@/components/Message.vue'

export default {
    data() {
      return {
        tariffAddShow: false,
        isApplicationForm: false,
        tariffName: '«ПОКУПКА»',
        tariffInnerName: '1 месяц',
        active: false,
        price: 1500000,
        allFaqs: [
          {
            question: "Если у меня будет больше пользователей, чем вмещает тариф?",
            answer: "",
            open: false
          },
          {
            question: "Какие возможны способы оплаты ?",
            answer: "Оплатить тариф можно банковской картой Visa/Mastercard, а также через Яндекс.Деньги, WebMoney и Qiwi Кошелек. Чтобы оплатить по безналичному расчету, напишите нам",
            open: false
          },
          {
            question: "Могу ли я в любое время сменить тарифный план?",
            answer: "",
            open: false
          },
          {
            question: "Как оформить тарифный план на индивидуальных условиях?",
            answer: "",
            open: false
          },
        ],
        props: ['faq', 'id', 'open', 'toggleOpen'],
      }
    },
    components: { Header, Footer, FaqBody, VueTelInput, ModalForm, ModalSuccess, BackToTop, MazPhoneNumberInput, Message },
    methods: {
      toggleOpen: function (index) {
        this.allFaqs = this.allFaqs.map(faq => {
          if (index == faq.id  ) {
            faq.open = !faq.open;
          } else {
            faq.open = false;
          }
          return faq;
        });
      },
      tariffInner(name) {
        if (name === '1 месяц') {
          this.tariffInnerName = name
          this.price = 25000
          this.price = this.price.toLocaleString("fi-FI")
        } else if (name === '3 месяца') {
          this.tariffInnerName = name
          this.price = 60000
          this.price = this.price.toLocaleString("fi-FI")
        } else if (name === '6 месяцев') {
          this.tariffInnerName = name
          this.price = 120000
          this.price = this.price.toLocaleString("fi-FI")
        } else if (name === '12 месяцев') {
          this.tariffInnerName = name
          this.price = 180000
          this.price = this.price.toLocaleString("fi-FI")
        }

      },
      openTariff(tariff_name, tariff_month, tariff_price) {
        if (tariff_name == '«АРЕНДА»') {
          this.tariffAddShow = !this.tariffAddShow
          this.tariffName = tariff_name
          this.price = tariff_price
          this.tariffInnerName = '1 месяц'
          this.price = this.price.toLocaleString("fi-FI")
        }
        else if (tariff_name == '«ПОКУПКА»') {
          this.tariffAddShow = false
          this.tariffName = tariff_name
          this.tariffInnerName = tariff_month
          this.price = tariff_price
          this.price = this.price.toLocaleString("fi-FI")
        }
        else if (tariff_name == '«ENTERPRISE»') {
          this.tariffAddShow = false
          this.tariffName = tariff_name
          this.tariffInnerName = tariff_month
          this.price = tariff_price
          this.price = this.price.toLocaleString("fi-FI")
        }
      },
      freeApplicationFormOpen(tariff_name) {
        this.isApplicationForm = !this.isApplicationForm
        this.tariffName = tariff_name
      },
      modalClose() {
          this.isApplicationForm = false
          document.querySelector('.main__modal.success').style.display = 'none'
      },
    },
    mounted() {
      this.price = this.price.toLocaleString("fi-FI")
    }
}
</script>

<style scoped>
  .page__body {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  .left {
    width: 65%;
  }
  .right {
    width: 35%;
  }
  .card {
    background-color: var(--white);
    padding: 20px;
    border: 1px solid rgba(0,0,0,.06);
    border-radius: 6px;
  }

  .tariffs-page {
    display: flex;
  }

  .tariffs-page__form-block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  .tariffs-page__tabs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .tariffs-page__tab-items {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  .tariffs-page__tab-items th,
  .tariffs-page__tab-items td {
    padding: 10px 16px;
  }

  .tariffs-page__tab-items > label.active {
    background: var(--main-color);
    color: var(--white);
  }
  .tariffs-page__tab-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .tariffs-page__tab-price div {
    font-size: 15px;
    text-decoration: line-through;
    font-weight: 500;
    color: red;
  }

  .tariffs-page__tab-price div span {
    color: #91969e;
  }
  .tariffs-page__tab-items > label.active .tariffs-page__tab-price div span {
    color: var(--white);
  }

  .tariffs-page__tab-items > label {
    padding: 15px 16px;
    width: auto;
    display: block;
    text-align: left;
    color: #3C454C;
    cursor: pointer;
    position: relative;
    z-index: 0;
    transition: color 200ms ease-in;
    overflow: hidden;
    display: flex;
    align-items: center;
    column-gap: 20px;
    border-radius: 6px;
  }

  .radio-logo-edu-white {
    display: none;
  }

  .tariffs-page__tab-items input:checked ~ label .radio-logo-edu-white {
    display: block;
  }
  .tariffs-page__tab-items input:checked ~ label  .radio-logo-edu-blue {
    display: none;
  }


  .tariffs-page__tab-items input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    //visibility: hidden;
    visibility: visible;
  }
  .tariffs-page__tab-items .checkmark {
    opacity: 0;
    width: 0;
    height: 0;
  }


  .tariffs-page__tab-additional {
    display: none;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    padding: 15px;
    background: #f5faff;
    border-radius: 6px;
  }

  .tariffs-page__tab-additional.show {
    display: flex;
  }

  .add-radio:checked ~ label {
    background: red;
  }


  .tariffs-page__tab-add-sales {
    width: 100%;
    position: relative;
    margin: 10px;
  }

  .tariffs-page__tab-add-sales label {
    display: flex;
    row-gap: 7px;
    justify-content: center;
  }

  .tariffs-page__tab-add-sales label .addtext {
    display: none;
  }

  .tariffs-page__tab-add-sales td:nth-child(1) label {
    justify-content: flex-start;
  }

  .tariffs-page__tab-add-sales label::before {
    background-color: var(--main2-color);
  }

  .tariffs-page__tab-add-sales input:checked ~ tr{
    background: red;
  }

  .tariffs-page__tab-add-price {
    display: flex;
    column-gap: 7px;
    align-items: center;
    flex-direction: column;
  }

  .tariffs-page__tab-add-price-sale {
    display: flex;
    column-gap: 7px;
  }

  .tariffs-page__tab-add-price-sale-count {
    background: red;
    font-size: 12px;
    color: var(--white);
    border-radius: 6px;
    padding: 2.5px 5px;
    width: 50px;
    margin: auto;
  }

  .tariffs-page__tab-add-price-old {
    text-decoration: line-through;
    color: red;
  }

  .tariffs-page__tab-add-price-old p {
    color: #91969e;
  }

  .tariffs-page__tab-add-sales input:checked ~ label p {
    color: var(--white);
  }

  .add-radio {
    /* position: relative !important;
    visibility: visible !important;
    width: 15px !important;
    height: 15px !important; */
  }

  .add-radio {

  }

  .tariffs-page__tab-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    column-gap: 30px;
  }

  .tariffs-page__tabs-result {
  }

  .tariffs-page__tab-result-items {
    padding: 15px 0;
    display: block;
    margin-top: 35px;
    border-top: 1px solid #c5c5c5;
  }
  

  .tariffs-page__tab-result-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .tariffs-page__tab-result-items ul li {
    display: flex;
    align-items: center;
    gap: 7px;
    background: url(../assets/icons/ic-tariff-list-done.svg) no-repeat left;
    padding: 8px 8px 8px 30px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    color: var(--black);
  }

  .tariffs-page__tab-result-items ul li .small-text {
    font-size: 12px;
  }

  .tariffs-page__tab-result-items ul li .-new {
    background: red;
    font-size: 10px;
    color: var(--white);
    border-radius: 6px;
    padding: 2.5px 5px;
  }

  .tariffs-page__tab-btn {
    font-family: "SF Pro Display";
    margin: 0 auto;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 20px;
    padding: 10px;
    width: 100%;
  }

  .faq {
    display: flex;
  }

  .switch {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .switch-radio {
    position: absolute;
    visibility: hidden !important;
  }

  .switch-label {
    cursor: pointer;
    padding-right: 35px;
    position: relative;
    display: block;
    font-size: 18px;
    padding: 15px 21px;
  }

  .switch-radio + .switch-label::before,
  .switch-radio + .switch-label::after
  {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -12.5px;
    box-sizing: border-box;
  }

  .switch-radio + .switch-label::before {
    width: 44px;
    height: 24.5px;
    right: -4.5px;
    background: #fff;
    border: 1px solid #e4e3e1;
    border-radius: 15px;
  }
  .switch-radio + .switch-label::after {
    width: 17px;
    height: 17px;
    right: 19px;
    background: #BDBDBD;
    border-radius: 50%;
    transition: all 200ms ease-out;
    margin-top: -8.5px;
  }

  .switch-radio.checked + .switch-label::after {
    right: 0px;
    background: var(--main2-color);
  }

  .tariff-page__price {
    font-size: 24px;
    font-weight: 700;
  }

  .tariff-page__name {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 7px;
  }

  @media (max-width: 1400px) {
    .left {
      width: 70%;
    }
    .right {
      width: 30%;
    }
  }
  @media (max-width: 1200px) {
    .page__body {
      flex-direction: column;
    }
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
    }
    .price-block {
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 20px;
      background: var(--white);
      z-index: 100;
      border-top: 1px solid #f0f0f0;
    }
    .tariffs-page__tab-result-items {
      border-top: 0;
      margin-top: 0;
      padding: 0;
    }
  }
  @media (max-width: 991px) {
    .tariffs-page__form-block {
      flex-direction: column;
    }
    .tariffs-page__tabs {
      gap: 10px;
    }
    .tariffs-page__tab-result-items {
      border-top: 1px solid #C4C8CC;
      border-left: none;
    }
    .tariffs-page__tab-additional {
      padding: 0;
    }
    .tariffs-page__tab-add-sales {
      display: flex;
      flex-direction: column;
      width: auto;
      padding-bottom: 10px;
      border-bottom: 1px solid #dbdada;
    }
    .tariffs-page__tab-add-sales label {
      gap: 7px;
    }
    .tariffs-page__tab-add-sales label .addtext {
      display: block;
    }
    .tariffs-page__tab-add-sales:nth-last-child(1) {
      border-bottom: 0;
    }
    .tariffs-page__tab-items thead {
      display: none;
    }
    .tariffs-page__tab-items td {
      padding: 5px;
    }
    .tariffs-page__tab-add-price {
      justify-content: center;
    }
    .tariffs-page__tab-result-items {
      border-top: none;
    }
    .card {
      padding: 0;
      border: none;
    }
    .radio-logo-edu-blue {
      width: 40px !important;
      height: 40px !important;
    }
  }
  @media (max-width: 768px) {
    
  }
  @media (max-width: 600px) {
    .tariffs-page__tabs-result,
    .tariffs-page__tabs {
      width: 100%;
    }
    .tariffs-page__tab-items {
      width: auto;
    }
    .tariffs-page__tab-text {
      font-size: 13px;
    }
    .tariffs-page__tab-result-items ul li {
      font-size: 13px;
    }
    .tariffs-page__tab-btn {
      font-size: 18px;
      margin-top: 10px;
    }
    .tariffs-page__tab-additional {
      grid-template-columns: 1fr;
      gap: 10px;
    }
    .tariffs-page__tab-add-sales label {
      flex-direction: row;
      justify-content: center;
    }
    .tariffs-page__tab-add-price {
      flex-direction: row;
      column-gap: 10px;
    }
    .tariffs-page__tab-price {
      flex-direction: row;
      gap: 7px;
    }
    .tariffs-page__tab-price div {
      font-size: 13px;
    }
  }
  @media (max-width: 500px) {
    .tariffs-page__tab-add-sales label {
      flex-direction: column;
    }

    .tariffs-page__tab-price {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
    .tariffs-page__tab-text {
      flex-direction: row;
    }
    .tariffs-page__tab-price {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
    .tariffs-page__tab-text {
      flex-direction: row;
    }
  }
  @media (max-width: 414px) {
  
  }
  @media (max-width: 390px) {
    .tariffs-page__tab-text {
      font-size: 13px;
      flex-direction: row;
      gap: 6px;
      align-items: center;
    }
  }
  @media (max-width: 375px) {

  }
  @media (max-width: 360px) {

  }
  @media (max-width: 320px) {

  }
</style>