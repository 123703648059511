import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/MainPage/Index.vue";
import About from "@/views/About.vue";
import Tariffs from "@/views/Tariffs.vue";
import Tariffs2 from "@/views/TariffsNew.vue";
import Services from "@/views/Services.vue";
import Blog from "@/views/Blog.vue";
import BlogInner from "@/views/BlogInner.vue";
import Faq from "@/views/Faq.vue";
import CreatePlatform from "@/views/CreatePlatform.vue";
import LeaseExpired from "@/views/LeaseExpired.vue";
import Thanks from "@/views/Thanks.vue";
import Help from "@/views/Help.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/tariffs",
    name: "Tariffs",
    component: Tariffs,
  },
  {
    path: "/tariffs2",
    name: "Tariffs2",
    component: Tariffs2,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/blog/1",
    name: "BlogInner",
    component: BlogInner,
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/create-platform",
    name: "CreatePlatform",
    component: CreatePlatform,
  },
  {
    path: "/lease-expired",
    name: "LeaseExpired",
    component: LeaseExpired,
  },
  {
    path: "/thanks",
    name: "Thanks",
    component: Thanks,
  },
  {
    path: '/help',
    name: "Help",
    component: Help,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;