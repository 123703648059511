<template>
  <section class="help" id="help">
    <div class="container">
      <div class="page__header">
        <h1 class="page__title">Помощь</h1>
      </div>
      <div class="middle">
        <div class="aside">
          <ul>
            <li v-for="(tab, index) in tabs" :key="index"
                @click="changeTab(index)"
                :class="{ 'active': activeTab === index }">
              {{ tab.title }}
            </li>
          </ul>
        </div>
        <div class="content" v-for="(tab, index) in tabs" :key="index" v-show="activeTab === index">
          <div v-html="tab.content"></div>
          <button @click="openVideo(tab.video)" v-if="tab.video">Видео</button>
        </div>
      </div>

    </div>
    <div class="media-component size-small">
      <modal-video :channel="videoChannel" :videoId="videoId" v-model:isOpen="videoIsOpen" />
    </div>
  </section>
</template>

<script>
import ModalVideo from '@/components/Modals/Video.vue'
export default {
    data() {
      return {
        activeTab: 0,
        videoId: '',
        videoIsOpen: false,
        tabs: [
          {
            title: 'Как войти в свой аккаунт?',
            content: '<h3>Как войти в свой аккаунт?</h3>' +
                '<ol>' +
                '<li>Зайти на платформу</li>' +
                '<li>Нажать на кнопку Войти</li>' +
                '<li>Ввести ваши данные на полях</li>' +
                '<li>Нажать кнопку Войти</li>' +
                '</ol>',
            video: false,
          },
          {
            title: 'Как зарегистрироватся на сайте?',
            content: '<h3>Как зарегистрироватся на сайте?</h3>' +
                '<ol>' +
                '<li>Зайти на платформу</li>' +
                '<li>Нажать на кнопку Регистрация</li>' +
                '<li>Заполнить ваши данные на полях</li>' +
                '<li>Принять условия соглашения</li>' +
                '<li>Нажать кнопку создать</li>' +
                '</ol>',
            video: false,
          },
          {
            title: 'Как зайти в Админку?',
            content: '<h3>Как зайти в Админку?</h3>' +
                '<ol>' +
                '<li>Иметь аккаунт с доступом Админа</li>' +
                '<li>Зайти в свой аккаунт</li>' +
                '<li>Нажать кнопку Админка расположенная слева в меню</li>' +
                '</ol>',
            video: false,
          },
          {
            title: 'Как выйти с аккаунта?',
            content: '<h3>Как выйти с аккаунта?</h3>' +
                '<ol>' +
                '<li>Навести курсор на кнопку мой профиль</li>' +
                '<li>Нажать на кнопку двери</li>' +
                '</ol>',
            video: false,
          },
          {
            title: 'Как добавить курс?',
            content: '<h3>Как добавить курс?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Зайти на страницу Курсы расположенная слева в меню</li>' +
                '<li>Нажать кнопку добавить</li>' +
                '<li>Добавить данные в полях</li>' +
                '<li>Нажать кнопку добавить запись</li>' +
                '</ol>' +
                '<p>Для дополнительной информации можете просмотреть видеинструкцию </p>',
            video: 'Bd2xBGUnHX8?si=jJPv8_ruSAV3k5CR',
          },
          {
            title: 'Как добавить урок на курс?',
            content: '<h3>Как добавить урок на курс?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Зайти на страницу Курсы расположенная слева</li>' +
                '<li>Нажать кнопку Глава в настройках курса</li>' +
                '<li>Нажать кнопку Добавить</li>' +
                '<li>Добавить Главу</li>' +
                '<li>Нажать кнопку Уроки в настройках главы</li>' +
                '<li>Нажать Добавить</li>' +
                '<li>Заполнить данные</li>' +
                '<li>Нажать кнопку добавить запись</li>' +
                '</ol>',
            video: false,
          },
          {
            title: 'Как добавить Тесты в урок?',
            content: '<h3>Как добавить Тесты в урок?</h3>' +
                '<ol>' +
                '<li>Иметь созданный урок</li>' +
                '<li>Зайти в настройки урока</li>' +
                '<li>Переключить тип урока на тип ‘С Тестами’</li>' +
                '<li>Нажать кнопку Обновить запись</li>' +
                '<li>Перейти на страницу урока</li>' +
                '<li>Нажать кнопку Тесты в настройках урока</li>' +
                '<li>Добавить тест</li>' +
                '<li>Нажать кнопку добавить запись</li>' +
                '</ol>' +
                '<p>Для дополнительной информации можете просмотреть видеинструкцию </p>',
            video: '-dnsfavnEzI?si=o5_9O49NpU-soegi',
          },
          {
            title: 'Что делать если я не вижу модули у себя на платформе?',
            content: '<h3>Что делать если я не вижу модули у себя на платформе?</h3>' +
                '<p>Пожалуйста зайдите на страницу Админки>Настройки>Модули и удостовертесь что нужные вам модулы были подключены. По умолчанию некоторые модули могут быть отключены. Для дополнительной информации о модулях можете нажать на эту кнопку</p>',
            video: 'opS4H-_XEd8?si=JV8xx9H6khD4IHyC',
          },
          {
            title: 'Как добавить тесты?',
            content: '<h3>Как добавить тесты?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Зайти на страницу Варианты расположенная слева в меню</li>' +
                '<li>Нажать кнопку Добавить</li>' +
                '<li>Заполнить данные</li>' +
                '<li>Нажать кнопку Добавить запись</li>' +
                '<li>Нажать кнопку Вопросы созданного варианта</li>' +
                '<li>Нажать кнопку Добавить</li>' +
                '<li>Добавить вопросы варианта</li>' +
                '<li>Нажать кнопку Добавить запись</li>' +
                '<li>Зайти на страницу Тесты расположенная слева в меню</li>' +
                '<li>Нажать кнопку Добавить</li>' +
                '<li>Заполнить данные</li>' +
                '<li>Нажать кнопку Добавить запись</li>' +
                '<li>Нажать кнопку Варианты</li>' +
                '<li>Нажать кнопку Добавить</li>' +
                '<li>Выбрать созданный вариант</li>' +
                '<li>Нажать кнопку Добавить запись</li>' +
                '</ol>' +
                '<p>Для дополнительной информации можете просмотреть видеинструкцию </p>',
            video: 'H7ywsIsi9sc?si=9xrTrKSTotfhGE8k',
          },
          {
            title: 'Как добавить задания в курсы?',
            content: '<h3>Как добавить задания в курсы?</h3>' +
                '<p>Добавление задания в курс идет аналогично добавлению теста в курс. Можете посмотреть по ссылке как добавлять тест или же ознакомится с видеинструкцией </p>',
            video: '-dnsfavnEzI?si=o5_9O49NpU-soegi',
          },
          {
            title: 'Как добавить группы?',
            content: '<h3>Как добавить группы?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Нажать на кнопку Группы расположенную слева в меню</li>' +
                '<li>Нажать кнопку Добавить</li>' +
                '<li>Заполнить данные</li>' +
                '<li>Выбрать Участников или же добавить эксель файл через Импорт участников</li>' +
                '<li>Нажать кнопку Добавить запись</li>' +
                '</ol>' +
                '<p>Для дополнительной информации можете просмотреть видеинструкцию </p>',
            video: 'Aob39F3gsX4?si=VlIsFSUh8EroChmJ',
          },
          {
            title: 'Можно ли добавить много пользователей за один момент?',
            content: '<h3>Можно ли добавить много пользователей за один момент?</h3>' +
                '<p>Да можно добавить новых пользователей через страницу Группы используя эксель таблицу. Шаблон будет прикреплен на страницу создании группы.</p>',
            video: false,
          },
          {
            title: 'Как добавить потоки?',
            content: '<h3>Как добавить потоки?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Нажать на кнопку Потоки располженную слева в меню</li>' +
                '<li>Нажать кнопку Добавить</li>' +
                '<li>Добавить название</li>' +
                '<li>Выбрать участников</li>' +
                '</ol>',
            video: false,
          },
          {
            title: 'В чем разница между потоком и группой?',
            content: '<h3>В чем разница между потоком и группой?</h3>' +
                '<p>Потоки могут состоят из групп учеников. В остальном никакой больше разницы.</p>',
            video: false,
          },
          {
            title: 'Как добавить журналы?',
            content: '<h3>Как добавить журналы?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Нажать на кнопку Журналы расположенная слева в меню</li>' +
                '<li>Нажать кнопку Добавить</li>' +
                '<li>Заполнить данные</li>' +
                '<li>Нажать кнопку Добавить запись</li>' +
                '<li>Нажать кнопку Журналы</li>' +
                '<li>Нажать кнопку Добавить запись</li>' +
                '<li>Заполнить данные</li>' +
                '</ol>' +
                '<p>Для дополнительной информации можете просмотреть видеинструкцию </p>',
            video: 'dc6sGoH6vpI?si=b6eSf3VcvQ3C4cx7',
          },
          {
            title: 'Как добавить промокоды?',
            content: '<h3>Как добавить промокоды?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Нажать на кнопку Промокоды расположенную слева в меню</li>' +
                '<li>Нажать кнопку Добавить</li>' +
                '<li>Заполнить данные</li>' +
                '<li>Нажать кнопку Добавить запись</li>' +
                '</ol>',
            video: false,
          },
          {
            title: 'Как распределяются бонусы пользователю?',
            content: '<h3>Как распределяются бонусы пользователю?</h3>' +
                '<p>Бонусы даются за каждую покупку совершенную пользователем. Количества бонусы можете указать на странице Настройки>Модули>Процент бонуса. Пользователь может потратить свой бонус для покупки вашего продукта</p>',
            video: 'XWegVlB9wmA?si=cpfhTxFiYvZrlYJd',
          },
          {
            title: 'Как добавить конференции?',
            content: '<h3>Как добавить конференции?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Нажать на кнопку Конференции расположенную слева в меню</li>' +
                '<li>Нажать кнопку Добавить</li>' +
                '<li>Заполнить данные</li>' +
                '<li>Выбрать участников</li>' +
                '<li>Нажать кнопку Добавить запись</li>' +
                '</ol>',
            video: false,
          },
          {
            title: 'Можно ли добавить что то другое кроме Zoom в конференцию?',
            content: '<h3>Можно ли добавить что то другое кроме Zoom в конференцию?</h3>' +
                '<p>Да, вы можете добавлять ссылку любого удобного вам ресурса для конференции.</p>',
            video: false,
          },
          {
            title: 'Есть ли возможность добавить другой язык на страницу?',
            content: '<h3>Есть ли возможность добавить другой язык на страницу?</h3>' +
                '<p>Да, первоначально у вас доступно 2 языка, Казахский и Русский. Вы можете добавлять языки со страницы Языки в Админке. Дополнительно вам надо будет перевести контент платформы на выбранный вам язык и заполнить данные в той странице. После этого у вас доступен ваш созданный язык.</p>',
            video: false,
          },
          {
            title: 'Как работают сертификаты?',
            content: '<h3>Как работают сертификаты?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Нажать на кнопку Сертификаты расположенную слева в меню</li>' +
                '<li>Нажать кнопку Добавить</li>' +
                '<li>Загрузить ваш Шаблон</li>' +
                '<li>Добавить текст</li>' +
                '<li>Нажать кнопку Добавить запись</li>' +
                '</ol>' +
                '<p>Для дополнительной информации можете просмотреть видеинструкцию</p>',
            video: 'pYe0520SZMs?si=gcNlOnaKzI-PbPPN',
          },
          {
            title: 'Какие модули доступны для платформы?',
            content: '<h3>Какие модули доступны для платформы?</h3>' +
                '<p>Вы можете просмотреть доступные вам модули на странице Настройки>Модули. Имейте ввиду что не все модули могуть быть у вас подключены. А так же у нас есть Модули которые не подключены на платформу. Для дополнительной информации можете связатся с представителем компании. При необходимости дополнительной информации о доступных вам модулях можете посмотреть нашу видеоинструкцию о странице Модули </p>',
            video: 'opS4H-_XEd8?si=Xa3yZuHGxamiiysQ',
          },
          {
            title: 'Какие платежные системы доступны для использования?',
            content: '<h3>Какие платежные системы доступны для использования?</h3>' +
                '<p>Все платежные системы которые доступны вам, вы можете увидеть на странице Настройки>Платежные системы. Если у нас отсутсвуют нужная вам система можете обратится представителю компании.</p>',
            video: false,
          },
          {
            title: 'Как делать рассылку?',
            content: '<h3>Как делать рассылку?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Перейти на страницу Настройки>Рассылки</li>' +
                '<li>Выбрать вид рассылки</li>' +
                '<li>Заполнить необходимые данные </li>' +
                '<li>Нажать кнопку Обновить запись</li>' +
                '</ol>' +
                '<p>Для дополнительной информации можете просмотреть видеинструкцию </p>',
            video: 'Ec7rUZcne6w?si=xCbeUyRQw6qA2wZw',
          },
          {
            title: 'Как добавить инструменты аналитики?',
            content: '<h3>Как добавить инструменты аналитики?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Зайти на страницу Настройки>Инструменты Аналитики</li>' +
                '<li>Выбрать нужную вам аналитику</li>' +
                '<li>Заполнить данные</li>' +
                '<li>Нажать кнопку Обновить запись</li>' +
                '</ol>',
            video: false,
          },
          {
            title: 'Как поменять метод авторизации с почты на номер?',
            content: '<h3>Как поменять метод авторизации с почты на номер?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Перейти на страницу Настройки>Персонализация</li>' +
                '<li>Выбрать нужный вид авторизации</li>' +
                '<li>Нажать кнопку Обновить запись</li>' +
                '</ol>',
            video: false,
          },
          {
            title: 'Как подключить CRM систему?',
            content: '<h3>Как подключить CRM систему?</h3>' +
                '<ol>' +
                '<li>Зайти в Админку</li>' +
                '<li>Перейти на страницу Настройки>CRM</li>' +
                '<li>Заполнить данные</li>' +
                '<li>Нажать на кнопку Включить</li>' +
                '<li>Нажать на кнопку Обновить запись</li>' +
                '</ol>' +
                '<p>Для дополнительной информации можете просмотреть видеинструкцию </p>',
            video: '_0WZ4uXiD24?si=sbeiL2M7W5lpJMx4',
          },
          {
            title: 'Какие CRM системы можно добавить?',
            content: '<h3>Какие CRM системы можно добавить?</h3>' +
                '<p>На нынешний момент платформа Educenter поддерживает только систему amoCRM</p>',
            video: false,
          },
        ],
      }
    },
    components: { ModalVideo },
    methods: {
      changeTab(value) {
        this.activeTab = value;
      },
      openVideo(id) {
        this.videoIsOpen = !this.videoIsOpen
        this.videoId = id
      },
    },
    computed: {
      videoChannel: function () {
        return 'youtube'
      },
    }
}
</script>

<style>
  .help .aside {
      width: 100%;
      max-width: 400px;
      height: 60vh;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 20px;
      background-color: #F8FAFF;
      border-radius: 4px;
  }
  .help .aside ul {
    list-style: none;
  }
  .help .aside ul li {
    padding: 10px 20px;
    cursor: pointer;
  }
  .help .aside ul li.active {
    background-color: #e4e9fc;
    border-radius: 4px;
  }
  .help .head .title{
    font-size: 30px;
    font-weight: 700;
    color: var(--black);
  }
  .help .middle {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  .help .content {
    width: 100%;
    margin-left: 30px;
  }
  .help .content *{
    margin-bottom: 1rem;
  }
  .help .content li{
    margin-bottom: 0.5rem;
    margin-left: 1.2rem;
  }
  .help .content button {
    background: var(--main-color);
    border: none;
    border-radius: 8px;
    color: var(--white);
    padding: 12px 10px 12px 40px;
    position: relative;
  }
  .help .content button:before {
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../assets/icons/video-play.svg");
    position: absolute;
    top: auto;
    left: 10px;
    width: 20px;
    height: 20px;
  }
  @media (max-width: 991px) {
    .help .page__title {
      margin-bottom: 0;
    }
    .help .middle {
      flex-direction: column;
      gap: 30px;
    }
    .help .aside {
      max-width: 100%;
    }
    .help .content {
      margin-left: 0;
    }
    .help .content iframe {
      width: 100%;
      height: 250px;
    }
    .help .content li {
      margin-left: 1.2rem;
    }
    .help .content *{
      margin-bottom: 1rem;
      margin-left: 0rem;
    }
    .help .content button {
      width: 100%;
      padding: 12px;
    }
    .help .content button:before {
      left: auto;
      display: none;
    }
  }
  @media (max-width: 450px) {
    .help .content iframe {
      width: 100%;
      height: 190px;
    }
    .help .aside {
      height: 25vh;
      padding: 0;
    }
  }
</style>