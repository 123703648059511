<template>

  <section class="faq-page" id="faq-page">
    <div class="container">
      <div class="faq-page__body">
        <div class="faq-page__content">
          <div class="faq-page__search">
            <div class="page__search-form">
              <button class="page__search-btn" type="button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.7138 6.8382C18.1647 9.28913 18.1647 13.2629 15.7138 15.7138C13.2629 18.1647 9.28913 18.1647 6.8382 15.7138C4.38727 13.2629 4.38727 9.28913 6.8382 6.8382C9.28913 4.38727 13.2629 4.38727 15.7138 6.8382" stroke="#6D7885" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19 19L15.71 15.71" stroke="#6D7885" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <input type="search" 
                     v-model="search" 
                     class="page__search-input" 
                     placeholder="Что нужно найти?"
                     oninput="this.value = this.value.toLowerCase()">
            </div>
          </div>
          <div class="page__header">
            <h1 class="page__title">Помощь по платформе</h1>
          </div>
          <FaqBody 
            v-for="faq in filteredFaqs" 
            :faq="faq" 
            :index="faq.id" 
            :key="faq.id"
            :open="faq.open"
            @toggleOpen="toggleOpen"
          />
        </div>
        <div class="faq-page__video">
          <div class="faq-page__video-header">
            <h2 class="faq-page__video-title">Видеоинструкции по платформе</h2>
            <a href="https://www.youtube.com/channel/UCjqdZ0Io9biQXed8wQVwo5Q" class="faq-page__video-link text-link-underline1" target="_blank">Перейти в ютуб</a>
          </div>
          <div class="faq-page__video-body">
            <div class="faq-page__video-grid">
              <div class="faq-page__video-items" @click="openVideo(faqVideo.video)" v-for="(faqVideo, index) of faqVideos" :key="index">
                <div class="faq-page__video-img">
                  <img :src="faqVideo.img" alt="">
                  <button class="faq-page__video-play-btn">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#A61818"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.25 15L13.25 19.75L13.25 10.25L19.25 15Z" fill="white"/>
                    </svg>
                  </button>
                </div>
                <div class="faq-page__video-text">
                  <p>{{ faqVideo.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="media-component size-small">
    <modal-video :channel="videoChannel" :videoId="videoId" v-model:isOpen="videoIsOpen" />
  </div>

  <Message />
  <BackToTop />

</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import FaqBody from '@/components/FaqBody.vue'
import ModalVideo from '@/components/Modals/Video.vue';
import BackToTop from '@/components/BackToTop.vue';
import Message from '@/components/Message.vue';

export default {
    data() {
      return {
        videoIsOpen: false,
        videoId: '',
        search: '',
        allFaqs: [
          {
            id: 1,
            question: "Где и как размещать видео?",
            answer: "Все видеоуроки загружаются сперва на видеохостинги YouTube или Vimeo, затем на платформу.",
            open: false
          },
          {
            id: 2,
            question: "Вебинары можно проводить?",
            answer: "Можно проводить трансляции с помощью Zoom/Google Classroom",
            open: false
          },
          {
            id: 3,
            question: "После приобретения платформы, можно ли дальше модернизировать?",
            answer: "Да, можно. Есть возможность модернизировать под ваши требования. ",
            open: false
          },
          {
            id: 4,
            question: "Смогу я сам заливать курс? Легко в использовании?",
            answer: "Да, платформа удобна в использовании. Так же есть видеоинструкции для загрузки курсов.",
            open: false
          },
          {
            id: 5,
            question: "Если купить платформу в аренду, туда входит мобильное приложение?",
            answer: "Мобильное приложение идет в подарок к тарифам «Покупка» и «Enterprise»",
            open: false
          },
          {
            id: 6,
            question: "Сколько стоит тариф Enterprise?",
            answer: "Стоимость тарифа Enterprise оценивается от сложности функционала. ",
            open: false
          },
          {
            id: 7,
            question: "На Платформе есть запрет на запись экрана?",
            answer: "Видеоплеер курсов полностью защищен. Мобильное приложение платформы защищена от записи экранов и скриншота.",
            open: false
          },
          // {
          //   question: "Можно ли посмотреть ваши работы?",
          //   answer: "В разделе «Кейсы» можете посмотреть наши работы",
          //   open: false
          // },
          {
            id: 8,
            question: "Есть ли автоплатежи на платформе?",
            answer: "Можно производить автоплатежи с помощью PayBox, WalletOne, PayPost, Epay, Cloudpayments, Robokassa, Kaspi.",
            open: false
          },
          {
            id: 9,
            question: "Могут ли обучаться пользователи с СНГ?",
            answer: "Да, со всех стран СНГ.",
            open: false
          },
        ],
        faqVideos: [
          {
            title: 'Общая информация по платформе',
            img: require('../assets/images/img-faq-video-1.png'),
            video: 'D2q6VOR5v5s',
          },
          {
            title: 'Инструкция по использованию платформы Educenter',
            img: require('../assets/images/img-faq-video-1.png'),
            video: 'HfHVgLw0G70',
          },
          {
            title: 'Общая информация по платформе',
            img: require('../assets/images/img-faq-video-1.png'),
            video: 'D2q6VOR5v5s',
          },
          {
            title: 'Общая информация по платформе',
            img: require('../assets/images/img-faq-video-1.png'),
            video: 'D2q6VOR5v5s',
          },
          {
            title: 'Общая информация по платформе',
            img: require('../assets/images/img-faq-video-1.png'),
            video: 'D2q6VOR5v5s',
          },
          {
            title: 'Общая информация по платформе',
            img: require('../assets/images/img-faq-video-1.png'),
            video: 'D2q6VOR5v5s',
          },
          {
            title: 'Общая информация по платформе',
            img: require('../assets/images/img-faq-video-1.png'),
            video: 'D2q6VOR5v5s',
          },
          {
            title: 'Общая информация по платформе',
            img: require('../assets/images/img-faq-video-1.png'),
            video: 'D2q6VOR5v5s',
          },
        ],
        props: ['faq', 'id', 'open', 'toggleOpen']
      }
    },
    components: { Header, Footer, FaqBody, ModalVideo, BackToTop, Message },
    methods: {
      toggleOpen: function (index) {
        this.allFaqs = this.allFaqs.map(faq => {
          if (index == faq.id  ) {
            faq.open = !faq.open;
          } else {
            faq.open = false;
          }
          return faq;
        });
      },
      openVideo(id) {
        this.videoIsOpen = !this.videoIsOpen
        this.videoId = id
      },
    },
    computed: {
      filteredFaqs() {
        return this.allFaqs
          .filter(
            (faq) => this.allFaqs.length
              ? Object.keys(this.allFaqs[0])
                .some(key => ('' + faq[key]).toLowerCase().includes(this.search))
              : true
          );
      },
      videoChannel: function () {
        return 'youtube'
      },
    }
  }
</script>

<style>
  .faq-page {
    display: flex;
  }
  .faq-page__content {
    width: 80%;
  }

  .faq-page .page__header {
    margin: 30px 0;
    align-items: flex-start;
    text-align: left;
  }

  .faq-page__content .page__title {
    margin-bottom: 0;
  }

  .faq-page .faq__body {
    width: 100%;
  }

  .faq-page__video {
    margin: 40px 0;
  }

  .faq-page__video-header {
    margin-bottom: 30px;
  }

  .faq-page__video-title {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: var(--black);
    margin-bottom: 20px;
  }

  .faq-page__video-link {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
  }

  .faq-page__video-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
  }

  .faq-page__video-items {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }

  .faq-page__video-img {
    width: 100%;
    height: 180px;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
  }

  .faq-page__video-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .faq-page__video-play-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    border: 0;
    background: 0;
  }

  .faq-page__video-text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: var(--black);
  }

  .page__search-input::-webkit-search-cancel-button {
    position:relative;
    right:0px;  
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius:0;
    background: url(../assets/icons/ic-search-x.svg);
  }

  @media (max-width: 1400px) {

  }
  @media (max-width: 1200px) {
    .faq-page__video-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (max-width: 991px) {
    .faq-page__content {
     width: 100%;
    }
    .page__search-form {
      width: auto;
    } 
    .faq-page__video-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 768px) {
   
  }
  @media (max-width: 600px) {
    .faq-page__video-grid {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: 500px) {
    .faq-page__video-text {
      font-size: 15px;
    }
  }
  @media (max-width: 414px) {
  
  }
  @media (max-width: 390px) {

  }
  @media (max-width: 375px) {

  }
  @media (max-width: 360px) {

  }
  @media (max-width: 320px) {

  }
</style>