<template>
  <section class="create-platform" id="create-platform">
    <div class="container">
      <div class="page__header">
        <h1 class="page__title">Откройте платформу за 5 минут</h1>
        <p class="page__subtitle">Получите 5 дней бесплатного периода<br>
и протестируйте все возможности нашей платформы</p>
      </div>
      <div class="page__body">

        <form action="https://control.educenter.kz/api/v1/create_account" 
        class="create-platform__form"  
        id="create-platform__form"
        @submit.prevent="createAccount()" method="post">
          <div class="create-platform_input-block">
            <div class="create-platform_input-area">
              <div class="create-platform__inputs" id="subdomain-input">
                <input type="text" name="subdomain" v-model="subdomain" class="create-platform__form-input" id="input-subdomain" placeholder=" ">
                <label for="input-subdomain" class="create-platform__form-label">Субдомен</label>
              </div>
              <p id="domain-name">.educenter.kz</p>
            </div>
            <p class="domain_warning" style="display:none;color:red;font-size:12px;">Домен существует</p>
          </div>
          <div class="create-platform__inputs">
            <input type="text" name="name" v-model="name" class="create-platform__form-input" id="input-name" placeholder=" "> 
            <label for="input-name" class="create-platform__form-label">ФИО</label>
          </div>
          <div class="create-platform__inputs">
            <MazPhoneNumberInput 
              id="input-tel"
              v-model="phone"
              default-country-code="KZ"
              isValid="false"
              show-code-on-list
              :only-countries="['KZ','RU','KG','UZ','UA','BY','AM','TJ','AZ','MD']"
              :translations="{
                countrySelector: {
                  placeholder: 'Код страны',
                  error: 'Выберите страну',
                },
                phoneInput: {
                  placeholder: 'Номер телефона',
                  example: 'Пример:',
                },
              }"
              :custom-countries-list="{
                KZ: 'Казахстан (Kazakhstan)',
                RU: 'Россия (Russia)',
                KG: 'Кыргызстан (Kyrgyzstan)',
                UZ: 'Узбекистан (Uzbekistan)',
                UA: 'Украина (Ukraine)',
                BY: 'Беларусь (Belarus)',
                AM: 'Армения (Armenia)',
                TJ: 'Таджикистан (Tadjikistan)',
                AZ: 'Азербайджан (Azerbaijan)',
                MD: 'Молдова (Moldova)',
              }"
              list-position="bottom left"
              color="primary"
              size="md"
              clearable="true"
            />
          </div>
          <div class="create-platform__inputs">
            <input type="text" name="email" v-model="email" class="create-platform__form-input" id="input-email" placeholder=" ">
            <label for="input-email" class="create-platform__form-label">E-mail</label>
          </div>
          <label class="create-platform__checkbox">
            <input type="checkbox" name="checkbox" @click="checkPhone()"  v-model="confirm_checkbox" id="">
            Я согласен на обработку данных
            <span class="create-platform__checkmark"></span>
          </label>
          <button class="create-platform__form-btn btn-blue" type="submit">Отправить</button>
        </form>
      </div>
    </div>
  </section>

  <section class="confirm-code original" id="confirm-code" style="display:none;">
    <div class="container">
      <div class="page__header">
        <h1 class="page__title">Подтвердите номер</h1>
      </div>
      <div class="page__body">
        <div class="confirm-code__body">
          <h3 class="confirm-code__body-title">Мы отправили вам код для подтверждения <br>
ваш номер телефона</h3>
          <p class="confirm-code__body-subtitle">Отправлено на {{ phone }}</p>
        </div>
        <form class="confirm-code__form" @submit.prevent="confirmCode()">
          <div class="captcha">
            <input class="input-code" v-for="(c, index) in ct" :key="index"
                  type="number" v-model="ct[index]" ref="input"
                  :style="{borderColor: index <= cIndex ? '#007AFF' : ''}"
                  @input="e => {onInput(e.target.value, index)}"
                  @keydown.delete="e=>{onKeydown(e.target.value, index)}"
                  @focus="onFocus"
                  :disabled="loading"
            >
          </div>
          <p id="confirm_error-text" style="display: none; color: red; font-size: 12px; text-align: center;"></p>
          <div class="confirm-code__form-links">
            <a href="" class="confirm-code__form-link1">мне не пришел код</a>
            <button href="" class="confirm-code__form-link2" @click="sendAgain()">Отправить</button>
          </div>
          <button class="confirm-code__form-btn btn-blue" type="submit" >Подтвердить код</button>
        </form>
      </div>
    </div>
  </section>

  <section class="confirm-code" id="confirm-loading">
    <div class="container">
      <div class="page__body">
        <div class="confirm-code__body">
          <div class="confirm-code__loading-gif -loading">
            <img src="../assets/icons/loading1.gif" alt="">
          </div>
          <p class="confirm-code__loading-name">Мы настраиваем Вам платформу, пожалуйста подождите<span class="dots">.</span></p>
        </div>
      </div>
    </div>
  </section>

  <section class="confirm-code" id="platform-installed">
    <div class="container">
      <div class="page__body">
        <div class="confirm-code__body">
          <div class="confirm-code__loading-gif -done">
            <img src="../assets/icons/done1.gif" alt="">
          </div>
          <p class="confirm-code__loading-name">Платформа установлена!</p>
          <div class="confirm-code__redirect">
            <h3 class="confirm-code__redirect-text">Оставайтесь на странице, мы перенаправим Вас<br> 
автоматически через..</h3>
            <h1 class="confirm-code__redirect-time" id="timerCount"></h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Message />
  <BackToTop />
</template>



<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import BackToTop from '@/components/BackToTop.vue';
import axios from 'axios'

import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import { notify  } from '@kyvg/vue3-notification';
import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import Message from '@/components/Message.vue'

export default {
    data() {
      return {
        errors: [],
        subdomain: null,
        name: null,
        phone: null, 
        email: null,
        confirm_checkbox: false,
        ct: ['','','','','',''],
        loading: false,
        msg:'',
        code_result: null,
        shedId:0,
        authKey: '',
      }
    },
    components: { Header, Footer, VueTelInput, BackToTop, axios, MazPhoneNumberInput, Message },
    mounted() {
        $('#input-subdomain').on("keyup",function (){
          if($(this).val().length < 1){
              return
          }
          var $this = $(this)

          axios.get('https://control.educenter.kz/api/v1/find_domain',
          {
            params: {
              domain: $(this).val() + '.educenter.kz'
            }
          })
          .then((response)=>{
            if(response.data.data.exist) {
              $('.domain_warning').show()
              $this.css({'border':'1px solid red'});
            }
            else{
                $('.domain_warning').hide()
                  $this.css({'border':'1px solid green'});
              }
          })
        })         
        this.resetCaret();        
    },
    computed: {
      ctSize() {
        return this.ct.length;
      },
      cIndex() {
        let i = this.ct.findIndex(item => item ==='');
        i = (i + this.ctSize)% this.ctSize;
        return i;
      },
      lastCode() {
        return this.ct[this.ctSize-1];
      }
    },
    watch: {
      cIndex() {
        this.resetCaret();
      },
      lastCode(val) {
        if (val) {
          // this.$refs.input[this.ctSize-1].blur();
          // this.sendCaptcha();
        }
      }
    },
    methods: {
      checkPhone(){
        
      },
      createAccount() {
        if(this.subdomain && this.name && this.phone && this.email && this.confirm_checkbox) {
          axios.get('https://control.educenter.kz/api/v1/create_account',
                {
                  params: {
                    subdomain: this.subdomain,
                    name: this.name,
                    phone: this.phone,
                    email:this.email,
                  }
                })
             .then(()=>{
                $('#create-platform').hide()
                this.subdomain = null
                this.name = null
                $('#input-tel').val('')
                // this.phone = null
                this.email = null
                $('#confirm-code').show()
             })
             .catch(error => {        
             })
        }

        this.errors = [];
        if(this.subdomain === null) {
          this.errors.push("Вы пропустили поле \"Субдомен\"")
        }
        if(this.name === null) {
          this.errors.push("Вы пропустили поле \"ФИО\"")
        }
        if (!this.phone) {
          this.errors.push("Вы пропустили поле \"Номер телефона\"")
        }
        if(this.email === null) {
          this.errors.push("Вы пропустили поле \"E-mail\"")
        }
        if (this.confirm_checkbox === false) {
          this.errors.push('Вам необходимо принять условия соглашения')
        }
        if (this.errors.length >= 1) {
          notify({
            group: 'app',
            type: 'error',
            text: this.errors.join(', <br>'),
          });
        }
        else {
          notify({
            group: 'app',
            type: 'success',
            text: 'Ваши данные успешно отправлены!',
          });
        }

      },

      onInput(val, index) {
        this.msg =''
        val = val.replace(/\s/g,'');
        if (index == this.ctSize-1) {
          this.ct[this.ctSize-1] = val[0]; 
        } else if(val.length> 1) {
          let i = index;
          for (i = index; i <this.ctSize && i-index <val.length; i++) {
            this.ct[i] = val[i];
          }
          this.resetCaret();
        }
      },
      
      resetCaret() {
        this.$refs.input[this.ctSize-1].focus();
      },
      onFocus() {
        let index = this.ct.findIndex(item => item ==='');
        index = (index + this.ctSize)% this.ctSize;
        this.$refs.input[index].focus();
      },
      onKeydown(val, index) {
        if (val ==='') {
          if (index> 0) {
            this.ct[index-1] ='';
            this.$refs.input[index-1].focus();
          }
        }
        
      },
      
      reset() {
        this.ct = this.ct.map(item =>'');
        this.resetCaret();
      },

      async confirmCode() {
        this.code_result = parseInt(this.ct[0].toString()+this.ct[1].toString()+this.ct[2].toString()+this.ct[3].toString()+this.ct[4].toString()+this.ct[5].toString())
        var timerCount = 5;
        var domain = ""
        var data_1 = {}
        let intervalId_1,intervalId_2
        var last_percent = 0;

        const res = axios.get('https://control.educenter.kz/api/v1/verify_check',
                {
                  params: {
                    phone: this.phone,
                    code: this.code_result
                  }
                })
             .then((response)=>{
                data_1 = response.data.success
                if (response.data.success) {
                  $('#confirm_error-text').hide()
                  $('.input-code').css({'border':'1px solid green'});
                }
                else{
                  response.data.success == false
                  $('#confirm_error-text').show()
                  $('#confirm_error-text').html(response.data.errors[0].error)
                  $('.input-code').css({'border':'1px solid red'});
                }
             })
             .catch(error => {
             })

        await axios.get('https://control.educenter.kz/api/v1/verify_account',
                {
                  params: {
                    phone: this.phone,
                    code: this.code_result
                  }
                })
             .then((response)=>{
                this.shedId  = response.data.data.schedule_id;
                this.authKey = response.data.data.auth_key;
                if (response.data.data.success) {
                  $('#confirm-code').hide()
                  $('#confirm-loading').show()
                }
               
             })
        
          intervalId_1 = setInterval(() => {
            let dots_length = $(".dots").text().length;
                if(dots_length < 3){
                    $(".dots").text($(".dots").text()+".")
                }else{
                    $(".dots").text(".")
                }

            axios.get('https://control.educenter.kz/api/v1/get_schedule/'+this.shedId, {
              params: {}
              })
            .then((response2)=>{
              
              if(!domain) {
                domain = response2.data.data.domain
              }
              if(response2.data.data.percent){
                  if(response2.data.data.percent > last_percent){
                      last_percent = response2.data.data.percent  
                  }
              }
              if(response2.data.data.finished){
                clearInterval(intervalId_1)
                setTimeout(function() {
                  $('#confirm-loading').hide()
                  $('#platform-installed').show()
                  intervalId_2 = setInterval(function() {
                    var timer = timerCount--;
                    document.getElementById("timerCount").innerHTML = timer;
  
                    if(timer <= 0) {
                      clearInterval(intervalId_2);
                      window.location = domain+'?auth_key='+this.authKey
                    }
                  }, 1000);
                },1000)
              }
            })
             
         },1000)   
        
      },

      async sendAgain() {
        await axios.get('https://control.educenter.kz/api/v1/send_again',
                {
                  params: {
                    phone: this.phone,
                  }
                })
             .then((response)=>{       
             })
      }
  }
}
</script>

<style scoped>

  .create-platform {
    display: flex;
    padding: 80px 0;
  }

  .create-platform .container {

  }

  .create-platform .page__title {
    text-transform: uppercase;
    line-height: 120%;
  }

  .create-platform__form {
    width: 360px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .create-platform__inputs {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .create-platform__form input {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 16px 22px;
    outline-color: var(--main-color);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    position: relative;
  }

  .create-platform__form-label {
    position: absolute;
    top: 0;
    left: 22px;
    bottom: 0;
    margin: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    padding: 0px 5px;
    color: #888888;
    transition: 0.3s;
    pointer-events: none;
    display: flex;
    align-items: center;
  }

  .create-platform__form-input:focus + .create-platform__form-label, .create-platform__form-input:not(:placeholder-shown) +.create-platform__form-label{
    top: -35px;
    font-size: 10px;
  }

  
  .create-platform__checkbox {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    color: var(--black);
    display: block;
    position: relative;
    padding: 1px 0 0 35px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
  }

  .create-platform__checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
  }

  .create-platform__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 20px;
    background: none;
    border-radius: 3px;
    border: 1px solid var(--main-color);
  }

  .create-platform__checkmark::after {
    content: "";
    position: absolute;
    display: none;
  }

  .create-platform__checkbox input:checked ~ .create-platform__checkmark {
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
  }

  .create-platform__checkbox input:checked ~ .create-platform__checkmark::after {
    display: block;
  }

  .create-platform__checkbox .create-platform__checkmark::after {
    left: 5px;
    top: 2px;
    width: 8px;
    height: 8px;
    border: solid var(--white);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    border-radius: 1px;
  }

  .create-platform_input-area {
    display: flex;
    align-items: center;
  }

  #subdomain-input {
    width: 250px;
    margin-right: 5px;
  }

  .vue-tel-input.platform-input {
    background: #FFFFFF;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px;
    outline-color: var(--main-color);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    position: relative;
  }

  @media (max-width: 1400px) {

  }
  @media (max-width: 1200px) {
   
  }
  @media (max-width: 991px) {
    
  }
  @media (max-width: 768px) {
  
  }
  @media (max-width: 600px) {
   
  }
  @media (max-width: 500px) {
    .create-platform__form  {
      width: 100%;
    }
    .create-platform .page__subtitle{
      font-size: 13px;
    }
    .create-platform__form input {
      padding: 14px 16px;
      font-size: 16px;
    }
    .create-platform__form-label {
      left: 14px;
      font-size: 13px;
    }
    #subdomain-input {
      width: 64%;
    }
    #domain-name {
      font-size: 16px;
    }

    .vue-tel-input.platform-input {
      font-size: 16px;
    }
  }
  @media (max-width: 414px) {

  }
  @media (max-width: 390px) {

  }
  @media (max-width: 375px) {

  }
  @media (max-width: 360px) {

  }
  @media (max-width: 320px) {

  }

  .confirm-code{
    display: flex;
    padding: 80px 0;
  }

  .confirm-code .container {

  }

  .confirm-code__body {
    min-height: 400px;
  }

  .confirm-code.original .confirm-code__body {
    min-height: auto;
  }

  .confirm-code .page__header {
    margin: 0;
  } 
  .confirm-code .page__title {
    text-transform: uppercase;
  }

  .confirm-code__form {
    width: 475px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .confirm-code__body-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #898989;
    margin-bottom: 20px;
  }

  .confirm-code__body-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
  }

  .confirm-code__form {
    
  }

  .captcha {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .captcha input {
    margin-right: 16px;
    text-align: center;
    width: 64px;
    height: 64px;
    outline: none;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: var(--main-color)
  }
  .captcha input::-webkit-outer-spin-button,
  .captcha input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .captcha input:last-of-type {
    margin-right: 0;
  }
  .captcha input:disabled {
    color: #000;
    background-color: #fff;
  }

  .confirm-code__form-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .confirm-code__form-link1 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #898989;
    margin: 0 0 16px 0;
  }

  .confirm-code__form-link2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: var(--main-color);
    background: none;
    border: none;
    outline: none;
  }



  @media (max-width: 1400px) {

  }
  @media (max-width: 1200px) {
  
  }
  @media (max-width: 991px) {
    
  }
  @media (max-width: 768px) {

  }
  @media (max-width: 600px) {
    .confirm-code__form {
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    .captcha input {
      width: 50px;
      height: 50px;
    }
    .confirm-code .page__title {
      font-size: 22px;
    } 
  }
  @media (max-width: 414px) {
    .captcha input {
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }
  }
  @media (max-width: 390px) {

  }
  @media (max-width: 375px) {

  }
  @media (max-width: 360px) {

  }
  @media (max-width: 320px) {
    .captcha input {
      width: 45px;
      height: 45px;
      margin-right: 2px;
    }
  }

  #confirm-loading {
    display: none;
  }
  .confirm-code__loading-gif.-loading {
    width: 300px;
    height: 140px;
    margin: 50px auto 12px auto;
  }
  .confirm-code__loading-gif.-loading img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .confirm-code__loading-gif.-done {
    width: 400px;
    height: 192px;
    margin: 50px auto 12px auto;
  }
  .confirm-code__loading-gif.-done img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .confirm-code__loading-name {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: var(--black);
    text-align: center;
  }

  #platform-installed {
    display: none;
  }

  .confirm-code__redirect {
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
    margin: 40px 0;
  }

  .confirm-code__redirect-text {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 135%;
    color: var(--black);
    text-align: center;
  }  

  .confirm-code__redirect-time {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 135%;
    color: var(--black);
  }

  @media (max-width: 500px) {
    .confirm-code__loading-gif.-done {
      width: 100%;
    }
  }
</style>