<template >
  <header class="header"  id="header">
    <div class="container">
      <router-link to="/" class="header__logo">
        <img src="../assets/logo.svg" alt="">
<!--         <img src="../assets/icons/ic-newyear-hat.png" alt="" id="santa-hat">-->
        <span>Cоздайте свою онлайн школу <br>вместе с educenter</span>
      </router-link>
      <nav class="header__nav" :class="{'active': menuActive}">
        <div class="header__nav-list">
          <ul>
            <li><a href="/#about-video" class="header__nav-items" @click="closeBurger()">О нас</a></li>
            <li><a href="/#works" class="header__nav-items" @click="closeBurger()">Наши работы</a></li>
            <li><a href="/#advantages" class="header__nav-items" @click="closeBurger()">Преимущества</a></li>
            <li><router-link to="/tariffs" class="header__nav-items" @click="closeBurger()">Тарифы</router-link></li>
            <li><router-link to="/services" class="header__nav-items" @click="closeBurger()">Услуги</router-link></li>
            <li><a href="/#reviews" class="header__nav-items" @click="closeBurger()">Отзывы</a></li>
            <li><router-link to="/help" class="header__nav-items" @click="closeBurger()">Помощь</router-link></li>
            <!-- <li><router-link to="/cases" class="header__nav-items" @click="closeBurger()">Кейсы</router-link></li> -->
            <!-- <li><router-link to="/blog" class="header__nav-items" @click="closeBurger()">Блог</router-link></li> -->
            <li><router-link to="/faq" class="header__nav-items" @click="closeBurger()">Частые вопросы</router-link></li>
          </ul>
        </div>
<!--        <div class="header__nav-list-mobile">-->
<!--          <ul >-->
<!--            <li><a href="/#about-video" class="header__nav-items" @click="closeBurger(); $emit('lockPage')">О нас</a></li>-->
<!--            <li><a href="/#works" class="header__nav-items" @click="closeBurger(); $emit('lockPage')">Наши работы</a></li>-->
<!--            <li><a href="/#advantages" class="header__nav-items" @click="closeBurger(); $emit('lockPage')">Преимущества</a></li>-->
<!--            <li><router-link to="/tariffs" class="header__nav-items" @click="closeBurger(); $emit('lockPage')">Тарифы</router-link></li>-->
<!--            <li><router-link to="/services" class="header__nav-items" @click="closeBurger(); $emit('lockPage')">Услуги</router-link></li>-->
<!--            <li><a href="/#reviews" class="header__nav-items" @click="closeBurger(); $emit('lockPage')">Отзывы</a></li>-->
<!--            <li><router-link to="/help" class="header__nav-items" @click="closeBurger(); $emit('lockPage')">Помощь</router-link></li>-->
<!--            &lt;!&ndash; <li><router-link to="/cases" class="header__nav-items" @click="closeBurger(); $emit('lockPage')">Кейсы</router-link></li> &ndash;&gt;-->
<!--            &lt;!&ndash; <li><router-link to="/blog" class="header__nav-items" @click="closeBurger(); $emit('lockPage')">Блог</router-link></li> &ndash;&gt;-->
<!--            <li><router-link to="/faq" class="header__nav-items" @click="closeBurger(); $emit('lockPage')">Частые вопросы</router-link></li>-->
<!--          </ul>-->
<!--        </div>-->
        <div class="header__nav-mobile-elem">
          <div class="header__nav-social">
            <ul>
              <li><a href="https://www.facebook.com/profile.php?id=100084520423464" target="_blank"><img src="../assets/icons/ic-social-fb.svg" alt=""></a></li>
              <li><a href="https://www.instagram.com/educenter.kz/" target="_blank"><img src="../assets/icons/ic-social-ig.svg" alt=""></a></li>
              <li><a href="https://wa.me/+77789920000" target="_blank"><img src="../assets/icons/ic-social-wa.svg" alt=""></a></li>
              <li><a href="https://t.me/Educenterkz" target="_blank"><img src="../assets/icons/ic-social-tg.svg" alt=""></a></li>
              <li><a href="https://www.youtube.com/channel/UCjqdZ0Io9biQXed8wQVwo5Q" target="_blank"><img src="../assets/icons/ic-social-yt.svg" alt=""></a></li>
            </ul>
          </div>
        </div>
      </nav>
      
      <div class="header__right">
        <div class="header__dropdown">
          <div class="dropdown-selected" @click="dropdownToggle()" :class="{'active':isActive}">
            RU
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 4L6 8L10 4" stroke="#222222"/>
            </svg>
          </div>
          <div class="dropdown-options" v-if="isOpen" :style="dropdownToggle">
            <a :href="option.link" class="dropdown-option" v-for="(option, index) in options" :key="index" >
              {{ option.name }}
            </a>
            <svg style="position: absolute; top: -5px; left: 0; right: 0; margin: auto;" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 0L0 6H12L6 0Z" fill="white"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="header__burger" @click="toggleBurger()" :class="{ 'active': burgerActive }">
        <span ></span>
      </div>
    </div>
   
  </header>
</template>

<script>
import About from '../views/About.vue'
import VueRouter from 'vue-router'

export default {
  data() {
    return {
      isOpen: false,
      isActive: false,
      burgerActive: false,
      menuActive: false,
      lock: false,
      options: [
        { name: 'Русский', link: '/' },
        { name: 'Қазақша', link: '/' },
      ]
    }
  },
  components: {
    VueRouter, About
  },
  methods: {
    dropdownToggle() {
      this.isActive = !this.isActive;
      this.isOpen = !this.isOpen;
    },
    toggleBurger() {
      this.burgerActive = !this.burgerActive
      this.menuActive = !this.menuActive
      if (document.body.style.overflow == 'hidden') {
        document.body.style.overflow = 'auto'
      }
      else {
        document.body.style.overflow = 'hidden'
      }
    },
    closeBurger() {
      this.burgerActive = false
      this.menuActive = false
      document.body.style.overflow = 'auto'
    },
  },
  created() {
    window.addEventListener('click', (e) => {
      if(e.target.className !== 'dropdown-selected active') {
        this.isActive = false;
        this.isOpen = false;
      }
    })
  },
}
</script>

<style>
  .header {
    /* padding: 30px 0; */
    background: #F8FAFF;
    display: block;
  }

  .header .container {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header__logo {
    display: flex;
    align-items: center;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: var(--main-color);
    column-gap: 10px;
    user-select: none;
    position: relative;
  }

  .header__logo span {
    font-family: "SF Pro Display";
  }

  .header__nav {
    position: relative;
  }

  .header__nav-list {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .header__nav-list-mobile {
    display: none;
    align-items: center;
    gap: 30px;
  }

  .header__nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 30px;
  }

  .header__nav ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text1);
    
  }

  .header__nav ul li a {
    color: var(--text1);
  }

  .header__nav ul li a:hover {
    color: var(--text1-hover);
  }

  .header__nav-mobile-elem {
    display: none;
  }

  .header__right {
    display: none;
    /* display: flex; */
    align-items: center;
    column-gap: 30px;
  }

  .header__burger {
    display: none;
  }

  .header__dropdown {
    position: relative;
  }

  .dropdown-selected {
    display: flex;
    align-items: center;
    column-gap: 4px;
    background: rgba(177, 179, 179, 0.2);
    border-radius: 2px;
    padding: 3px 6px;
    user-select: none;
    cursor: pointer;
  }

  .dropdown-options {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    background: var(--white);
    padding: 4px;
    width: 115px;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -22px;
    /* left: 0; */
    /* margin: auto; */
    top: 36px;
    z-index: 1000;
  }

  .dropdown-option {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    letter-spacing: -0.32px;
    color: #2C2D2E;
    background: none;
    text-align: center;
  }

  .dropdown-option:hover {
    background: #4090FF;
    border-radius: 8px;
    color: var(--white)
  }

  .login-btn {
    width: 180px;
  }

  #santa-hat {
    position: absolute;
    top: -28px;
    left: 10px;
    width: 67px;
    height: 57px;
  }

  .header.is-banner {
    top: -90px;
  }
  .banner {
    width: 100%;
    height: 90px;
    position: relative;
    overflow: hidden;
    display: block;
  }
  .banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner-mobile {
    display: none;
  }

  .header.main-header {
    top: auto;
    background: transparent;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .header.main-header .banner {
    display: none;
  }
  .header.main-header .header__nav-items {
    color: var(--white);
  }
  .header.main-header .header__nav.active {
    background: #222;
  }
  .header.main-header .header__burger span {
    background-color: #fff;
  }
  .header.main-header .header__burger:after,
  .header.main-header .header__burger:before {
    background-color: #fff;
  }

  @media (max-width: 1400px) {

  }

  @media (max-width: 1200px) {
    .header__nav {
      position: fixed;
      left: -1000%;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      background-color: #f6f7f9;
      margin: 100px 0px 20px 0px;
      padding: 60px 0;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .header__nav.active {
      left: 0px;
      background: #F8FAFF;
      display: flex;
      gap: 30px;
      flex-direction: column;
      align-items: center;
    }

    .header__burger {
      display: block;
      position: relative;
      width: 30px;
      height: 20px;
      z-index: 3;
      margin: 0;
      cursor: pointer;
    }

    .header__burger span {
      position: absolute;
      background-color: #000;
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      top: 9px;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
    }

    .header__burger:after {
      bottom: 0;
    }

    .header__burger:before {
      top: 0;
    }

    .header__burger:after, .header__burger:before {
      content: "";
      background-color: #000;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
    }

    .header__burger.active:after {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      bottom: 9px;
    }

    .header__burger.active span{
      transform: scale(0) translate(0px, -50%);
    }

    .header__burger.active:before {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 9px;
    }

    .header__burger.active:after,
    .header__burger.active:before {
      background-color: var(--main-color);
    }

    .header__nav ul li {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 24px;
      color: var(--text1);
    }

    .header__nav-list-mobile {
      display: flex;
    }

    .header__nav-list ul,
    .header__nav-list-mobile {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .header__nav-list-mobile ul {
      display: flex;
      flex-direction: column;
    }

    .header__nav-mobile-elem {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      height: 240px;
    }

    .header__nav-social ul {
      display: flex;
      gap: 20px;
    }
    
    .header__nav-social-description {
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 135%;
      color: #65737E;
      margin-top: 25px;
    }
  }

  @media (max-width: 991px) {
    .header__logo span {
      display: none;
    }

    .login-btn {
      width: auto;
    }
  }

  @media (max-width: 768px) {
    .banner-desktop {
      display: none;
    }
    .banner-mobile {
      display: block;
    }
    .banner {
      height: 50px;
    }
  }

  @media (max-width: 500px) {
    .header__nav-social-description {
      font-size: 14px;
    }
    .header .container {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .header__nav {
      margin-top: 90px;
    }
  }

  @media (max-width: 414px) {
    .login-btn {
    padding:10px;
    }
    .header__nav ul {
      gap: 15px;
    }
    .header__nav ul li{
      font-size: 27px;
    }
  }

  @media (max-width: 390px) {

  }

  @media (max-width: 375px) {

  }

  @media (max-width: 360px) {
    .header__right {
      column-gap: 10px;
    }
  }

  @media (max-width: 320px) {

  }
</style>