<template>
      <div class="main__modal" :class="{ 'isApplicationForm': isAppForm }" >
         <div class="main__modal-back" @click="modalClose()" ></div>
         <form action="" class="formAppl" id="formAppl" @submit.prevent="checkFormTariff()">
           <div class="main__modal-card" >
             <div class="main__modal-header">
               <h5 class="text-uppercase">Оставьте заявку</h5>
               <h3>Свяжитесь с нами в 2 клика</h3>
               <h5>В течение 30 минут наш менеджер перезвонит вам и проконсультирует Вас по всем вопросам.</h5>
               <svg class="main__modal-close-btn" @click="modalClose()" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <rect width="30" height="30" rx="15" fill="black" fill-opacity="0.05"/>
                 <path d="M11 11L19 19" stroke="#2C2D2E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                 <path d="M19 11L11 19" stroke="#2C2D2E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
             </div>
             <div class="main__modal-body">
               <input type="text" id="user_name" v-model="user_name" placeholder="Имя" name="name">
               <MazPhoneNumberInput
                 id="user_phone"
                 v-model="userPhone"
                 default-country-code="KZ"
                 isValid="false"
                 show-code-on-list
                 :only-countries="['KZ','RU','KG','UZ','UA','BY','AM','TJ','AZ','MD']"
                 :translations="{
                   countrySelector: {
                     placeholder: 'Код страны',
                     error: 'Выберите страну',
                   },
                   phoneInput: {
                     placeholder: 'Номер телефона',
                     example: 'Пример:',
                   },
                 }"
                 :custom-countries-list="{
                   KZ: 'Казахстан (Kazakhstan)',
                   RU: 'Россия (Russia)',
                   KG: 'Кыргызстан (Kyrgyzstan)',
                   UZ: 'Узбекистан (Uzbekistan)',
                   UA: 'Украина (Ukraine)',
                   BY: 'Беларусь (Belarus)',
                   AM: 'Армения (Armenia)',
                   TJ: 'Таджикистан (Tadjikistan)',
                   AZ: 'Азербайджан (Azerbaijan)',
                   MD: 'Молдова (Moldova)',
                 }"
                 list-position="bottom left"
                 color="primary"
                 size="md"
                 clearable="true"
               />
               <label class="checkbox">
                 <input type="checkbox" name="" id="" v-model="confirm_checkbox">
                 Я согласен на обработку данных
                 <span class="checkmark"></span>
               </label>
             </div>
             <div class="main__modal-footer">
               <button class="btn-blue" type="submit">Отправить
                 <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M4.97998 11.9805H19.97" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                   <path d="M13.983 5.98828L20.02 12.0003L13.983 18.0123" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg>
               </button>
             </div>
           </div>
         </form>
      </div>
</template>

<script>
import { notify  } from '@kyvg/vue3-notification';

export default {
   data() {
      return {
         user_name: null,
         user_phone: null,
         userPhone: null,
         confirm_checkbox: false,
         errors: [],
      }
   },
   props: ['isAppForm', 'form_text', 'modalClose'],
   methods: {

      checkFormTariff() { 
         if(this.user_name && this.userPhone && this.confirm_checkbox) {
            // ORIGINAL TOKEN AND CHAT_ID
            let token = '5654725825:AAGoNIE_7x0QCHoPlqZfgbmRosBQZ6hw2N8'
            let chat_id = '-1001630861682'

            // TEST TOKEN AND CHAT_ID
            // let token = '5819742667:AAHkrNEgCU6VLZ3v-XBfgRO7AJ5afTwhYXo'
            // let chat_id = '667611896'

            let appl_text = `Имя: ${this.user_name} %0AТелефон: ${this.userPhone} %0A(вопросы по платформе)`


            axios.post('https://api.telegram.org/bot' + token + '/sendMessage?chat_id=' + chat_id + '&parse_mode=&text=' + appl_text,
                  {
                     params: {
                        name: this.user_name,
                        phone: this.userPhone,
                     }
                  }
               )
               .then(()=>{
                  this.user_name = null;
                  this.userPhone = null;
                  this.confirm_checkbox = false;
                  // $('.main__modal.success').addClass('active');
                  // setTimeout(() => {
                  //   $('.main__modal.success').removeClass('active')
                  //   }, 2500);
                  $('.main__modal').removeClass('isApplicationForm');
                 window.location.href = "/thanks"
               })
               .catch(() => {

               })
            }
            this.errors = [];
            if(this.user_name === null) {
               this.errors.push("Вы пропустили поле \"Имя\"")
            }
            if (!this.userPhone) {
               this.errors.push("Вы пропустили поле \"Номер телефона\"")
            }
            if (this.confirm_checkbox === false) {
               this.errors.push('Вам необходимо принять условия соглашения')
            }
            if (this.errors.length >= 1) {
               notify({
                  group: 'app',
                  type: 'error',
                  text: this.errors.join(', <br>'),
               });
            }
            else {
               notify({
                  group: 'app',
                  type: 'success',
                  text: 'Ваша заявка успешно отправлена!',
               });
            }

      },
 
   }
}
</script>

<style>

</style>
