<template>
  <section class="lease-expired" id="lease-expired">
    <div class="container">
      <div class="lease-expired__text">
        <div class="success-icon">
          <svg width="1024" height="1024" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M512 64C630.817 64 744.768 111.2 828.784 195.216C912.8 279.232 960 393.183 960 512C960 630.817 912.8 744.768 828.784 828.784C744.768 912.8 630.817 960 512 960C393.183 960 279.232 912.8 195.216 828.784C111.2 744.768 64 630.817 64 512C64 393.183 111.2 279.232 195.216 195.216C279.232 111.2 393.183 64 512 64ZM456.192 600.384L356.672 500.8C353.104 497.232 348.869 494.402 344.207 492.471C339.546 490.54 334.55 489.547 329.504 489.547C324.458 489.547 319.462 490.54 314.801 492.471C310.139 494.402 305.904 497.232 302.336 500.8C295.131 508.005 291.083 517.778 291.083 527.968C291.083 538.158 295.131 547.931 302.336 555.136L429.056 681.856C432.614 685.442 436.846 688.288 441.509 690.23C446.172 692.172 451.173 693.172 456.224 693.172C461.275 693.172 466.276 692.172 470.939 690.23C475.602 688.288 479.834 685.442 483.392 681.856L745.792 419.392C749.407 415.839 752.284 411.605 754.255 406.935C756.226 402.265 757.253 397.251 757.276 392.182C757.3 387.113 756.32 382.09 754.392 377.402C752.464 372.713 749.628 368.453 746.046 364.867C742.463 361.28 738.207 358.439 733.521 356.505C728.835 354.572 723.812 353.586 718.743 353.604C713.674 353.621 708.659 354.642 703.987 356.608C699.314 358.574 695.077 361.445 691.52 365.056L456.192 600.384Z" fill="#4BB543"/>
          </svg>
        </div>
        <h1 class="lease-expired__title">Спасибо! <br>Ваша заявка успешно отправлена!</h1>
        <h3 class="lease-expired__subtitle">В течение 30 минут наш менеджер перезвонит вам и проконсультирует Вас по всем вопросам.</h3>
        <div class="lease-expired__btns">
          <router-link to="/" class="lease-expired__btn btn-blue">Вернуться на главную страницу</router-link>
        </div>
      </div>
      <div class="lease-expired__img">
        <img src="../assets/illustrations/ill-thanks.png" alt="">
      </div>
    </div>
  </section>
</template>

<script>
export default {
    data() {
      return {

      }
    }
}
</script>

<style scoped>
  .lease-expired {
    min-height: 600px;
  }

  .lease-expired .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }

  .lease-expired__text,
  .lease-expired__img {
    width: 50%;
  }

  .lease-expired__img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .lease-expired__title {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 135%;
    color: var(--black);
    margin: 0 0 20px 0;
  }

  .lease-expired__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #363636;
    margin: 0 0 30px 0;
  }

  .lease-expired__btns {
    display: flex;
    flex-direction: column;
    width: 360px;
    gap: 24px;
  }

  .lease-expired__btn.btn-blue,
  .lease-expired__btn.btn-aqua {
    border-radius: 30px;
  }

  .success-icon {
    margin: 20px 0;
    display: none;
  }
  .success-icon svg{
    width: 100px;
    height: 100px;
  }


  @media (max-width: 1400px) {

  }
  @media (max-width: 1200px) {

  }
  @media (max-width: 991px) {
    .lease-expired .container {
      flex-direction: column;
    } 
    .lease-expired__text,
    .lease-expired__img {
      width: 100%;
    }
    .lease-expired__img {
      display: none;
    }
    .lease-expired__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .success-icon {
      margin: 20px 0;
      display: block;
    }
  }
  @media (max-width: 768px) {

  }
  @media (max-width: 600px) {
  
  }
  @media (max-width: 500px) {
    .lease-expired__btns {
      width: 100%;
    }
  }
  @media (max-width: 414px) {

  }
  @media (max-width: 390px) {

  }
  @media (max-width: 375px) {

  }
  @media (max-width: 360px) {

  }
  @media (max-width: 320px) {

  }
</style>