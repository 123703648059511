<template>
  <section class="tariffs-page" id="tariffs-page">
    <div class="container">
      <div class="page__header">
        <h1 class="page__title">Тарифы</h1>
        <p class="page__subtitle">5 дней бесплатно, тестовый период с полным функционалом</p>
      </div>
      <div class="page__body">
        <div class="tariffs-page__content">
          <div class="tariffs-page__form">
            <div class="tariffs-page__form-block">
              <div class="tariffs-page__tabs">
                <div class="tariffs-page__tab-items">
                  <input checked id="radio1" name="radio" type="radio"/>
                  <label for="radio1" @click="openTariff('«АРЕНДА»', '')">
                    <img src="../assets/images/img-logo-for-radio-blue.svg" class="radio-logo-edu-blue" style="border-radius: 50%; width: 52px; height: 52px;" alt="">
                    <img src="../assets/images/img-logo-for-radio-white.svg" class="radio-logo-edu-white" style="border-radius: 50%; width: 52px; height: 52px;" alt="">

                    <div class="tariffs-page__tab-text">
                      <h3 class="tariffs-page__tab-name">Аренда</h3>
                      <div class="tariffs-page__tab-price">
                        <p>от 45 000 &#8376;</p>
                      </div>
                    </div>
                    <span class="checkmark"></span>
                  </label>
                  <div class="tariffs-page__tab-additional" :class="{'show': tariffAddShow}">
                      <p>PROFESSIONAL</p>
                      <div class="arenda-types">
                        <div class="tariffs-page__tab-add-sales" @click="tariffInner('1 месяц', 'Professional')">
                          <input type="radio" name="add-radio" id="add-radio0" class="add-radio">
                          <label for="add-radio0">
                            <h4 class="tariffs-page__tab-add-month">1 месяц</h4>
                            <p class="tariffs-page__tab-price">Память: <br>без ограничений</p>
                            <p class="tariffs-page__tab-price">Пользователи: <br>без ограничений</p>
                            <div class="tariffs-page__tab-add-price">
                              <h3 class="tariffs-page__tab-add-price-new">45 000 &#8376;</h3>
                            </div>
                          </label>
                        </div>
                        <div class="tariffs-page__tab-add-sales" @click="tariffInner('3 месяца', 'Professional')">
                          <input type="radio" name="add-radio" id="add-radio1" class="add-radio">
                          <label for="add-radio1">
                            <h4 class="tariffs-page__tab-add-month">3 месяца</h4>
                            <p class="tariffs-page__tab-price">Память: <br>без ограничений</p>
                            <p class="tariffs-page__tab-price">Пользователи: <br>без ограничений</p>
                            <div class="tariffs-page__tab-add-price">
                              <div class="tariffs-page__tab-add-price-sale">
                                <p class="tariffs-page__tab-add-price-sale-count">-10%</p>
                                <div class="tariffs-page__tab-add-price-old">
                                  <p>135 000 &#8376;</p>
                                </div>
                              </div>
                              <h3 class="tariffs-page__tab-add-price-new">121 000 &#8376;</h3>
                            </div>
                          </label>
                        </div>
                        <div class="tariffs-page__tab-add-sales" @click="tariffInner('6 месяцев', 'Professional')">
                          <input type="radio" name="add-radio" id="add-radio2" class="add-radio">
                          <label for="add-radio2">
                            <h4 class="tariffs-page__tab-add-month">6 месяцев</h4>
                            <p class="tariffs-page__tab-price">Память: <br>без ограничений</p>
                            <p class="tariffs-page__tab-price">Пользователи: <br>без ограничений</p>
                            <div class="tariffs-page__tab-add-price">
                              <div class="tariffs-page__tab-add-price-sale">
                                <p class="tariffs-page__tab-add-price-sale-count">-30%</p>
                                <div class="tariffs-page__tab-add-price-old">
                                  <p>270 000 &#8376;</p>
                                </div>
                              </div>
                              <h3 class="tariffs-page__tab-add-price-new">216 000 &#8376;</h3>
                            </div>
                          </label>
                        </div>
                        <div class="tariffs-page__tab-add-sales" @click="tariffInner('12 месяцев', 'Professional')">
                          <input type="radio" name="add-radio" id="add-radio3" class="add-radio">
                          <label for="add-radio3">
                            <h4 class="tariffs-page__tab-add-month">12 месяцев</h4>
                            <p class="tariffs-page__tab-price">Память: <br>без ограничений</p>
                            <p class="tariffs-page__tab-price">Пользователи: <br>без ограничений</p>
                            <div class="tariffs-page__tab-add-price">
                              <div class="tariffs-page__tab-add-price-sale">
                                <p class="tariffs-page__tab-add-price-sale-count">-40%</p>
                                <div class="tariffs-page__tab-add-price-old">
                                  <p>540 000 &#8376;</p>
                                </div>
                              </div>
                              <h3 class="tariffs-page__tab-add-price-new">378 000 &#8376;</h3>
                            </div>
                          </label>
                        </div>
                      </div>
                      <p>VIP</p>
                      <div class="arenda-types -vip">
                        
                        <div class="tariffs-page__tab-add-sales" @click="tariffInner('6 месяцев', 'VIP')">
                          <input type="radio" name="add-radio" id="add-radio5" class="add-radio">
                          <label for="add-radio5">
                            <h4 class="tariffs-page__tab-add-month">6 месяцев</h4>
                            <p class="tariffs-page__tab-price">Память: <br>без ограничений</p>
                            <p class="tariffs-page__tab-price">Пользователи: <br>без ограничений</p>
                            <p class="tariffs-page__tab-price">
                              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_704_58)">
                                <path d="M3.07359 6.10889C3.07359 6.10889 1.86422 6.20451 1.70297 6.47639C1.53984 6.74826 1.53047 7.16639 1.53047 7.62576C1.53047 8.08701 1.51172 9.14451 1.51172 9.14451L1.80047 9.76514C1.80047 9.76514 1.85484 17.6758 1.85484 17.9214C1.85484 18.1651 1.85484 18.4633 2.20734 18.6526C2.55984 18.842 11.5186 23.1695 11.798 23.2858C12.2198 23.4601 12.4205 23.3945 12.4205 23.3945L22.3748 6.74639C22.3748 6.74639 22.343 6.29076 21.9286 6.18201C21.6905 6.12201 3.07359 6.10889 3.07359 6.10889Z" fill="#F5B03E"/>
                                <path d="M12.3138 11.5821C12.1619 12.0003 12.2313 16.324 12.2575 19.4684C12.2838 22.6109 12.1769 23.2615 12.2575 23.3703C12.3381 23.479 12.5575 23.374 13.1781 23.0984C14.3313 22.5884 19.87 19.8471 20.2769 19.6034C20.6838 19.3596 21.6775 18.8553 21.8406 18.5834C22.0038 18.3115 22.0919 15.7821 22.1463 13.7515C22.2006 11.719 22.255 10.0934 22.255 10.0934L22.4988 9.36215C22.4988 9.36215 22.4463 7.3859 22.4369 7.1684C22.42 6.8084 22.3806 6.62277 22.2888 6.66777C22.1575 6.72965 21.0625 7.62777 18.1638 9.03777C16.3431 9.9209 12.4188 11.2934 12.3138 11.5821Z" fill="#E07F14"/>
                                <path d="M12.3429 2.26155C11.531 2.22405 9.30913 3.50842 7.16788 4.21155C5.02663 4.91467 1.79413 6.32842 1.76601 6.41092C1.73976 6.49155 3.06726 7.08592 3.91476 7.4928C4.92163 7.97467 8.76538 9.7953 9.46851 10.0934C10.1735 10.3915 11.8535 11.089 12.1235 11.0684C12.5023 11.0422 16.0798 9.63217 17.8948 8.76592C19.7098 7.89967 21.9785 6.55717 22.0085 6.23092C22.0216 6.08467 18.671 4.83405 17.0004 4.07842C13.7754 2.6178 12.9373 2.28967 12.3429 2.26155Z" fill="#FDD717"/>
                                <path d="M1.52281 9.49174C1.55094 9.84424 1.74406 9.96986 2.23531 10.1836C2.72656 10.3974 12.0134 15.2217 12.2272 15.2217C12.4409 15.2217 12.5047 14.4567 12.2703 14.4774C12.1428 14.4886 9.09219 13.0392 6.24219 11.5917C3.82344 10.3655 1.50781 9.14111 1.50781 9.14111L1.52281 9.49174Z" fill="#E37D14"/>
                                <path d="M22.5016 9.34521C22.5016 9.34521 12.5847 14.389 12.4872 14.4377C12.3897 14.4865 12.2341 14.4696 12.2341 14.4696L12.2266 15.2215C12.2266 15.2215 12.3184 15.2421 12.4216 15.2102C12.4816 15.1915 22.2147 10.1533 22.2578 10.1383C22.3009 10.1233 22.4884 10.0108 22.5278 9.77271C22.5616 9.58146 22.5016 9.34521 22.5016 9.34521Z" fill="#BA5E0D"/>
                                <path d="M5.41234 18.426L7.35297 21.3135L8.20234 21.6042C8.20234 21.6042 8.27172 18.4542 8.29984 16.1198C8.33922 12.7092 8.32422 9.59854 8.37297 9.42229C8.45359 9.12416 12.228 7.89979 12.228 7.89979C12.228 7.89979 16.4917 9.23666 16.6267 9.53479C16.6867 9.66604 16.6398 11.8748 16.6267 14.4492C16.6098 17.7004 16.6155 21.4542 16.6155 21.4542C16.6155 21.4542 17.0561 21.4523 17.1086 21.2892C17.163 21.126 17.3805 19.8679 17.3805 19.8679L19.0342 8.84479L19.8742 7.76104L18.708 5.54104L13.923 3.18604L12.2786 3.55541L10.4992 3.28354L4.85547 7.33916L5.51359 8.67416L7.12797 9.49728L5.41234 18.426Z" fill="#AF0F1B"/>
                                <path d="M17.1152 9.42035C17.012 9.64723 17.072 21.3379 17.072 21.3379C17.072 21.3379 17.5877 21.0529 18.2514 20.7341C18.9114 20.4172 19.3858 20.2204 19.427 20.0554C19.4683 19.8904 19.5414 8.3291 19.5414 8.3291L17.1152 9.42035Z" fill="#DC0D28"/>
                                <path d="M7.75422 9.17842C7.81609 9.24592 7.71484 21.3772 7.58922 21.4053C7.48984 21.4297 5.23047 20.4209 5.23047 20.3178C5.23047 20.2147 5.41234 8.62154 5.41234 8.62154C5.41234 8.62154 7.57047 8.97217 7.75422 9.17842ZM12.2317 2.83154C11.6073 2.83154 10.9173 3.00967 10.7167 3.53279C10.5161 4.05592 10.4711 4.83404 10.4711 5.41529C10.4711 5.97217 10.3811 7.39717 12.0967 7.37467C13.8123 7.35217 13.863 6.71654 13.8892 5.31404C13.9117 4.14404 13.7561 3.57592 13.6005 3.33217C13.4617 3.11279 13.0005 2.83154 12.2317 2.83154Z" fill="#FF2A23"/>
                                <path d="M11.237 5.72641C11.627 5.74891 11.7939 4.89204 12.0939 4.42329C12.3939 3.95641 12.707 3.64329 12.5945 3.39954C12.5026 3.19704 11.687 3.13141 11.2464 3.74454C10.937 4.17766 10.8826 5.70579 11.237 5.72641Z" fill="#FCC9D2"/>
                                <path d="M13.75 3.23819C13.75 3.23819 14.3031 3.80069 14.4475 4.90319C14.5919 6.00569 14.425 6.86257 14.425 6.86257C14.425 6.86257 17.1737 6.78007 18.1225 6.96194C19.0581 7.14382 19.3844 7.55632 19.3844 7.85632C19.3844 8.15632 18.7694 8.39819 18.145 8.35319C17.5206 8.30819 17.0987 8.27444 17.02 8.43194C16.9412 8.58944 17.0538 8.83319 17.9219 8.89882C18.79 8.96632 19.7931 8.84257 20.1044 7.76257C20.4156 6.68257 20.4269 4.81132 20.3162 3.46319C20.2056 2.11507 19.9619 1.34444 19.3581 1.05194C18.7225 0.746319 17.1306 0.695694 15.6269 1.54132C14.1231 2.38694 13.75 3.23819 13.75 3.23819Z" fill="#FF2A23"/>
                                <path d="M16.8632 1.95614C16.6682 1.70489 16.0401 1.71239 15.7044 2.10052C15.3482 2.51302 15.2694 4.08239 15.7269 4.17239C16.2444 4.27364 16.1507 3.42614 16.5294 2.90302C16.9007 2.39114 17.0526 2.20177 16.8632 1.95614Z" fill="#FCC9D2"/>
                                <path d="M10.5698 3.29279C10.5698 3.29279 8.46413 0.838414 6.0585 0.875914C4.611 0.898414 4.2435 1.73279 4.16475 2.07779C4.086 2.42279 3.48601 5.85404 4.28663 7.71216C5.08726 9.57029 6.7485 9.08841 7.00913 9.02841C7.34288 8.94966 8.0535 8.70591 7.986 8.44904C7.9185 8.19216 5.55788 9.11654 5.53538 8.05904C5.51288 7.00154 7.206 6.88904 7.986 6.86654C8.766 6.84404 10.1029 7.02216 10.1029 7.02216C10.1029 7.02216 9.86851 5.74154 10.0241 4.82841C10.1798 3.91529 10.5698 3.29279 10.5698 3.29279Z" fill="#FF2A23"/>
                                <path d="M5.41434 1.76673C5.11434 2.07798 4.99059 3.87235 5.17996 4.76298C5.36934 5.6536 5.53621 6.03235 5.92621 5.95548C6.25246 5.88985 6.44934 4.14235 6.60496 3.52735C6.77184 2.8711 7.18434 2.1736 7.01746 1.86798C6.76246 1.39923 5.72746 1.44235 5.41434 1.76673Z" fill="#FCC9D2"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_704_58">
                                <rect width="24" height="24" fill="white" transform="translate(0 0.159668)"/>
                                </clipPath>
                                </defs>
                              </svg>
                              <strong>Мобильное приложение на Маркетплейс</strong>
                            </p>
                            <div class="tariffs-page__tab-add-price">
                              <div class="tariffs-page__tab-add-price-sale">
                                <p class="tariffs-page__tab-add-price-sale-count">-15%</p>
                                <div class="tariffs-page__tab-add-price-old">
                                  <p>518 000 &#8376;</p>
                                </div>
                              </div>
                              <h3 class="tariffs-page__tab-add-price-new">450 000 &#8376;</h3>
                            </div>
                          </label>
                        </div>
                        
                        <div class="tariffs-page__tab-add-sales" @click="tariffInner('12 месяцев', 'VIP')">
                          <input type="radio" name="add-radio" id="add-radio7" class="add-radio">
                          <label for="add-radio7">
                            <h4 class="tariffs-page__tab-add-month">12 месяцев</h4>
                            <p class="tariffs-page__tab-price">Память: <br>без ограничений</p>
                            <p class="tariffs-page__tab-price">Пользователи: <br>без ограничений</p>
                            <p class="tariffs-page__tab-price">
                              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_704_58)">
                                <path d="M3.07359 6.10889C3.07359 6.10889 1.86422 6.20451 1.70297 6.47639C1.53984 6.74826 1.53047 7.16639 1.53047 7.62576C1.53047 8.08701 1.51172 9.14451 1.51172 9.14451L1.80047 9.76514C1.80047 9.76514 1.85484 17.6758 1.85484 17.9214C1.85484 18.1651 1.85484 18.4633 2.20734 18.6526C2.55984 18.842 11.5186 23.1695 11.798 23.2858C12.2198 23.4601 12.4205 23.3945 12.4205 23.3945L22.3748 6.74639C22.3748 6.74639 22.343 6.29076 21.9286 6.18201C21.6905 6.12201 3.07359 6.10889 3.07359 6.10889Z" fill="#F5B03E"/>
                                <path d="M12.3138 11.5821C12.1619 12.0003 12.2313 16.324 12.2575 19.4684C12.2838 22.6109 12.1769 23.2615 12.2575 23.3703C12.3381 23.479 12.5575 23.374 13.1781 23.0984C14.3313 22.5884 19.87 19.8471 20.2769 19.6034C20.6838 19.3596 21.6775 18.8553 21.8406 18.5834C22.0038 18.3115 22.0919 15.7821 22.1463 13.7515C22.2006 11.719 22.255 10.0934 22.255 10.0934L22.4988 9.36215C22.4988 9.36215 22.4463 7.3859 22.4369 7.1684C22.42 6.8084 22.3806 6.62277 22.2888 6.66777C22.1575 6.72965 21.0625 7.62777 18.1638 9.03777C16.3431 9.9209 12.4188 11.2934 12.3138 11.5821Z" fill="#E07F14"/>
                                <path d="M12.3429 2.26155C11.531 2.22405 9.30913 3.50842 7.16788 4.21155C5.02663 4.91467 1.79413 6.32842 1.76601 6.41092C1.73976 6.49155 3.06726 7.08592 3.91476 7.4928C4.92163 7.97467 8.76538 9.7953 9.46851 10.0934C10.1735 10.3915 11.8535 11.089 12.1235 11.0684C12.5023 11.0422 16.0798 9.63217 17.8948 8.76592C19.7098 7.89967 21.9785 6.55717 22.0085 6.23092C22.0216 6.08467 18.671 4.83405 17.0004 4.07842C13.7754 2.6178 12.9373 2.28967 12.3429 2.26155Z" fill="#FDD717"/>
                                <path d="M1.52281 9.49174C1.55094 9.84424 1.74406 9.96986 2.23531 10.1836C2.72656 10.3974 12.0134 15.2217 12.2272 15.2217C12.4409 15.2217 12.5047 14.4567 12.2703 14.4774C12.1428 14.4886 9.09219 13.0392 6.24219 11.5917C3.82344 10.3655 1.50781 9.14111 1.50781 9.14111L1.52281 9.49174Z" fill="#E37D14"/>
                                <path d="M22.5016 9.34521C22.5016 9.34521 12.5847 14.389 12.4872 14.4377C12.3897 14.4865 12.2341 14.4696 12.2341 14.4696L12.2266 15.2215C12.2266 15.2215 12.3184 15.2421 12.4216 15.2102C12.4816 15.1915 22.2147 10.1533 22.2578 10.1383C22.3009 10.1233 22.4884 10.0108 22.5278 9.77271C22.5616 9.58146 22.5016 9.34521 22.5016 9.34521Z" fill="#BA5E0D"/>
                                <path d="M5.41234 18.426L7.35297 21.3135L8.20234 21.6042C8.20234 21.6042 8.27172 18.4542 8.29984 16.1198C8.33922 12.7092 8.32422 9.59854 8.37297 9.42229C8.45359 9.12416 12.228 7.89979 12.228 7.89979C12.228 7.89979 16.4917 9.23666 16.6267 9.53479C16.6867 9.66604 16.6398 11.8748 16.6267 14.4492C16.6098 17.7004 16.6155 21.4542 16.6155 21.4542C16.6155 21.4542 17.0561 21.4523 17.1086 21.2892C17.163 21.126 17.3805 19.8679 17.3805 19.8679L19.0342 8.84479L19.8742 7.76104L18.708 5.54104L13.923 3.18604L12.2786 3.55541L10.4992 3.28354L4.85547 7.33916L5.51359 8.67416L7.12797 9.49728L5.41234 18.426Z" fill="#AF0F1B"/>
                                <path d="M17.1152 9.42035C17.012 9.64723 17.072 21.3379 17.072 21.3379C17.072 21.3379 17.5877 21.0529 18.2514 20.7341C18.9114 20.4172 19.3858 20.2204 19.427 20.0554C19.4683 19.8904 19.5414 8.3291 19.5414 8.3291L17.1152 9.42035Z" fill="#DC0D28"/>
                                <path d="M7.75422 9.17842C7.81609 9.24592 7.71484 21.3772 7.58922 21.4053C7.48984 21.4297 5.23047 20.4209 5.23047 20.3178C5.23047 20.2147 5.41234 8.62154 5.41234 8.62154C5.41234 8.62154 7.57047 8.97217 7.75422 9.17842ZM12.2317 2.83154C11.6073 2.83154 10.9173 3.00967 10.7167 3.53279C10.5161 4.05592 10.4711 4.83404 10.4711 5.41529C10.4711 5.97217 10.3811 7.39717 12.0967 7.37467C13.8123 7.35217 13.863 6.71654 13.8892 5.31404C13.9117 4.14404 13.7561 3.57592 13.6005 3.33217C13.4617 3.11279 13.0005 2.83154 12.2317 2.83154Z" fill="#FF2A23"/>
                                <path d="M11.237 5.72641C11.627 5.74891 11.7939 4.89204 12.0939 4.42329C12.3939 3.95641 12.707 3.64329 12.5945 3.39954C12.5026 3.19704 11.687 3.13141 11.2464 3.74454C10.937 4.17766 10.8826 5.70579 11.237 5.72641Z" fill="#FCC9D2"/>
                                <path d="M13.75 3.23819C13.75 3.23819 14.3031 3.80069 14.4475 4.90319C14.5919 6.00569 14.425 6.86257 14.425 6.86257C14.425 6.86257 17.1737 6.78007 18.1225 6.96194C19.0581 7.14382 19.3844 7.55632 19.3844 7.85632C19.3844 8.15632 18.7694 8.39819 18.145 8.35319C17.5206 8.30819 17.0987 8.27444 17.02 8.43194C16.9412 8.58944 17.0538 8.83319 17.9219 8.89882C18.79 8.96632 19.7931 8.84257 20.1044 7.76257C20.4156 6.68257 20.4269 4.81132 20.3162 3.46319C20.2056 2.11507 19.9619 1.34444 19.3581 1.05194C18.7225 0.746319 17.1306 0.695694 15.6269 1.54132C14.1231 2.38694 13.75 3.23819 13.75 3.23819Z" fill="#FF2A23"/>
                                <path d="M16.8632 1.95614C16.6682 1.70489 16.0401 1.71239 15.7044 2.10052C15.3482 2.51302 15.2694 4.08239 15.7269 4.17239C16.2444 4.27364 16.1507 3.42614 16.5294 2.90302C16.9007 2.39114 17.0526 2.20177 16.8632 1.95614Z" fill="#FCC9D2"/>
                                <path d="M10.5698 3.29279C10.5698 3.29279 8.46413 0.838414 6.0585 0.875914C4.611 0.898414 4.2435 1.73279 4.16475 2.07779C4.086 2.42279 3.48601 5.85404 4.28663 7.71216C5.08726 9.57029 6.7485 9.08841 7.00913 9.02841C7.34288 8.94966 8.0535 8.70591 7.986 8.44904C7.9185 8.19216 5.55788 9.11654 5.53538 8.05904C5.51288 7.00154 7.206 6.88904 7.986 6.86654C8.766 6.84404 10.1029 7.02216 10.1029 7.02216C10.1029 7.02216 9.86851 5.74154 10.0241 4.82841C10.1798 3.91529 10.5698 3.29279 10.5698 3.29279Z" fill="#FF2A23"/>
                                <path d="M5.41434 1.76673C5.11434 2.07798 4.99059 3.87235 5.17996 4.76298C5.36934 5.6536 5.53621 6.03235 5.92621 5.95548C6.25246 5.88985 6.44934 4.14235 6.60496 3.52735C6.77184 2.8711 7.18434 2.1736 7.01746 1.86798C6.76246 1.39923 5.72746 1.44235 5.41434 1.76673Z" fill="#FCC9D2"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_704_58">
                                <rect width="24" height="24" fill="white" transform="translate(0 0.159668)"/>
                                </clipPath>
                                </defs>
                              </svg>
                              <strong>Мобильное приложение на Маркетплейс</strong>
                            </p>
                            <div class="tariffs-page__tab-add-price">
                              <div class="tariffs-page__tab-add-price-sale">
                                <p class="tariffs-page__tab-add-price-sale-count">-30%</p>
                                <div class="tariffs-page__tab-add-price-old">
                                  <p>910 000 &#8376;</p>
                                </div>
                              </div>
                              <h3 class="tariffs-page__tab-add-price-new">700 000 &#8376;</h3>
                            </div>
                          </label>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="tariffs-page__tab-items">
                  <input id="radio2" checked name="radio" type="radio"/>
                  <label for="radio2" @click="openTariff('«ПОКУПКА»', '-', 2500000)">
                    <img src="../assets/images/img-logo-for-radio-blue.svg" class="radio-logo-edu-blue" style="border-radius: 50%; width: 52px; height: 52px;" alt="">
                    <img src="../assets/images/img-logo-for-radio-white.svg" class="radio-logo-edu-white" style="border-radius: 50%; width: 52px; height: 52px;" alt="">
                    <div class="tariffs-page__tab-text">
                      <h3 class="tariffs-page__tab-name">Покупка</h3>
                      <div class="tariffs-page__tab-price">
                        <div>
                          <span>2 999 990 &#8376;</span>
                        </div>
                        <p>2 500 000 &#8376;</p>
                      </div>
                    </div>
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="tariffs-page__tab-items">
                  <input id="radio3" name="radio" type="radio"/>
                  <label for="radio3" @click="openTariff('«ENTERPRISE»', '-', 'Под ваши индивидуальные требования после покупки тарифа с мобильным приложением')">
                    <img src="../assets/images/img-logo-for-radio-blue.svg" class="radio-logo-edu-blue" style="border-radius: 50%; width: 52px; height: 52px;" alt="">
                    <img src="../assets/images/img-logo-for-radio-white.svg" class="radio-logo-edu-white" style="border-radius: 50%; width: 52px; height: 52px;" alt="">
                    <div class="tariffs-page__tab-text">
                      <h3 class="tariffs-page__tab-name">Enterprise</h3>
                      <div class="tariffs-page__tab-price">
                        <p style="text-align: right">></p>
                      </div>
                    </div>
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="tariffs-page__tabs-result">
                <div class="tariffs-page__tab-result-items" v-if="tariffName === '«АРЕНДА»'" id="tariffs-tab-result-1">
                  <ul>
                    <li>Ежемесячные платежи</li>
                    <li>Поддержка платформы</li>
<!--                    <li>Личный менеджер</li>-->
                    <li>Бесплатная консультация</li>
                    <li>Онлайн прием платежей</li>
                    <li>Тестирование</li>
                    <li>Генерация сертификатов</li>
                  </ul>
                </div>
                <div class="tariffs-page__tab-result-items" v-else-if="tariffName === '«ПОКУПКА»'" id="tariffs-tab-result-2">
                  <ul>
                    <li>Интегрированная платежная система для рассрочки 0*0*12 <span class="-new">NEW</span></li>
                    <!-- <li>Дизайн личного бренда <span class="-new">NEW</span></li> -->
                    <li>Мобильное приложение V2 <span class="-new">NEW</span></li>
                    <li>Загрузка одного курса на платформу <span class="-new">NEW</span></li>
                    <!-- <li>Два таргетных видеоролика <span class="-new">NEW</span></li> -->
                    <li>Выбор модулей для приложения <span class="-new">NEW</span></li>
                    <li>Интеграция платежной системы <span class="-new">NEW</span></li>
                    <li>Защита персональных данных <span class="-new">NEW</span></li>
                    <li>Регистрация действия пользователей <span class="-new">NEW</span></li>
                    <li>Интеграция с внешними кросс платформами <span class="-new">NEW</span></li>
                    <li>Интеграция с социальными сетями <span class="-new">NEW</span></li>
                    <li>Неограниченное количество курсов</li>
<!--                    <li>Личный менеджер</li>-->
                    <!-- <li>Бесплатная консультация</li> -->
                    <li>Онлайн прием платежей
                      <span class="-pay">
                        <img src="../assets/icons/pay/ic-paybox.png" alt="icon ">
                        <img src="../assets/icons/pay/ic-paypost.png" alt="icon ">
                        <img src="../assets/icons/pay/ic-robokassa.png" alt="icon ">
                        <img src="../assets/icons/pay/ic-wallet-one.png" alt="icon ">
                        <img src="../assets/icons/pay/ic-cloudpayments.png" alt="icon ">
                        <img src="../assets/icons/pay/ic-kaspi.png" alt="icon ">
                      </span>
                    </li>
                    <li>Свой домен и бесплатный хостинг на год (до 10 GB хранилище)</li>
                    <!-- <li>Тестирование</li> -->
                    <!-- <li>Выдача сертификатов</li> -->
                    <li>Собственная платформа с ежеквартальным обновлением</li>
                    <li>Рейтинг</li>
                    <!-- <li>Промокоды</li> -->
                    <li>Техническая поддержка вашей платформы</li>
                    <li>Мобильное приложение в подарок <span class="-gift">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.07555 5.94934C3.07555 5.94934 1.86617 6.04497 1.70492 6.31684C1.5418 6.58872 1.53242 7.00684 1.53242 7.46622C1.53242 7.92747 1.51367 8.98497 1.51367 8.98497L1.80242 9.60559C1.80242 9.60559 1.8568 17.5162 1.8568 17.7618C1.8568 18.0056 1.8568 18.3037 2.2093 18.4931C2.5618 18.6825 11.5205 23.01 11.7999 23.1262C12.2218 23.3006 12.4224 23.235 12.4224 23.235L22.3768 6.58684C22.3768 6.58684 22.3449 6.13122 21.9305 6.02247C21.6924 5.96247 3.07555 5.94934 3.07555 5.94934Z" fill="#F5B03E"/>
                        <path d="M12.3157 11.4225C12.1638 11.8406 12.2332 16.1644 12.2595 19.3087C12.2857 22.4512 12.1788 23.1019 12.2595 23.2106C12.3401 23.3194 12.5595 23.2144 13.1801 22.9387C14.3332 22.4287 19.872 19.6875 20.2788 19.4437C20.6857 19.2 21.6795 18.6956 21.8426 18.4237C22.0057 18.1519 22.0938 15.6225 22.1482 13.5919C22.2026 11.5594 22.257 9.93373 22.257 9.93373L22.5007 9.20248C22.5007 9.20248 22.4482 7.22623 22.4388 7.00873C22.422 6.64873 22.3826 6.4631 22.2907 6.5081C22.1595 6.56998 21.0645 7.4681 18.1657 8.8781C16.3451 9.76123 12.4207 11.1337 12.3157 11.4225Z" fill="#E07F14"/>
                        <path d="M12.3409 2.10188C11.5291 2.06438 9.30718 3.34875 7.16593 4.05188C5.02468 4.755 1.79218 6.16875 1.76406 6.25125C1.73781 6.33188 3.06531 6.92625 3.91281 7.33313C4.91968 7.815 8.76343 9.63563 9.46656 9.93375C10.1716 10.2319 11.8516 10.9294 12.1216 10.9088C12.5003 10.8825 16.0778 9.4725 17.8928 8.60625C19.7078 7.74 21.9766 6.3975 22.0066 6.07125C22.0197 5.925 18.6691 4.67438 16.9984 3.91875C13.7734 2.45813 12.9353 2.13 12.3409 2.10188Z" fill="#FDD717"/>
                        <path d="M1.52281 9.33183C1.55094 9.68433 1.74406 9.80995 2.23531 10.0237C2.72656 10.2375 12.0134 15.0618 12.2272 15.0618C12.4409 15.0618 12.5047 14.2968 12.2703 14.3175C12.1428 14.3287 9.09219 12.8793 6.24219 11.4318C3.82344 10.2056 1.50781 8.9812 1.50781 8.9812L1.52281 9.33183Z" fill="#E37D14"/>
                        <path d="M22.5016 9.18567C22.5016 9.18567 12.5847 14.2294 12.4872 14.2782C12.3897 14.3269 12.2341 14.31 12.2341 14.31L12.2266 15.0619C12.2266 15.0619 12.3184 15.0825 12.4216 15.0507C12.4816 15.0319 22.2147 9.99379 22.2578 9.97879C22.3009 9.96379 22.4884 9.85129 22.5278 9.61317C22.5616 9.42192 22.5016 9.18567 22.5016 9.18567Z" fill="#BA5E0D"/>
                        <path d="M5.41039 18.2662L7.35102 21.1537L8.20039 21.4444C8.20039 21.4444 8.26977 18.2944 8.29789 15.96C8.33727 12.5494 8.32227 9.43875 8.37102 9.2625C8.45164 8.96437 12.226 7.74 12.226 7.74C12.226 7.74 16.4898 9.07687 16.6248 9.375C16.6848 9.50625 16.6379 11.715 16.6248 14.2894C16.6079 17.5406 16.6135 21.2944 16.6135 21.2944C16.6135 21.2944 17.0541 21.2925 17.1066 21.1294C17.161 20.9662 17.3785 19.7081 17.3785 19.7081L19.0323 8.685L19.8723 7.60125L18.706 5.38125L13.921 3.02625L12.2766 3.39562L10.4973 3.12375L4.85352 7.17937L5.51164 8.51437L7.12602 9.3375L5.41039 18.2662Z" fill="#AF0F1B"/>
                        <path d="M17.1152 9.26068C17.012 9.48756 17.072 21.1782 17.072 21.1782C17.072 21.1782 17.5877 20.8932 18.2514 20.5744C18.9114 20.2576 19.3858 20.0607 19.427 19.8957C19.4683 19.7307 19.5414 8.16943 19.5414 8.16943L17.1152 9.26068Z" fill="#DC0D28"/>
                        <path d="M7.75227 9.01875C7.81414 9.08625 7.71289 21.2175 7.58727 21.2456C7.48789 21.27 5.22852 20.2613 5.22852 20.1581C5.22852 20.055 5.41039 8.46188 5.41039 8.46188C5.41039 8.46188 7.56852 8.8125 7.75227 9.01875ZM12.2298 2.67188C11.6054 2.67188 10.9154 2.85 10.7148 3.37312C10.5141 3.89625 10.4691 4.67438 10.4691 5.25563C10.4691 5.8125 10.3791 7.2375 12.0948 7.215C13.8104 7.1925 13.861 6.55687 13.8873 5.15437C13.9098 3.98438 13.7541 3.41625 13.5985 3.1725C13.4598 2.95313 12.9985 2.67188 12.2298 2.67188Z" fill="#FF2A23"/>
                        <path d="M11.2389 5.56687C11.6289 5.58937 11.7958 4.73249 12.0958 4.26374C12.3958 3.79687 12.7089 3.48374 12.5964 3.23999C12.5046 3.03749 11.6889 2.97187 11.2483 3.58499C10.9389 4.01812 10.8846 5.54624 11.2389 5.56687Z" fill="#FCC9D2"/>
                        <path d="M13.75 3.07877C13.75 3.07877 14.3031 3.64127 14.4475 4.74377C14.5919 5.84627 14.425 6.70315 14.425 6.70315C14.425 6.70315 17.1737 6.62064 18.1225 6.80252C19.0581 6.98439 19.3844 7.39689 19.3844 7.69689C19.3844 7.99689 18.7694 8.23877 18.145 8.19377C17.5206 8.14877 17.0987 8.11502 17.02 8.27252C16.9412 8.43002 17.0537 8.67377 17.9219 8.7394C18.79 8.8069 19.7931 8.68314 20.1044 7.60314C20.4156 6.52314 20.4269 4.6519 20.3162 3.30377C20.2056 1.95565 19.9619 1.18502 19.3581 0.89252C18.7225 0.586895 17.1306 0.53627 15.6269 1.3819C14.1231 2.22752 13.75 3.07877 13.75 3.07877Z" fill="#FF2A23"/>
                        <path d="M16.8632 1.79623C16.6682 1.54498 16.0401 1.55248 15.7044 1.94061C15.3482 2.35311 15.2694 3.92248 15.7269 4.01248C16.2444 4.11373 16.1507 3.26623 16.5294 2.74311C16.9007 2.23123 17.0526 2.04186 16.8632 1.79623Z" fill="#FCC9D2"/>
                        <path d="M10.5717 3.13312C10.5717 3.13312 8.46608 0.678746 6.06046 0.716246C4.61296 0.738746 4.24546 1.57312 4.16671 1.91812C4.08796 2.26312 3.48796 5.69437 4.28858 7.5525C5.08921 9.41062 6.75046 8.92875 7.01108 8.86875C7.34483 8.79 8.05546 8.54625 7.98796 8.28937C7.92046 8.0325 5.55983 8.95687 5.53733 7.89937C5.51483 6.84187 7.20796 6.72937 7.98796 6.70687C8.76796 6.68437 10.1048 6.8625 10.1048 6.8625C10.1048 6.8625 9.87046 5.58187 10.0261 4.66875C10.1817 3.75562 10.5717 3.13312 10.5717 3.13312Z" fill="#FF2A23"/>
                        <path d="M5.41434 1.60682C5.11434 1.91807 4.99059 3.71244 5.17996 4.60307C5.36934 5.49369 5.53621 5.87244 5.92621 5.79557C6.25246 5.72994 6.44934 3.98244 6.60496 3.36744C6.77184 2.71119 7.18434 2.01369 7.01746 1.70807C6.76246 1.23932 5.72746 1.28244 5.41434 1.60682Z" fill="#FCC9D2"/>
                      </svg>

                    </span></li>
                  </ul>
                </div>
                <div class="tariffs-page__tab-result-items" v-else id="tariffs-tab-result-3">
                  <p style="color: red; margin-bottom: 10px;">Цена тарифа зависит от ТЗ</p>
                  <ul>
                    <li>Интегрированная платежная система для рассрочки 0*0*12 <span class="-new">NEW</span></li>
                    <!-- <li>Дизайн личного бренда <span class="-new">NEW</span></li> -->
                    <li>Мобильное приложение V2 <span class="-new">NEW</span></li>
                    <li>Загрузка одного курса на платформу <span class="-new">NEW</span></li>
                    <li>Выбор модулей для приложения <span class="-new">NEW</span></li>
                    <li>Интеграция платежной системы <span class="-new">NEW</span></li>
                    <li>Индивидуальная разработка под ваши требования</li>
                    <li>Разработка под определенный аналог или ТЗ по проекту</li>
                    <li>Мобильное приложение под ваши требования </li>
                    <li>Возможность принимать онлайн платежи </li>
                  </ul>
                </div>
              </div>
            </div>
            <button type="submit" class="tariffs-page__tab-btn btn-green" @click="freeApplicationFormOpen(tariffName)">Выбрать тариф</button>
          </div>

        </div>
      </div>
    </div>
    <Message />

    <ModalForm :isAppForm="isApplicationForm" :modalClose="modalClose" :tariffName="tariffName" :tariffInnerName="tariffInnerName" :tariffType="tariffType" :tariff-price="price"/>

    <ModalSuccess :modalClose="modalClose" />
  </section>

  <BackToTop />

</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import FaqBody from '@/components/FaqBody.vue';
import BackToTop from '@/components/BackToTop.vue';

import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import { notify  } from '@kyvg/vue3-notification';
import 'vue3-tel-input/dist/vue3-tel-input.css'
import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import ModalForm from '@/components/Modals/Form/Tarrifs.vue'
import ModalSuccess from '@/components/Modals/Success.vue'
import Message from '@/components/Message.vue'

export default {
    data() {
      return {
        tariffAddShow: false,
        isApplicationForm: false,
        tariffName: '«ПОКУПКА»',
        tariffInnerName: '3 месяца',
        tariffType: '',
        price: 1500000,
        allFaqs: [
          {
            question: "Если у меня будет больше пользователей, чем вмещает тариф?",
            answer: "",
            open: false
          },
          {
            question: "Какие возможны способы оплаты ?",
            answer: "Оплатить тариф можно банковской картой Visa/Mastercard, а также через Яндекс.Деньги, WebMoney и Qiwi Кошелек. Чтобы оплатить по безналичному расчету, напишите нам",
            open: false
          },
          {
            question: "Могу ли я в любое время сменить тарифный план?",
            answer: "",
            open: false
          },
          {
            question: "Как оформить тарифный план на индивидуальных условиях?",
            answer: "",
            open: false
          },
        ],
        props: ['faq', 'id', 'open', 'toggleOpen'],
      }
    },
    components: { Header, Footer, FaqBody, VueTelInput, ModalForm, ModalSuccess, BackToTop, MazPhoneNumberInput, Message },
    methods: {

      toggleOpen: function (index) {
        this.allFaqs = this.allFaqs.map(faq => {
          if (index == faq.id  ) {
            faq.open = !faq.open;
          } else {
            faq.open = false;
          }
          return faq;
        });
      },
      tariffInner(name, type) {
        if (name === '1 месяц' && type === 'Professional') {
          this.tariffInnerName = name
          this.tariffType = type
          this.price = 45000
          this.price = this.price.toLocaleString("fi-FI")
        } else if (name === '3 месяца' && type === 'Professional') {
          this.tariffInnerName = name
          this.tariffType = type
          this.price = 121000
          this.price = this.price.toLocaleString("fi-FI")
        } else if (name === '6 месяцев' && type === 'Professional') {
          this.tariffInnerName = name
          this.tariffType = type
          this.price = 216000
          this.price = this.price.toLocaleString("fi-FI")
        } else if (name === '12 месяцев' && type === 'Professional') {
          this.tariffInnerName = name
          this.tariffType = type
          this.price = 378000
          this.price = this.price.toLocaleString("fi-FI")
        } else if (name === '6 месяцев' && type === 'VIP') {
          this.tariffInnerName = name
          this.tariffType = type
          this.price = 450000
          this.price = this.price.toLocaleString("fi-FI")
        } else if (name === '12 месяцев' && type === 'VIP') {
          this.tariffInnerName = name
          this.tariffType = type
          this.price = 700000
          this.price = this.price.toLocaleString("fi-FI")
        }
      },
      openTariff(tariff_name, tariff_month, tariff_price) {
        if (tariff_name == '«АРЕНДА»') {
          this.tariffAddShow = true
          this.tariffName = tariff_name
          this.tariffInnerName = '1 месяц'
          this.price = tariff_price
          this.price = this.price.toLocaleString("fi-FI")
        }
        else if (tariff_name == '«ПОКУПКА»') {
          this.tariffAddShow = false
          this.tariffName = tariff_name
          this.tariffInnerName = tariff_month
          this.price = tariff_price
          this.price = this.price.toLocaleString("fi-FI")
        }
        else if (tariff_name == '«ENTERPRISE»') {
          this.tariffAddShow = false
          this.tariffName = tariff_name
          this.tariffInnerName = tariff_month
          this.price = tariff_price
          this.price = this.price.toLocaleString("fi-FI")
        }
      },
      freeApplicationFormOpen(tariff_name) {
        this.isApplicationForm = !this.isApplicationForm
        this.tariffName = tariff_name
      },
      modalClose() {
          this.isApplicationForm = false
          document.querySelector('.main__modal.success').style.display = 'none'
      },
    },
    mounted() {

    }
}
</script>

<style scoped>
  .tariffs-page {
    display: flex;
  }

  .tariffs-page .container {

  }

  .tariffs-page__content {

  }

  .tariffs-page__form {

  }

  .tariffs-page__form-block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  .tariffs-page__tabs {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .tariffs-page__tabs, .tariffs-page__tabs-result {
    width: 50%;
  }

  .tariffs-page__tab-items {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  //margin: 10px 0;
  position: relative;
  width: 100%;
}

.tariffs-page__tab-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.tariffs-page__tab-price div {
  font-size: 15px;
  text-decoration: line-through;
  font-weight: 500;
  color: red;
}

.tariffs-page__tab-price div span {
  color: #91969e;
}

.tariffs-page__tab-items label {
  padding: 15px 16px;
  width: auto;
  display: block;
  text-align: left;
  color: #3C454C;
  cursor: pointer;
  position: relative;
  z-index: 0;
  transition: color 200ms ease-in;
  overflow: hidden;
  display: flex;
  align-items: center;
  column-gap: 20px;
  border-radius: 6px;
}
.tariffs-page__tab-items label:before {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  content: '';
  background-color: var(--main-color);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}

.tariffs-page__tab-items input:checked ~ label {
  color: #fff;
}
.tariffs-page__tab-items input:checked ~ label .tariffs-page__tab-price span {
  color: #fff;
}
.tariffs-page__tab-items input:checked ~ label .tariffs-page__tab-price div {
  color: #fff;
}
.tariffs-page__tab-items input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.tariffs-page__tab-items input:checked ~ label:after {
  background-color: #54E0C7;
  border-color: #54E0C7;
}
.radio-logo-edu-white {
  display: none;
}

.tariffs-page__tab-items input:checked ~ label .radio-logo-edu-white {
  display: block;
}
.tariffs-page__tab-items input:checked ~ label  .radio-logo-edu-blue {
  display: none;
}


.tariffs-page__tab-items input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}
.tariffs-page__tab-items .checkmark {
  opacity: 0;
  width: 0;
  height: 0;
}

.tariffs-page__tab-additional {
  display: none;
}
.tariffs-page__tab-additional > p {
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  color: var(--black);
  margin: 20px 0 8px 0;
}
.arenda-types {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding: 15px;
  background: #f5faff;
  border-radius: 6px;
}
.arenda-types.-vip {
  background: #ffe77e;
}
.arenda-types.-vip .tariffs-page__tab-add-sales {
  width: 50%;
}

.tariffs-page__tab-additional.show {
  display: block;
}

.tariffs-page__tab-add-sales {
  width: 25%;
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.tariffs-page__tab-add-sales label {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  padding: 10px;
  text-align: center;
}

.tariffs-page__tab-add-sales label::before {
  background-color: var(--main2-color);
}

.tariffs-page__tab-add-sales label p {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.tariffs-page__tab-add-price {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  align-items: center;
  width: 100%;
  margin-top: auto;
}

.tariffs-page__tab-add-price-sale {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  column-gap: 7px;
}

.tariffs-page__tab-add-price-sale-count {
  background: red;
  font-size: 12px;
  color: var(--white);
  border-radius: 6px;
  padding: 2.5px 5px;
  width: fit-content;
}

.tariffs-page__tab-add-price-old {
  text-decoration: line-through;
  color: red;
}

.tariffs-page__tab-add-price-old p {
  color: #91969e;
}

.tariffs-page__tab-add-sales input:checked ~ label p {
  color: var(--white);
}

.add-radio {
  /* position: relative !important;
  visibility: visible !important;
  width: 15px !important;
  height: 15px !important; */
}

  .tariffs-page__tab-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    column-gap: 30px;
  }

  .tariffs-page__tab-result-items {
    padding: 15px 30px;
    border-left: 1px solid #C4C8CC;
    display: block;
  }


  .tariffs-page__tab-result-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .tariffs-page__tab-result-items ul li {
    display: flex;
    align-items: center;
    gap: 7px;
    background: url(../assets/icons/ic-tariff-list-done.svg) no-repeat left;
    padding: 8px 8px 8px 30px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    color: var(--black);
  }

  .tariffs-page__tab-result-items ul li .small-text {
    font-size: 12px;
  }

  .tariffs-page__tab-result-items ul li span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .tariffs-page__tab-result-items ul li .-new {
    background: red;
    font-size: 10px;
    color: var(--white);
    border-radius: 6px;
    padding: 2.5px 5px;
  }

  .tariffs-page__tab-result-items ul li .-pay img {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }

  .tariffs-page__tab-btn {
    font-family: "SF Pro Display";
    margin: 0 auto;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    margin-top: 40px;
  }


  .faq {
    display: flex;
  }
  @media (max-width: 1400px) {

  }
  @media (max-width: 1200px) {

  }
  @media (max-width: 991px) {
    .tariffs-page__tabs-result,
    .tariffs-page__tabs {
      width: 100%;
    }
    .tariffs-page__form-block {
      flex-direction: column;
    }
    .tariffs-page__tabs {
      gap: 10px;
    }
    .tariffs-page__tab-result-items {
      border-top: 1px solid #C4C8CC;
      border-left: none;
    }
    .tariffs-page__tab-add-sales {
      width: 50%;
    }
  }
  @media (max-width: 768px) {

  }
  @media (max-width: 600px) {
  
    .tariffs-page__tab-items {
      width: auto;
    }
    .tariffs-page__tab-text {
      font-size: 13px;
    }
    .tariffs-page__tab-result-items ul li {
      font-size: 13px;
    }
    .tariffs-page__tab-btn {
      position: fixed;
      bottom: 20px;
      left: 30px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
      z-index: 10;
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .tariffs-page__tab-additional {
      grid-template-columns: 1fr;
      gap: 10px;
    }
    .tariffs-page__tab-add-sales label {
      flex-direction: row;
      justify-content: center;
    }
    .tariffs-page__tab-price {
      flex-direction: row;
      gap: 7px;
    }
    .tariffs-page__tab-price div {
      font-size: 13px;
    }
  }
  @media (max-width: 500px) {
    .tariffs-page__tab-add-sales label {
      flex-direction: column;
      font-size: 14px;
    }

    .tariffs-page__tab-price {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
    .tariffs-page__tab-text {
      flex-direction: row;
    }
    .tariffs-page__tab-price {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
    .tariffs-page__tab-text {
      flex-direction: row;
    }
  }
  @media (max-width: 414px) {

  }
  @media (max-width: 390px) {
    .tariffs-page__tab-text {
      font-size: 13px;
      flex-direction: row;
      gap: 6px;
      align-items: center;
    }
  }
  @media (max-width: 375px) {

  }
  @media (max-width: 360px) {

  }
  @media (max-width: 320px) {

  }
</style>