<template>
   <section class="services" id="services">
      <div class="container">
         <div class="section__header">
         <h2 class="section__title">МЫ делаем <span class="text-blue">акцент на сервис</span></h2>
         </div>
         <div class="section__body">
         <div class="services__list">
            <ul>
               <li v-for="(servicesListItem, i) of servicesList" :key="i">
               {{ servicesListItem}}
               </li>
            </ul>
         </div>
         <img src="../../../../assets/illustrations/ill-services-1.png" alt="" style="" class="services__main-img-bg">
         </div>
      </div>
      <img src="../../../../assets/images/img-services-1.png" alt="" class="services__main-img">
      <img src="../../../../assets/illustrations/ill-services-2.png" alt="" style="position:absolute;top:0;left:0;">
   </section>
</template>

<script>
export default {
   data() {
      return {
         servicesList: [
            'К каждому клиенту мы прикрепляем своего менеджера',
            'Ваш менеджер будет консультировать и помогать Вам сразу же после вашего обращения',
            'Линия тех поддержки работает до 00.00, что очень удобно и эффективно',
            'Все наши сотрудники имеют квалификацию и большой стаж работы',
         ],
      }
   },
}
</script>

<style scoped>
.services__list ul li {
  background: url(../../../../assets/icons/ic-list-done.svg) no-repeat left center;
  padding: 8px 0px 8px 26px;
}
</style>