import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue'
import { notify  } from '@kyvg/vue3-notification';
import { VueTelInput } from 'vue3-tel-input'
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import FaqBody from '@/components/FaqBody.vue';
import BackToTop from '@/components/BackToTop.vue'
import ModalVideo from '@/components/Modals/Video.vue';
import MainBlock from '@/views/MainPage/components/Main/Index.vue';
import Stat from '@/views/MainPage/components/Stat/Index.vue';
import AboutVideo from '@/views/MainPage/components/AboutVideo/Index.vue';
import Advantages from '@/views/MainPage/components/Advantages/Index.vue';
import MobileApp from '@/views/MainPage/components/MobileApp/Index.vue';
import Price from '@/views/MainPage/components/Price/Index.vue';
import Works from '@/views/MainPage/components/Works/Index.vue';
import Reviews from '@/views/MainPage/components/Reviews/Index.vue';
import Services from '@/views/MainPage/components/Services/Index.vue';
import Faq from '@/views/MainPage/components/Faq/Index.vue';
import Feedback from '@/views/MainPage/components/Feedback/Index.vue';
import Message from '@/components/Message.vue'

import Vue3autocounter from 'vue3-autocounter';
import axios from 'axios';
import 'vue3-tel-input/dist/vue3-tel-input.css'
import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

import ExpandableImage from '@/components//ExpandableImage'
import ModalForm from "@/components/Modals/Form/Tarrifs.vue";

export default {
  data() {
    return {
    }
  },
  components: {
      ModalForm,
      Header, Footer,
      Swiper,
      SwiperSlide,
      FaqBody,
      BackToTop,
      ModalVideo,
      Vue3autocounter,
      axios,
      VueTelInput,
      ExpandableImage,
      MazPhoneNumberInput,

      MainBlock,
      Stat,
      AboutVideo,
      Advantages,
      MobileApp,
      Price,
      Works,
      Reviews,
      Services,
      Faq,
      Feedback,
      Message,
    },
}

