<template>
    <section class="blog-page" id="blog-page">
      <div class="container">
        <div class="page__header">
          <h1 class="page__title">Блог</h1>
          <p class="page__subtitle">Делимся своими знаниями и опытом <br>
по развитию и введению онлайн образования</p>
        </div>
        <div class="page__body">
          <div class="blog-page__search">
            <div class="page__search-form">
              <button class="page__search-btn" type="button" @click="() => searchBlogs(blogParam)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.7138 6.8382C18.1647 9.28913 18.1647 13.2629 15.7138 15.7138C13.2629 18.1647 9.28913 18.1647 6.8382 15.7138C4.38727 13.2629 4.38727 9.28913 6.8382 6.8382C9.28913 4.38727 13.2629 4.38727 15.7138 6.8382" stroke="#6D7885" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19 19L15.71 15.71" stroke="#6D7885" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <input type="search" v-model="blogParam" @keypress.enter="searchBlogs(blogParam)" class="page__search-input" placeholder="Поиск по блогу">
            </div>
          </div>
          <div class="blog-page__body">
            <div class="blog-page__filtering">
              <div class="blog-page__filtering-btns"
                  :class="{ 'isBlogFilter' : selectedCategory == blogCategory }"
                  v-for="blogCategory in blogCategories" 
                  :key="blogCategory" 
                  @click="() => filterBlogs(blogCategory)"
                >
                {{ blogCategory }}
              </div>
            </div>
            <div class="blog-page__content">
              <div class="blog-page__card">
                <div class="blog-page__card-items" v-for="(blog, index) of blogs" :key="index">
                  <div class="blog-page__card-img">
                    <img :src="blog.img" alt="">
                  </div>
                  <div class="blog-page__card-text">
                    <span class="blog-page__card-category">{{ blog.category }}</span>
                    <h3 class="blog-page__card-title">{{ blog.title }}</h3>
                    <p class="blog-page__card-subtitle">{{ blog.subtitle }}</p>
                    <router-link to="/blog/1" class="blog-page__card-link">
                      <span>Читать полностью</span>
                      <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.3536 4.85355C16.5488 4.65829 16.5488 4.34171 16.3536 4.14645L13.1716 0.964467C12.9763 0.769205 12.6597 0.769205 12.4645 0.964467C12.2692 1.15973 12.2692 1.47631 12.4645 1.67157L15.2929 4.5L12.4645 7.32843C12.2692 7.52369 12.2692 7.84027 12.4645 8.03553C12.6597 8.2308 12.9763 8.2308 13.1716 8.03554L16.3536 4.85355ZM-4.37114e-08 5L16 5L16 4L4.37114e-08 4L-4.37114e-08 5Z" fill="#007AFF"/>
                      </svg>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="blog-page__content-btn">
                <button class="btn-blue">Показать еще</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <BackToTop />

</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import BackToTop from '@/components/BackToTop.vue';

export default {
    data() {
      return {
        blogCategories: [
          'Все',
          'Маркетинг',
          'Дизайн',
          'Разработка',
          'Продажа',
          'Бизнес',
        ],
        blogs: [
        {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать деньги в Тиктоке',
            category: 'Маркетинг',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать Продажа в Тиктоке',
            category: 'Продажа',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать деньги в Тиктоке',
            category: 'Маркетинг',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать Дизайн в Тиктоке',
            category: 'Дизайн',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать деньги в Тиктоке',
            category: 'Маркетинг',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать Разработка в Тиктоке',
            category: 'Разработка',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать деньги в Тиктоке',
            category: 'Маркетинг',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать Бизнес в Тиктоке',
            category: 'Бизнес',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать деньги в Тиктоке',
            category: 'Маркетинг',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать Бизнес в Тиктоке',
            category: 'Бизнес',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
        ],
        filteringBlogs: [
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать деньги в Тиктоке',
            category: 'Маркетинг',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать Продажа в Тиктоке',
            category: 'Продажа',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать деньги в Тиктоке',
            category: 'Маркетинг',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать Дизайн в Тиктоке',
            category: 'Дизайн',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать деньги в Тиктоке',
            category: 'Маркетинг',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать Разработка в Тиктоке',
            category: 'Разработка',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать деньги в Тиктоке',
            category: 'Маркетинг',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать Бизнес в Тиктоке',
            category: 'Бизнес',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать деньги в Тиктоке',
            category: 'Маркетинг',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
          {
            img: require('../assets/images/img-blog-item-1.jpg'),
            title: 'Как делать Бизнес в Тиктоке',
            category: 'Бизнес',
            subtitle: 'Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...',
          },
        ],
        selectedCategory: 'Все'
      }
    },
    components: { Header, Footer, BackToTop },
    methods: {
      filterBlogs(categoryName) {
        this.resetBlogs()
        if (categoryName !== 'Все') {
          this.blogs = this.blogs.filter((blog) => {
            return blog.category === categoryName
          })
          this.selectedCategory = categoryName
        }
        else {
          this.selectedCategory = categoryName
        }
      },
      searchBlogs(blogParam) {
        this.resetBlogs()
        this.blogs = this.blogs.filter((blog) => {
          return blog.title.toLowerCase().includes(blogParam.toLowerCase())
        })
      },
      resetBlogs() {
        this.blogs = this.filteringBlogs;
      },
    },
}
</script>

<style scoped>
  .blog-page {
    display: flex;
  }

  .blog-page .container {

  }

  .blog-page__search {
    width: 500px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .blog-page__filtering {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    margin: 30px 0;
  }

  .blog-page__filtering-btns {
    padding: 16px;
    background: #F3F3F3;
    border-radius: 100px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center; 
    justify-content: center;
    user-select: none;
    cursor: pointer; 
    color: #1B1D1A;
  }

  .blog-page__filtering-btns.isBlogFilter {
    background: var(--main-color);
    color: var(--white);
  }

  .blog-page__card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
  }

  .blog-page__card-items {
    background: var(--white);
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    overflow: hidden;
  }

  .blog-page__card-img {
    width: 100%;
    height: 180px;
  }

  .blog-page__card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog-page__card-text {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .blog-page__card-category {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--main-color);
    margin-bottom: 10px;
  }

  .blog-page__card-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #1B1D1A;
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .blog-page__card-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #ADADAD;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .blog-page__card-link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: var(--main-color);
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 12px;
  }

  .blog-page__content-btn {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }

  .blog-page__content-btn button {
    width: 300px;
  }

  @media (max-width: 1400px) {

  }
  @media (max-width: 1200px) {
    .blog-page__card {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (max-width: 991px) {
    .blog-page__card {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 768px) {
    .blog-page__filtering  {
      justify-content: flex-start;
      gap: 8px;
    }

    .blog-page__filtering-btns {
      padding: 10px;
      font-size: 13px;
    }

    .blog-page__search {
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    .blog-page__card {
      grid-template-columns: 1fr;
    } 
  }
  @media (max-width: 414px) {
   
  }
  @media (max-width: 390px) {

  }
  @media (max-width: 375px) {

  }
  @media (max-width: 360px) {

  }
  @media (max-width: 320px) {

  }
</style>