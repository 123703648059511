<template>
   <div class="main__modal success" >
      <div class="main__modal-back" ></div>
      <div class="main__modal-card" >
         <div class="main__modal-header">
            <svg class="main__modal-close-btn" @click="modalClose()" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="30" height="30" rx="15" fill="black" fill-opacity="0.05"/>
              <path d="M11 11L19 19" stroke="#2C2D2E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19 11L11 19" stroke="#2C2D2E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
         </div>
         <div class="main__modal-body">
            <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
               <circle opacity="0.3" cx="75" cy="75" r="75" fill="#BABABA" fill-opacity="0.4"/>
               <circle cx="75" cy="75" r="45" fill="#BABABA" fill-opacity="0.3"/>
               <path d="M99.2409 55.4705C98.3493 54.7448 97.0409 54.8743 96.3097 55.7501L68.6021 88.9514L53.5567 74.1055C52.7421 73.3016 51.4255 73.3016 50.6109 74.1055C49.7964 74.9094 49.7964 76.2087 50.6109 77.0126L67.2772 93.4602C67.6668 93.8487 68.198 94.0625 68.7501 94.0625C68.7834 94.0625 68.8168 94.0625 68.8501 94.0605C69.4396 94.0338 69.9854 93.7624 70.3584 93.3141L99.5242 58.3632C100.255 57.4853 100.13 56.1921 99.2409 55.4705Z" fill="#43D854"/>
            </svg>
            <h3>Спасибо! Ваша заявка успешно отправлена!</h3>
            <h5>В течение 30 минут наш менеджер перезвонит вам и проконсультирует Вас по всем вопросам.</h5>
         </div>
         <div class="main__modal-footer"></div>
      </div>
   </div>
</template>

<script>

export default {
   data() {
      return {

      }
   },
   props: ['modalClose'],
   methods: {
      
   }
}
</script>

<style>
   .main__modal.success {
      display: none;
   }

   .main__modal.success.active {
      display: flex;
   }

   .main__modal.success .main__modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center ;
   }

   .main__modal.success .main__modal-body svg {
      margin-bottom: 30px;
   }
   
   .main__modal.success .main__modal-body h3 {
     font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: var(--black);
      margin-bottom: 16px;
   }
   .main__modal.success .main__modal-body h5 {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #4D4D4D;
      margin-bottom: 16px;
   }
</style>