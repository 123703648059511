<template>
  <section class="services-page" id="services-page">
    <div class="container">
      <div class="page__header">
        <h1 class="page__title">Услуги</h1>
        <p class="page__subtitle">Различные виды услуг по платформе</p>
      </div>
      <div class="page__body">
        <div class="services-page__content">
          <div class="services-page__card">
            <div class="services-page__items" v-for="(service, index) of services" :key="index">
              <div class="">
                <div class="services-page__card-img">
                  <img :src="service.img" alt="">
                </div>
                <div class="services-page__card-text">
                  <h3 class="services-page__card-title">{{ service.title}}</h3>
                  <div class="services-page__card-subtitle">
                    <p>{{ service.description }}</p>
                    <ul v-if="service.isVisible">
                      <li v-for="(serviceListItem, index) of service.list" :key="index">{{ serviceListItem }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="services-page__card-footer">
                <h3 class="services-page__card-price">{{ service.priceCount }} ₸/{{ service.priceType }}</h3>
                <button type="submit" class="services-page__card-btn btn-green" @click="freeApplicationFormOpen(service.title, service.priceCount, service.priceType)">Заказать услугу</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalServices :isAppForm="isApplicationForm" :modalClose="modalClose" :serviceName="serviceName" :servicePrice="servicePrice" :servicePriceType="servicePriceType"/>

    <ModalSuccess :modalClose="modalClose" />
  </section>
  <Message />
  <BackToTop />
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import BackToTop from '@/components/BackToTop.vue';

import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import { notify  } from '@kyvg/vue3-notification';
import 'vue3-tel-input/dist/vue3-tel-input.css'
import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

import ModalServices from '@/components/Modals/Form/Services.vue'
import ModalSuccess from '@/components/Modals/Success.vue'
import Message from '@/components/Message.vue'

export default {
    data() {
      return {
        isApplicationForm: false,
        services: [
          // {
          //   img: require('../assets/images/img-services-page-1.png'),
          //   title: 'Консультация',
          //   description: 'На консультации вы сможете получить квалифицированную помощь по настройке платформы от и до. Консультации проводятся онлайн (видеозвонок) или офлайн (личная встреча).',
          //   list: [
          //     '',
          //   ],
          //   isVisible: false,
          //   priceCount: 0,
          //   priceType: 'бесплатно',
          // },
          // {
          //   img: require('../assets/images/img-services-page-2.png'),
          //   title: 'Базовая настройка',
          //   description: 'После оформления заказа в самое ближайшее время с вами свяжется технический специалист для первичной настройки платформы. В базовую настройку входит:',
          //   list: [
          //     'подключение домена',
          //     'установка сертификата безопасности SSL',
          //     'установка логотипа и названия сайта',
          //     'настройка доступа администратора сайта',
          //     'загрузка одного курса и одного видеоурока',
          //   ],
          //   isVisible: true,
          //   priceCount: 0,
          //   priceType: 'бесплатно',
          // },
          // {
          //   img: require('../assets/images/img-services-page-4.png'),
          //   title: 'Технический специалист (Базовый)',
          //   description: 'В услуги технического специалиста входит:',
          //   list: [
          //     'полная настройка сайта (установка домена, логотипа);',
          //     'техническая поддержка сайта;',
          //     'загрузка курсов;',
          //     'загрузка видеоуроков;',
          //     'загрузка домашнего задания;',
          //     'добавление учеников, сотрудников;',
          //     'настройка конференции, прием платежей;',
          //     'создание инструкций для учеников и кураторов,',
          //     'настройка дополнительных модулей.',
          //   ],
          //   isVisible: true,
          //   priceCount: 50000,
          //   priceType: 'в месяц',
          // },
          {
            img: require('../assets/images/img-services-page-4.png'),
            title: 'Технический специалист (Базовый)',
            description: 'В услуги технического специалиста входит:',
            list: [
              'Добавление курсов',
              'Добавление тестов',
              'Добавление пользователей (неограниченно)',
              'Настройка модуля Группы и Расписание курсов',
              'Настройка платежной системы',
              'Доступность сервиса в будние дни',
            ],
            isVisible: true,
            priceCount: 75000,
            priceType: 'в месяц',
          },
          {
            img: require('../assets/images/img-services-page-4.png'),
            title: 'Технический специалист (Advanced)',
            description: 'Все бонусы тарифа Базовый а так же новые услуги ввиде:',
            list: [
              'Интеграция вашей системы CRM и ее настройка в тарифах “Покупка”',
              'Добавление сертификата',
              'Добавление ЕНТ теста(в тарифе Покупка)',
              'Настройка рассылки почты и помощь с созданием рабочей почты',
              'Настройка Whatsapp рассылки',
              'Настройка модуля Группы и Расписание курсов',
              'Локализация платформы на удобный вам язык',
              'Настройка модулей',
              'Доступность сервиса в будни и выходные',
            ],
            isVisible: true,
            priceCount: 100000,
            priceType: 'в месяц',
          },
          {
            img: require('../assets/images/img-services-page-5.png'),
            title: 'Настройка одного курса',
            description: 'В настройки курса входит:',
            list: [
              'базовая настройка;',
              'загрузка одного курса;',
              'загрузка модулей курса;',
              'загрузка видеоуроков;',
              'добавление материалов курса;',
              'добавление домашнего задания к уроку.',
            ],
            isVisible: true,
            priceCount: 35000,
            priceType: 'ед',
          },
          {
            img: require('../assets/images/img-services-page-6.png'),
            title: 'Настройка модуля',
            description: 'Услуга предусматривает настройку дополнительных модулей на моб.приложений.',
            list: [
              '',
            ],
            isVisible: false,
            priceCount: 25000,
            priceType: 'ед',
          },
        ],
        serviceName: 'tertretre',
        servicePrice: 'erter',
        servicePriceType: 'ret',
      }
    },
    components: { Header, Footer, VueTelInput, BackToTop, ModalServices, ModalSuccess, MazPhoneNumberInput, Message },
    methods: {
      freeApplicationFormOpen(service_name, service_price, service_priceType) {
        this.isApplicationForm = !this.isApplicationForm
        this.serviceName = service_name 
        this.servicePrice = service_price 
        this.servicePriceType = service_priceType 
      },

      modalClose() {
        this.isApplicationForm = false
        document.querySelector('.main__modal.success').style.display = 'none'
      },
    },
    mounted() {
   
    },
}
</script>

<style scoped>
  .services-page {

  }

  .services-page .container {

  }

  .services-page__content {

  }

  .services-page__card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }
  
  .services-page__items {
    background: var(--white);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .services-page__card-img {
    width: 100%;
    height: 180px;
  }

  .services-page__card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .services-page__card-text {
    padding: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .services-page__card-title {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    margin-bottom: 12px;
  }

  .services-page__card-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #636363;
  }

  .services-page__card-subtitle ul {
    margin: 0 0 0 18px;
    padding: auto;
  }

  .services-page__card-footer {
    display: flex;
    flex-direction: column;
    gap:40px;
    padding: 18px;
 }

  .services-page__card-price {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 110%;
    color: var(--black);
  }

  .services-page__show-hide {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #184E88;
    margin-top: 8px;
    cursor: pointer;
  }

  .invisible {
    display: none;
  }

  @media (max-width: 1400px) {

  }
  @media (max-width: 1200px) {

  }
  @media (max-width: 991px) {
    .services-page__card {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 768px) {

  }
  @media (max-width: 600px) {
    .services-page__card {
      grid-template-columns: 1fr;
    } 
  }
  @media (max-width: 500px) {
  
  }
  @media (max-width: 414px) {

  }
  @media (max-width: 390px) {

  }
  @media (max-width: 375px) {

  }
  @media (max-width: 360px) {

  }
  @media (max-width: 320px) {

  }
</style>