<template>
  <main>
    <main-block />
    <stat />
    <about-video />
    <advantages />
    <mobile-app />
    <price />
    <works />
    <reviews />
    <services />
    <faq />
    <feedback />

    <message />
    <BackToTop />
  </main>
</template>

<script src="./MainPage.js"></script>

<style>



</style>