<template>
  <section class="feedback" id="feedback">
    <div class="feedback-container">
      <div class="container">
        <div class="section__body">
          <div class="feedback__titles">
            <h3 class="feedback__title">У Вас остались еще вопросы
  по платформе?</h3>
            <p class="feedback__subtitle">Заполните форму для связи , и мы обязательно свяжемся с Вами</p>
          </div>
          <form class="feedback__form" @submit.prevent="checkForm()">
            <input type="text" name="name" id="user_name2" v-model="user_name" placeholder="Ваше имя*">
            <MazPhoneNumberInput 
              id="userPhoneId"
              v-model="userPhone"
              default-country-code="KZ"
              isValid="false"
              show-code-on-list
              :only-countries="['KZ','RU','KG','UZ','UA','BY','AM','TJ','AZ','MD']"
              :translations="{
                countrySelector: {
                  placeholder: 'Код страны',
                  error: 'Выберите страну',
                },
                phoneInput: {
                  placeholder: 'Номер телефона',
                  example: 'Пример:',
                },
              }"
              :custom-countries-list="{
                KZ: 'Казахстан (Kazakhstan)',
                RU: 'Россия (Russia)',
                KG: 'Кыргызстан (Kyrgyzstan)',
                UZ: 'Узбекистан (Uzbekistan)',
                UA: 'Украина (Ukraine)',
                BY: 'Беларусь (Belarus)',
                AM: 'Армения (Armenia)',
                TJ: 'Таджикистан (Tadjikistan)',
                AZ: 'Азербайджан (Azerbaijan)',
                MD: 'Молдова (Moldova)',
              }"
              list-position="bottom left"
              color="primary"
              size="md"
              clearable="true"
            />
            <label class="checkbox">
              <input type="checkbox" name="" id="" v-model="confirm_checkbox">
              Я согласен на обработку данных
              <span class="checkmark"></span>
            </label>
            <button class="feedback__btn" type="submit">Отправить</button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { notify  } from '@kyvg/vue3-notification';

export default {
   data() {
      return {
         user_name: null,
         user_phone: null,
         userPhone: null,
         confirm_checkbox: false,
         errors: [],
      }
   },
   methods: {
      checkForm() { 
         if(this.user_name && this.userPhone && this.confirm_checkbox) {

         // ORIGINAL TOKEN AND CHAT_ID
         let token = '5654725825:AAGoNIE_7x0QCHoPlqZfgbmRosBQZ6hw2N8'
         let chat_id = '-1001630861682'

         // TEST TOKEN AND CHAT_ID
         // let token = '5819742667:AAHkrNEgCU6VLZ3v-XBfgRO7AJ5afTwhYXo'
         // let chat_id = '667611896'

         let appl_text = `Имя: ${this.user_name} %0AТелефон: ${this.userPhone}%0A(вопросы по платформе)`

         axios.post('https://api.telegram.org/bot' + token + '/sendMessage?chat_id=' + chat_id + '&parse_mode=&text=' + appl_text,
             {
               params: {
                  name: this.user_name,
                  phone: this.userPhone
               }
             })
            .then(()=>{
               this.user_name = null;
               this.userPhone = null;
               this.confirm_checkbox = false;
               // $('.main__modal.success').addClass('active');
               // setTimeout(() => {
               // $('.main__modal.success').removeClass('active')
               // }, 2500);
               $('.main__modal').removeClass('isApplicationForm');
              window.location.href = "/thanks"
            })
            .catch(() => {

            })
         }

         this.errors = [];
         if(this.user_name === null) {
         this.errors.push("Вы пропустили поле \"Ваше имя\"")
         }
         if (!this.userPhone) {
         this.errors.push("Вы пропустили поле \"Номер телефона\"")
         }
         if (this.confirm_checkbox === false) {
         this.errors.push('Вам необходимо принять условия соглашения')
         }
         if (this.errors.length >= 1) {
         notify({
            group: 'app',
            type: 'error',
            text: this.errors.join(', <br>'),
         });
         }
         else {
         notify({
            group: 'app',
            type: 'success',
            text: 'Ваша заявка успешно отправлена!',
         });
         }

      },
   },
}
</script>

<style>

 
</style>