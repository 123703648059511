import { createApp } from 'vue'
import Vue3Autocounter from 'vue3-autocounter';
import App from './App.vue'
import router from './router/router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import Notifications from '@kyvg/vue3-notification'
import MazBtn from 'maz-ui/components/MazBtn'
import MazInput from 'maz-ui/components/MazInput'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import { plugin, defaultConfig } from '@formkit/vue'
import { createI18n } from 'vue-i18n'
import ModalSuccess from '@/components/Modals/Success.vue'

const i18n = createI18n({})

createApp(App)
  .use(router)
  .component('vue3-autocounter', Vue3Autocounter)
  .component('MazBtn', MazBtn)
  .component('MazInput', MazInput)
  .component('MazPhoneNumberInput', MazPhoneNumberInput)
  .component('modal-success', ModalSuccess)
  .use(VueTelInput)
  .use(VueAxios, axios)
  .use(Notifications)
  .use(plugin, defaultConfig)
  .use(i18n)
  .mount('#app')
