<template>
  <section class="advantages" id="advantages">
    <div class="container">
      <div class="section__header">
          <h2 class="section__title"><span class="text-blue">Преимущества </span>Платформы </h2>
      </div>
    </div>
    <div class="advantages__sections">
      <div class="advantages__block" v-for="(advantage, i) of advantages" :key="i">
        <div class="container">
          <div class="section__body">
            <div class="advantages__image">
              <img :src="advantage.img" alt="">
            </div>
            <div class="advantages__content">
              <h3 class="advantages__content-title"><span class="text-blue">{{ i+1 }}. </span>{{ advantage.title }}</h3>
              <ul class="advantages__content-list">
                <li v-for="(advantageListItem, j) of advantage.list" :key="j">{{ advantageListItem }}</li>
              </ul>
              <div class="advantages__content-btns">
                <!-- <a href="/create-platform" class="btn-green">Попробовать бесплатно</a> -->
                <a href="https://wa.me/+77789920000" target="_blank" class="btn-green">Узнать подробнее</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
   export default {
      data() {
         return {
            advantages: [
               {
                  img: require('@/assets/images/img-advantages-block-1-1.webp'),
                  title: 'Создайте ваш онлайн курс в удобном формате',
                  list: [
                     'Видеуроки на собственной платформе',
                     'Уроки всегда будут на платформе, и ваш студент может просматривать их в любое удобное время и несколько раз',
                     'Раздаточные материалы для студентов: аудио, видео, тексты, PDF,WORD файлы, инфографика и многое другое.',
                     'Расписание курсов',
                     'Стоп-уроки после каждого урока',
                     'Автогенерация сертификатов после окончания курса',
                     'Аналитика курсов',
                  ]
               },
               {
                  img: require('@/assets/images/img-advantages-block-2.webp'),
                  title: 'Совершенствуйте свою платформу',
                  list: [
                     'Дополнительные модули платформы',
                     'ЕНТ/НИШ',
                     'Журналы',
                     'Новости',
                     'Тренинги',
                     'Промокоды',
                     'Бонусные системы',
                     'Рейтинг',
                     'Группы/потоки/периоды',
                     'Конференции',
                     'Расписание курсов',
                     'Блокировка подозрительных пользователей',
                  ]
               },
               {
                  img: require('@/assets/images/img-advantages-block-3-1.webp'),
                  title: 'Принимайте платежи онлайн',
                  list: [
                     'WhatsApp',
                     'PayBox',
                     'WalletOne',
                     'PayPost',
                     'Epay',
                     'Cloudpayments',
                     'Kaspi перевод',
                  ]
               },
               {
                  img: require('@/assets/images/img-advantages-block-4.webp'),
                  title: 'Интегрируйте с другими популярными сервисами',
                  list: [
                     'AMO CRM',
                     'WhatsApp',
                     'Google Analytics',
                     'Facebook Pixel',
                     'Yandex Metrika',
                     'Zoom Meetings',
                  ]
               },
               {
                  img: require('@/assets/images/img-advantages-block-5.webp'),
                  title: 'Разработка дизайна под ваши индивидуальные требования',
                  list: [
                     'По вашему желанию платформу можно упрастить или же сделать многофункциональной',
                     'Все блоки адаптируются под мобильные устройства автоматически',
                     'Возможность создавать дизайн под ваш бренд',
                  ]
               },
            ],
         }
      },
      methods: {

      },
   }
</script>

<style scoped>
.advantages__content-list li {
  background: url(../../../../assets/icons/ic-list-done.svg) no-repeat left center;
  padding: 8px 0px 8px 26px;
}
</style>