<template>
 
  <section class="about" id="about">
    <div class="container">
      
    </div>
  </section>
  
  <BackToTop />
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import BackToTop from '@/components/BackToTop.vue';

export default {
    components: { Header, Footer, BackToTop }
}
</script>

<style>

</style>