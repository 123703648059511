<template>
  <div class="wrapper">
<!--    <Snowflake />-->
    <Header />
    <router-view />
    <notifications group="app" class="my-notification" position="top right" />
    <Footer />

  </div>
</template>

<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Snowflake from "@/components/Snowflake.vue"

export default {
  name: 'App',
  data() {
    return {
    }
  },
  components: {
    Header, Footer, Snowflake
  },
  methods: {
  }
}


</script>


<style>
/* .wrapper {
  overflow: hidden;
} */
</style>
