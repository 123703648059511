<template>
   <section class="about-video" id="about-video">
      <div class="container">
         <div class="section__header">
         <h2 class="section__title">Узнайте о <span class="text-blue">EduCenter за 3 минуты</span></h2>
         </div>
         <div class="section__body" >
         <div class="about-video__frame">
            <img src="../../../../assets/images/img-about-video-1.jpg" alt="">
            <button class="about-video__play-btn" @click="openVideo('7o0gJxUO5SQ')">
               <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#007AFF"/>
               <path fill-rule="evenodd" clip-rule="evenodd" d="M38.5 30L26.5 39.5L26.5 20.5L38.5 30Z" fill="white"/>
               </svg>
            </button>
         </div>
         </div>
      </div>
      <div class="media-component size-small">
         <modal-video :channel="videoChannel" :videoId="videoId" v-model:isOpen="videoIsOpen" />
      </div>
   </section>

</template>

<script>
   import ModalVideo from '@/components/Modals/Video.vue'

   export default {
      data() {
         return {
            videoIsOpen: false,
            videoId: '',
         }
      },
      components: {
         ModalVideo,
      },
      methods: {
         openVideo(id) {
            this.videoIsOpen = !this.videoIsOpen
            this.videoId = id
         },
      },
      computed: {    
         videoChannel: function () {
            return 'youtube'
         },
      },
   }
</script>

<style>

</style>