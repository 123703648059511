<template>
	<div :class="open ? 'faq__body open' : 'faq__body'" @click="$emit('toggleOpen', faq.id)">
		<div class="question">{{ faq.id }}. {{ faq.question }}</div>
		<div class="answer">{{ faq.answer }}</div>
	</div>
</template>

<script>

export default {
	name: 'FAQ',
	props: ['faq', 'open', 'id', 'toggleOpen']
}
</script>

<style scoped>
.faq__body {
  display: block;
  width: 80%;
  margin: 10px auto;
  
	border: 1.5px solid #F3F3F3;
	border-radius: 4px;
  background-color: var(--white);
	cursor: pointer;
	user-select: none;
}
.faq__body .question {
  font-family: 'SF Pro Display';
  position: relative;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 135%;
	color: var(--black);
  padding: 20px 60px 20px 20px;
  transition: 0.3s;
}
.faq__body .question::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%) rotate(0deg);
  width: 30px;
  height: 30px;
	
  background-image: url('../assets/icons/ic-accordion-plus.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  
  transition: 0.3s;
}
.faq__body.open .question {
	color: var(--main-color);
}
.faq__body.open .question::after {
  transform: translateY(-50%) rotate(45deg);
}
.faq__body .answer {
  color: #555555;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 135%;
  opacity: 0;
  max-height: 0px;
  overflow-y: hidden;
  transition: 0.3s ;
}
.faq__body.open .answer {
  opacity: 1;
  max-height: 1000px;
  padding: 0 20px 20px;
}

@media (max-width: 1400px) {

}
@media (max-width: 1200px) {

}
@media (max-width: 991px) {
  .faq__body {
    width: 100%;
  }
}
@media (max-width: 768px) {

}
@media (max-width: 500px) {
  .faq__body .question {
    font-size: 16px;
  }
  .faq__body .answer {
    font-size: 12px;
  }
}
@media (max-width: 414px) {

}
@media (max-width: 390px) {

}
@media (max-width: 375px) {

}
@media (max-width: 360px) {

}
@media (max-width: 320px) {

}
</style>
