<template>
   <section class="reviews" id="reviews">
      <div class="container">
         <div class="section__header">
         <h2 class="section__title">отзывы</h2>
         <p class="section__subtitle"><span class="text-gray">Посмотрите, что о нас говорят наши клиенты</span></p>
         </div>
         <div class="section__body">
         <div class="reviews__tabs">
            <div class="reviews__tab-items" @click="reviewContent('video')" :class="{'active': isVideoTab}">Видео отзывы</div>
            <div class="reviews__tab-items" @click="reviewContent('photo')" :class="{'active': isPhotoTab}">Фото отзывы</div>
            <div class="reviews__tab-items" @click="reviewContent('certificate')" :class="{'active': isPhotoThanks}">Благодарственные письма</div>
         </div>
         <div class="reviews__tab-video" v-if="isVideoTab">
            <div class="reviews__slider" >
               <swiper class="reviewsSwiper"
               :slides-per-view="4"
               :space-between="30"
               @swiper="onSwiper"
               @slideChange="onSlideChange"
               
               :navigation="{
                  prevEl: prev2,
                  nextEl: next2,
               }"
               :modules="modules"
               :pagination="{
                  type: 'progressbar',
                  el: pagination2,
               }"
               :breakpoints="swiperOptions.breakpoints"  
               >
                  <swiper-slide v-for="(reviewVideo, i) of reviewVideos" :key="i">
                     <div class="reviews__video-img">
                        <img :src="reviewVideo.img" alt="">
                     </div>
                     <div class="reviews__img-text">
                        <h3 class="">{{ reviewVideo.name }}</h3>
                        <p class="">{{ reviewVideo.post }}</p>
                     </div>
                     <button class="reviews__video-btn" @click="openVideo(reviewVideo.video)">
                     <svg width="37" height="42" viewBox="0 0 37 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M36.5 20.134C37.1667 20.5189 37.1667 21.4811 36.5 21.866L2 41.7846C1.33333 42.1695 0.499998 41.6884 0.499998 40.9186L0.5 1.08142C0.5 0.311615 1.33333 -0.169511 2 0.215389L36.5 20.134Z" fill="white"/>
                     </svg>
                     </button>
                  
                  </swiper-slide>
                     
                  <div class="swiper1-control">
                     <div class="swiper1-pagination">
                        <div ref="pagination2" class="swiper-pagination2">
                        </div>
                     </div>
                     <div class="swiper1-navigation">
                        <div class="swiper1-btns">
                           <div ref="prev2" class="swiper-button-prev2">
                              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path fill-rule="evenodd" clip-rule="evenodd" d="M15 3.75V3.75C21.2138 3.75 26.25 8.78625 26.25 15V15C26.25 21.2138 21.2138 26.25 15 26.25V26.25C8.78625 26.25 3.75 21.2138 3.75 15V15C3.75 8.78625 8.78625 3.75 15 3.75Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 <path d="M10 15H20" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 <path d="M13.75 18.75L10 15L13.75 11.25" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                           </div>
                           <div ref="next2" class="swiper-button-next2">
                              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path fill-rule="evenodd" clip-rule="evenodd" d="M15 26.25V26.25C8.78625 26.25 3.75 21.2138 3.75 15V15C3.75 8.78625 8.78625 3.75 15 3.75V3.75C21.2138 3.75 26.25 8.78625 26.25 15V15C26.25 21.2138 21.2138 26.25 15 26.25Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 <path d="M20 15H10" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                 <path d="M16.25 11.25L20 15L16.25 18.75" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                           </div>
                        </div>
                     </div>
                  </div>  
               </swiper>          
            </div>
         </div>
         <div class="reviews__tab-photo" v-else-if="isPhotoTab">
            <div class="reviews__slider">
               <swiper class="reviewsSwiper"
               :slides-per-view="4"
               :space-between="30"
               @swiper="onSwiper"
               @slideChange="onSlideChange"
               :navigation="{
                  prevEl: prev3,
                  nextEl: next3,
               }"
               :modules="modules"
               :pagination="{
                  type: 'progressbar',
                  el: pagination3,
               }"
               :breakpoints="swiperOptions.breakpoints"
               >
                  <swiper-slide v-for="(reviewImage, i) of reviewImages" :key="i">
                     <div class="reviews__photo-img">
                     <expandable-image
                        class="img-reviewImage"
                        :src="reviewImage.img"
                     />
                     </div>
                  </swiper-slide>
                  
                  <div class="swiper1-control">
                     <div class="swiper1-pagination">
                     <div ref="pagination3" class="swiper-pagination3">
                     </div>
                     </div>
                     <div class="swiper1-navigation">
                     <div class="swiper1-btns">
                        <div ref="prev3" class="swiper-button-prev3">
                           <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd" clip-rule="evenodd" d="M15 3.75V3.75C21.2138 3.75 26.25 8.78625 26.25 15V15C26.25 21.2138 21.2138 26.25 15 26.25V26.25C8.78625 26.25 3.75 21.2138 3.75 15V15C3.75 8.78625 8.78625 3.75 15 3.75Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M10 15H20" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M13.75 18.75L10 15L13.75 11.25" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           </svg>
                        </div>
                        <div ref="next3" class="swiper-button-next3">
                           <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd" clip-rule="evenodd" d="M15 26.25V26.25C8.78625 26.25 3.75 21.2138 3.75 15V15C3.75 8.78625 8.78625 3.75 15 3.75V3.75C21.2138 3.75 26.25 8.78625 26.25 15V15C26.25 21.2138 21.2138 26.25 15 26.25Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M20 15H10" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M16.25 11.25L20 15L16.25 18.75" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           </svg>
                        </div>
                     </div>
                     </div>
                  </div>
               </swiper>
            </div>
         </div>
         <div class="reviews__tab-photo" v-else>
            <div class="reviews__slider">
               <swiper class="reviewsThanksSwiper"
               :slides-per-view="2"
               :space-between="30"
               @swiper="onSwiper"
               @slideChange="onSlideChange"
               :navigation="{
                  prevEl: prev4,
                  nextEl: next4,
               }"
               :modules="modules"
               :pagination="{
                  type: 'progressbar',
                  el: pagination4,
               }"
               :breakpoints="swiperOptions2.breakpoints"
               >
                  <swiper-slide v-for="(reviewThanksImage, i) of reviewThanksImages" :key="i">
                     <div class="reviews__photo-img">
                     <expandable-image
                        class="img-reviewImage"
                        :src="reviewThanksImage.img"
                     />
                     </div>
                  </swiper-slide>
                  
                  <div class="swiper1-control">
                     <div class="swiper1-pagination">
                     <div ref="pagination4" class="swiper-pagination4">
                     </div>
                     </div>
                     <div class="swiper1-navigation">
                     <div class="swiper1-btns">
                        <div ref="prev4" class="swiper-button-prev4">
                           <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd" clip-rule="evenodd" d="M15 3.75V3.75C21.2138 3.75 26.25 8.78625 26.25 15V15C26.25 21.2138 21.2138 26.25 15 26.25V26.25C8.78625 26.25 3.75 21.2138 3.75 15V15C3.75 8.78625 8.78625 3.75 15 3.75Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M10 15H20" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M13.75 18.75L10 15L13.75 11.25" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           </svg>
                        </div>
                        <div ref="next4" class="swiper-button-next4">
                           <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path fill-rule="evenodd" clip-rule="evenodd" d="M15 26.25V26.25C8.78625 26.25 3.75 21.2138 3.75 15V15C3.75 8.78625 8.78625 3.75 15 3.75V3.75C21.2138 3.75 26.25 8.78625 26.25 15V15C26.25 21.2138 21.2138 26.25 15 26.25Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M20 15H10" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M16.25 11.25L20 15L16.25 18.75" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           </svg>
                        </div>
                     </div>
                     </div>
                  </div>
               </swiper>
            </div>
         </div>
         </div>
      </div>
      <div class="media-component size-small">
         <modal-video :channel="videoChannel" :videoId="videoId" v-model:isOpen="videoIsOpen" />
      </div>
   </section>
</template>

<style>
   .reviewsSwiper  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
      background: #222!important;
      height: 2px;
   }
   .reviewsThanksSwiper  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
      background: #222!important;
      height: 2px;
   }
   .reviewsSwiper .swiper-wrapper {
      justify-content: inherit;
   }
   @media (max-width: 991px) {
      .reviewsSwiper .swiper-wrapper {
         justify-content:inherit;
      }
   }
</style>

<script>
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import ExpandableImage from '@/components//ExpandableImage'
import ModalVideo from '@/components/Modals/Video.vue'

export default {
   data() {
      return {
         videoId: '',
         videoIsOpen: false,
         isVideoTab: true,
         isPhotoTab: false,
         isPhotoThanks: false,

         reviewVideos: [
            {
               name: 'Айбек Қуатбай',
               post: 'Основатель платформы Daryn Online',
               img: require('../../../../assets/images/img-reviews-item-0.jpg'),
               video: 'GX2zrm9yDf4',
            },
            {
               name: 'Аблай Юсупов',
               post: 'Основатель Upgrade Business | Маркетолог',
               img: require('../../../../assets/images/img-reviews-item-2.jpg'),
               video: 'dAl12EQRcZg',
            },
            {
               name: 'Жексен Васильевич',
               post: 'Бизнес-тренер, руководитель jvteam',
               img: require('../../../../assets/images/img-reviews-item-3.jpg'),
               video: 'iq0ufjIDiqY',
            },
            {
               name: 'Рамин Абаков',
               post: 'Топ менеджер, партнер JV Online',
               img: require('../../../../assets/images/img-reviews-item-5.jpg'),
               video: '81Y5UBgBxVs',
            },
            {
               name: 'Әріпбай Еркебұлан Дулатұлы',
               post: 'Қор Нарығында инвестор, трейдер, қаржы докторант',
               img: require('../../../../assets/images/img-reviews-item-4-1.jpg'),
               video: 'y-1hMNX6Aqk',
            },
         ],
         reviewImages: [
            {
               img: require('../../../../assets/images/review/IMG_7097.png'),
            },
            {
               img: require('../../../../assets/images/review/IMG_7131.png'),
            },
            {
               img: require('../../../../assets/images/review/IMG_7147.png'),
            },
         ],
         reviewThanksImages: [
            {
               img: require('../../../../assets/images/review/img-thanks-letter-1.jpg'),
            },
            {
               img: require('../../../../assets/images/review/img-thanks-letter-2.jpg'),
            },
         ],
         swiperOptions: {
            breakpoints: {       
               320: {       
                  slidesPerView: 1,
                  spaceBetween: 10     
               },          
               768: {       
                  slidesPerView: 2,       
                  spaceBetween: 50     
               },   
            
               991: {       
                  slidesPerView: 4,       
                  spaceBetween: 30     
               } 
            }   
            },
         swiperOptions2: {
            breakpoints: {       
               320: {       
                  slidesPerView: 1,
                  spaceBetween: 10     
               },          
               991: {       
                  slidesPerView: 2,       
                  spaceBetween: 30     
               },   
            }   
         },
      }
   },
   methods: {
      reviewContent(review_type) {
         if (review_type == 'video') {
            this.isVideoTab = true;
            this.isPhotoTab = false;
            this.isPhotoThanks = false;
         }
         else if (review_type == 'photo') {
            this.isPhotoTab = true;
            this.isVideoTab = false;
            this.isPhotoThanks = false;
         }
         else if (review_type == 'certificate'){
            this.isPhotoThanks = true;
            this.isPhotoTab = false;
            this.isVideoTab = false;
         }
      },
      openVideo(id) {
         this.videoIsOpen = !this.videoIsOpen
         this.videoId = id
      },
   },
   components: {
            Swiper,
            SwiperSlide,
            ExpandableImage,
            ModalVideo
         },
   setup() {
      const onSwiper = (swiper) => {
      // console.log(swiper);
      };
      const onSlideChange = () => {
      // console.log('slide change');
      };
      const prev1 = ref(null);
      const next1 = ref(null);
      const pagination1 = ref(null);
      const prev2 = ref(null);
      const next2 = ref(null);
      const pagination2 = ref(null);
      const prev3 = ref(null);
      const next3 = ref(null);
      const pagination3 = ref(null);
      const prev4 = ref(null);
      const next4 = ref(null);
      const pagination4 = ref(null);
      return {
         onSwiper,
         onSlideChange,
         modules: [Navigation, Pagination, Scrollbar, A11y],
         prev1,
         next1,
         pagination1,
         prev2,
         next2,
         pagination2,
         prev3,
         next3,
         pagination3,
         prev4,
         next4,
         pagination4,
      };
   },
   computed: {
      videoChannel: function () {
         return 'youtube'
      },
   }
}
</script>

<style>


</style>