<template>
  <section class="lease-expired" id="lease-expired">
    <div class="container">
      <div class="lease-expired__text">
        <h1 class="lease-expired__title">Срок аренды платформы истек :( </h1>
        <h3 class="lease-expired__subtitle">Для продлевания ареды пожалуйста оплатите,
для вопросов напишите менеджеру </h3>
        <div class="lease-expired__btns">
          <button class="lease-expired__btn btn-blue">Перейти к оплате</button>
          <button class="lease-expired__btn btn-aqua">Связаться с менеджером</button>
        </div>
      </div>
      <div class="lease-expired__img">
        <img src="../assets/illustrations/ill-main-1.png" alt="">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    
  },
}
</script>

<style scoped>
  .lease-expired {

  }

  .lease-expired .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }

  .lease-expired__text,
  .lease-expired__img {
    width: 50%;
  }

  .lease-expired__img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .lease-expired__title {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 135%;
    color: var(--black);
    margin: 0 0 20px 0;
  }

  .lease-expired__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #363636;
    margin: 0 0 30px 0;
  }

  .lease-expired__btns {
    display: flex;
    flex-direction: column;
    width: 360px;
    gap: 24px;
  }

  .lease-expired__btn.btn-blue,
  .lease-expired__btn.btn-aqua {
    border-radius: 30px;
  }

  @media (max-width: 1400px) {

  }
  @media (max-width: 1200px) {

  }
  @media (max-width: 991px) {
    .lease-expired .container {
      flex-direction: column;
    } 
    .lease-expired__text,
    .lease-expired__img {
      width: 100%;
    }
    .lease-expired__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  @media (max-width: 768px) {

  }
  @media (max-width: 600px) {
  
  }
  @media (max-width: 500px) {
    .lease-expired__btns {
      width: 100%;
    }
  }
  @media (max-width: 414px) {

  }
  @media (max-width: 390px) {

  }
  @media (max-width: 375px) {

  }
  @media (max-width: 360px) {

  }
  @media (max-width: 320px) {

  }
</style>