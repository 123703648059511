<template>
    <section class="blogInner-page" id="blogInner-page">
      <div class="container">
        <div class="page__main-banner --blogInner">
          <img src="../assets/images/img-bloginner-big1.png" alt="">
        </div>
        <div class="page__header">
          <h1 class="page__title">Как делать деньги в Тиктоке</h1>
          <p class="blogInner-page__category">Маркетинг</p>
        </div>
        <div class="page__body">
          
          <div class="blogInner-page__body">
            <div class="blogInner-page__body-text">
              <p>Тикток стал самым загружаемым неигровым приложением в 2020 году. Скорее всего, главную роль в этом сыграл локдаун. Пока клубы и торговые центры были закрыты, Gen Z залипали на домашнее окрашивание старых футболок в тазике, платья из подушек и цитаты волка.</p>
              <p>То, что происходит в тиктоке, редко только там и остается — уже сейчас в том же инстаграме больше 70 млн публикаций с тегом #tiktok. Любой ролик из тиктока может стать глобальным трендом сразу в нескольких соцсетях за пару часов.</p>
              <p>Разбираемся, кто создает контент в тиктоке, как его продвигают и что сделать бренду, чтобы влиться в этот поток.</p>
            </div>
            <div class="blogInner-page__body-img">
              <img src="../assets/images/img-bloginner-small1.png" alt="">
            </div>
            <div class="blogInner-page__body-text">
              <h3>Как делать деньги в Тиктоке 2</h3>
              <p>Тикток стал самым загружаемым неигровым приложением в 2020 году. Скорее всего, главную роль в этом сыграл локдаун. Пока клубы и торговые центры были закрыты, Gen Z залипали на домашнее окрашивание старых футболок в тазике, платья из подушек и цитаты волка.</p>
              <p>То, что происходит в тиктоке, редко только там и остается — уже сейчас в том же инстаграме больше 70 млн публикаций с тегом #tiktok. Любой ролик из тиктока может стать глобальным трендом сразу в нескольких соцсетях за пару часов.</p>
              <p>Разбираемся, кто создает контент в тиктоке, как его продвигают и что сделать бренду, чтобы влиться в этот поток.</p>
            </div>
            <div class="blogInner-page__body-share">
              <span>Поделиться:</span>
              <div class="blogInner-page__body-share-link">
                <a href="#"><img src="../assets/icons/ic-social-fb-gray.svg" alt=""></a>
                <a href="#"><img src="../assets/icons/ic-social-vk-gray.svg" alt=""></a>
                <a href="#"><img src="../assets/icons/ic-social-wa-gray.svg" alt=""></a>
                <a href="#"><img src="../assets/icons/ic-social-tg-gray.svg" alt=""></a>
                <a href="#"><img src="../assets/icons/ic-social-tw-gray.svg" alt=""></a>
              </div>
            </div>
          </div>

          <div class="blog-page__body">
            <div class="blog-page__body-header">
              <h2>Читайте также:</h2>
            </div>
            <div class="blog-page__card">
              <div class="blog-page__card-items">
                <div class="blog-page__card-img">
                    <img src="../assets/images/img-blog-item-1.jpg" alt="">
                </div>
                <div class="blog-page__card-text">
                    <span class="blog-page__card-category">Маркетинг</span>
                    <h3 class="blog-page__card-title">Как делать деньги в Тиктоке</h3>
                    <p class="blog-page__card-subtitle">Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...</p>
                    <router-link to="/blog/1" class="blog-page__card-link">
                      <span>Читать полностью</span>
                      <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.3536 4.85355C16.5488 4.65829 16.5488 4.34171 16.3536 4.14645L13.1716 0.964467C12.9763 0.769205 12.6597 0.769205 12.4645 0.964467C12.2692 1.15973 12.2692 1.47631 12.4645 1.67157L15.2929 4.5L12.4645 7.32843C12.2692 7.52369 12.2692 7.84027 12.4645 8.03553C12.6597 8.2308 12.9763 8.2308 13.1716 8.03554L16.3536 4.85355ZM-4.37114e-08 5L16 5L16 4L4.37114e-08 4L-4.37114e-08 5Z" fill="#007AFF"/>
                      </svg>
                    </router-link>
                </div>
              </div>
              <div class="blog-page__card-items">
                <div class="blog-page__card-img">
                    <img src="../assets/images/img-blog-item-1.jpg" alt="">
                </div>
                <div class="blog-page__card-text">
                    <span class="blog-page__card-category">Маркетинг</span>
                    <h3 class="blog-page__card-title">Как делать деньги в Тиктоке</h3>
                    <p class="blog-page__card-subtitle">Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...</p>
                    <router-link to="/blog/1" class="blog-page__card-link">
                      <span>Читать полностью</span>
                      <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.3536 4.85355C16.5488 4.65829 16.5488 4.34171 16.3536 4.14645L13.1716 0.964467C12.9763 0.769205 12.6597 0.769205 12.4645 0.964467C12.2692 1.15973 12.2692 1.47631 12.4645 1.67157L15.2929 4.5L12.4645 7.32843C12.2692 7.52369 12.2692 7.84027 12.4645 8.03553C12.6597 8.2308 12.9763 8.2308 13.1716 8.03554L16.3536 4.85355ZM-4.37114e-08 5L16 5L16 4L4.37114e-08 4L-4.37114e-08 5Z" fill="#007AFF"/>
                      </svg>
                    </router-link>
                </div>
              </div>
              <div class="blog-page__card-items">
                <div class="blog-page__card-img">
                    <img src="../assets/images/img-blog-item-1.jpg" alt="">
                </div>
                <div class="blog-page__card-text">
                    <span class="blog-page__card-category">Маркетинг</span>
                    <h3 class="blog-page__card-title">Как делать деньги в Тиктоке</h3>
                    <p class="blog-page__card-subtitle">Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...</p>
                    <router-link to="/blog/1" class="blog-page__card-link">
                      <span>Читать полностью</span>
                      <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.3536 4.85355C16.5488 4.65829 16.5488 4.34171 16.3536 4.14645L13.1716 0.964467C12.9763 0.769205 12.6597 0.769205 12.4645 0.964467C12.2692 1.15973 12.2692 1.47631 12.4645 1.67157L15.2929 4.5L12.4645 7.32843C12.2692 7.52369 12.2692 7.84027 12.4645 8.03553C12.6597 8.2308 12.9763 8.2308 13.1716 8.03554L16.3536 4.85355ZM-4.37114e-08 5L16 5L16 4L4.37114e-08 4L-4.37114e-08 5Z" fill="#007AFF"/>
                      </svg>
                    </router-link>
                </div>
              </div>
              <div class="blog-page__card-items">
                <div class="blog-page__card-img">
                    <img src="../assets/images/img-blog-item-1.jpg" alt="">
                </div>
                <div class="blog-page__card-text">
                    <span class="blog-page__card-category">Маркетинг</span>
                    <h3 class="blog-page__card-title">Как делать деньги в Тиктоке</h3>
                    <p class="blog-page__card-subtitle">Чтобы делать деньги в тиктоке нужен смартфон и фонарик который светит лицо. И еще контент план...</p>
                    <router-link to="/blog/1" class="blog-page__card-link">
                      <span>Читать полностью</span>
                      <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.3536 4.85355C16.5488 4.65829 16.5488 4.34171 16.3536 4.14645L13.1716 0.964467C12.9763 0.769205 12.6597 0.769205 12.4645 0.964467C12.2692 1.15973 12.2692 1.47631 12.4645 1.67157L15.2929 4.5L12.4645 7.32843C12.2692 7.52369 12.2692 7.84027 12.4645 8.03553C12.6597 8.2308 12.9763 8.2308 13.1716 8.03554L16.3536 4.85355ZM-4.37114e-08 5L16 5L16 4L4.37114e-08 4L-4.37114e-08 5Z" fill="#007AFF"/>
                      </svg>
                    </router-link>
                </div>
              </div>
                
                
              </div>
            </div>
        </div>
      </div>
    </section>

    <BackToTop />
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import BackToTop from '@/components/BackToTop.vue';

export default {
    components: { Header, Footer, BackToTop }
}
</script>

<style scoped>
  .blog-page {

  }

  .blog-page .container {

  }

  .blog-page__search {
    width: 500px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .blog-page__filtering {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin: 30px;
  }

  .blog-page__filtering-btns {
    padding: 16px;
    background: #F3F3F3;
    border-radius: 100px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center; 
    justify-content: center;
    user-select: none;
    cursor: pointer; 
    color: #1B1D1A;
  }

  .blog-page__filtering-btns.isBlogFilter {
    background: var(--main-color);
    color: var(--white);
  }

  .blog-page__card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
  }

  .blog-page__card-items {
    background: var(--white);
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    overflow: hidden;
  }

  .blog-page__card-img {
    width: 100%;
    height: 180px;
  }

  .blog-page__card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog-page__card-text {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .blog-page__card-category {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--main-color);
    margin-bottom: 10px;
  }

  .blog-page__card-title {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #1B1D1A;
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .blog-page__card-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #ADADAD;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .blog-page__card-link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: var(--main-color);
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 12px;
  }

  .blog-page__content-btn {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }

  .blog-page__content-btn button {
    width: 300px;
  }

  .blogInner-page {
    display: flex;
  }

  .blogInner-page .container {

  }

  .page__main-banner.--blogInner {
    width: 100%;
    height: 500px;
    border-radius: 16px;
    overflow: hidden;
  }

  .page__main-banner.--blogInner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blogInner-page .page__header,
  .blogInner-page .blogInner-page__body {
    width: 60%;
    margin: 0 auto;
  }

  .blogInner-page .page__title {
    margin: 30px 0;
  }

  .blogInner-page__category {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: var(--main-color);
    margin-bottom: 30px;
  }

  .blogInner-page__body-text h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    color: var(--black);
    margin: 0 0 15px 0;
  }
  .blogInner-page__body-text p {
    font-style: normal;
    font-weight: 457;
    font-size: 18px;
    line-height: 135%;
    color: #636363;
    margin: 0 0 15px 0;
  }
  
  .blogInner-page__body-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    margin: 20px 0 30px 0;
  }

  .blogInner-page__body-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blogInner-page__body-share {
    display: flex;
    align-items: center;
    gap: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: #9C9C9C;
  }

  .blogInner-page__body-share-link {
    display: flex;
    gap: 10px; 
  }

  .blogInner-page__body-share-link a {
    display: flex;
  }

  .blog-page__body {
    margin: 30px 0;
  }

  .blog-page__body-header h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 30px;
  }

  @media (max-width: 1400px) {

  }
  @media (max-width: 1200px) {
    .blog-page__card {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .page__main-banner.--blogInner {
      height: 400px;
    }
  }
  @media (max-width: 991px) {
    .blog-page__card {
      grid-template-columns: 1fr 1fr;
    }
    .page__main-banner.--blogInner {
      height: 300px;
    }
  }
  @media (max-width: 768px) {
    .blog-page__filtering  {
      justify-content: flex-start;
      gap: 8px;
    }

    .blog-page__filtering-btns {
      padding: 10px;
      font-size: 13px;
    }

    .blog-page__search {
      width: 100%;
    }

    .blogInner-page .page__header,
    .blogInner-page .blogInner-page__body {
      width: 100%;
    }

    .page__main-banner.--blogInner {
      height: 230px;
    } 

  }
  @media (max-width: 500px) {
    .blog-page__card {
      grid-template-columns: 1fr;
    } 
    .blogInner-page .page__title {
      font-size: 22px;
    }
    .blogInner-page__category {
      font-size: 14px;
    }
    .blogInner-page__body-text h3 {
      font-size: 20px
    }
    .blogInner-page__body-text p {
      font-size: 14px;
    }
  }
  @media (max-width: 414px) {
  
  }
  @media (max-width: 390px) {

  }
  @media (max-width: 375px) {

  }
  @media (max-width: 360px) {
    .blogInner-page__body-share {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @media (max-width: 320px) {

  }
</style>