<template>
  <section class="faq">
    <div class="container">
      <div class="section__header">
        <h2 class="section__title">Топ-10 <span class="text-blue">вопросов</span></h2>
      </div>
      <div class="section__body">
        <div class="faqs">
          <FaqBody 
            v-for="faq in allFaqs" 
            :faq="faq" 
            :index="faq.id" 
            :key="faq.id"
            :open="faq.open"
            @toggleOpen="toggleOpen"
          />
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import FaqBody from '@/components/FaqBody.vue';

export default {
   data() {
      return {
         props: ['faq', 'id', 'open', 'toggleOpen'],
         allFaqs: [
            {
               id: 1,
               question: "Где и как размещать видео?",
               answer: "Все видеоуроки загружаются сперва на видеохостинги YouTube или Vimeo, затем на платформу.",
               open: false
            },
            {
               id: 2,
               question: "Вебинары можно проводить?",
               answer: "Можно проводить трансляции с помощью Zoom/Google Classroom",
               open: false
            },
            {
               id: 3,
               question: "После приобретения платформы, можно ли дальше модернизировать?",
               answer: "Да, можно. Есть возможность модернизировать под ваши требования. ",
               open: false
            },
            {
               id: 4,
               question: "Смогу я сам заливать курс? Легко в использовании?",
               answer: "Да, платформа удобна в использовании. Так же есть видеоинструкции для загрузки курсов.",
               open: false
            },
            {
               id: 5,
               question: "Если купить платформу в аренду, туда входит мобильное приложение?",
               answer: "Мобильное приложение идет в подарок к тарифам «Покупка» и «Enterprise»",
               open: false
            },
            {
               id: 6,
               question: "Сколько стоит тариф Enterprise?",
               answer: "Стоимость тарифа Enterprise оценивается от сложности функционала. ",
               open: false
            },
            {
               id: 7,
               question: "На Платформе есть запрет на запись экрана?",
               answer: "Видеоплеер курсов полностью защищен. Мобильное приложение платформы защищена от записи экранов и скриншота.",
               open: false
            },
            {
               id: 8,
               question: "Есть ли автоплатежи на платформе?",
               answer: "Можно производить автоплатежи с помощью PayBox, WalletOne, PayPost, Epay, Cloudpayments, Robokassa, Kaspi.",
               open: false
            },
            {
               id: 9,
               question: "Могут ли обучаться пользователи с СНГ?",
               answer: "Да, со всех стран СНГ.",
               open: false
            },
         ],
      }
   },
   components: { FaqBody },
   methods: {
      toggleOpen(index) {
        this.allFaqs = this.allFaqs.map(faq => {
          if (index == faq.id  ) {
            faq.open = !faq.open;
          } else {
            faq.open = false;
          }
          return faq;
        });
      },
   },
}
</script>

<style>

</style>