<template>
  <section class="stat" id="stat">
    <div class="container">
      <div class="section__header">
        <h2 class="section__title">мы в цифрах</h2>
      </div>
      <div class="section__body" >
        <div class="stat__items" v-for="stat in stats" :key="stat.value">
          <vue3-autocounter ref='counter' class="stat__count" :startAmount='0' :endAmount='stat.value' :duration='1' prefix='' :suffix='stat.suffix' separator='' decimalSeparator='' :decimals='0' :autoinit='true' />
          <p class="stat__description" v-html="stat.description"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        stats: [
          { value: 125000, description: 'пользователей на платформе', suffix: '+' },
          { value: 450, description: 'разработанных платформ', suffix: '+'  },
          { value: 24, description: 'техническая поддержка', suffix: '/7'  },
          { value: 3, description: 'года автоматизируем <br>онлайн-школы', suffix: ''  },
        ],
      }
    }
  }
</script>

<style>

 
</style>