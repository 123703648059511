<template>
  <section class="works" id="works">
    <div class="container">
      <div class="section__header">
        <h2 class="section__title">наши <span class="text-blue">работы</span></h2>
      </div>
      <div class="section__body">
        <div class="works__slider">
          <swiper class="worksSwiper"
            :slides-per-view="1"
            :space-between="50"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :navigation="{
              prevEl: prev1,
              nextEl: next1,
            }"
            :autoplay="{
              delay: 7500,
            }"
            :modules="modules"
            :pagination="{
              type: 'progressbar',
              el: pagination1,
            }"
          >
              <swiper-slide v-for="(workImage, i) of workImages" :key="i">
                <img :src="workImage.img" alt="">
              </swiper-slide>
              <div class="swiper1-control">
                <div class="swiper1-pagination">
                  <div ref="pagination1" class="swiper-pagination1">
                  </div>
                </div>
                <div class="swiper1-navigation">
                  <div class="swiper1-btns">
                    <div ref="prev1" class="swiper-button-prev1">
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 3.75V3.75C21.2138 3.75 26.25 8.78625 26.25 15V15C26.25 21.2138 21.2138 26.25 15 26.25V26.25C8.78625 26.25 3.75 21.2138 3.75 15V15C3.75 8.78625 8.78625 3.75 15 3.75Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 15H20" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.75 18.75L10 15L13.75 11.25" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div ref="next1" class="swiper-button-next1">
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 26.25V26.25C8.78625 26.25 3.75 21.2138 3.75 15V15C3.75 8.78625 8.78625 3.75 15 3.75V3.75C21.2138 3.75 26.25 8.78625 26.25 15V15C26.25 21.2138 21.2138 26.25 15 26.25Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M20 15H10" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.25 11.25L20 15L16.25 18.75" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>   
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
   .worksSwiper .swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
      background: #222!important;
      height: 2px;
   }

</style>

<script>
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue'
import ModalVideo from '@/components/Modals/Video.vue'
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import "swiper/css/autoplay";

export default {
      data() {
         return {
            workImages: [
               {
                 img: require('../../../../assets/images/img-work-item-6.jpg'),
               },
               {
                 img: require('../../../../assets/images/img-work-item-7.jpg'),
               },
               {
                  img: require('../../../../assets/images/img-work-item-1.jpg'),
               },
               {
                  img: require('../../../../assets/images/img-work-item-2.jpg'),
               },
               {
                  img: require('../../../../assets/images/img-work-item-3.jpg'),
               },
               {
                  img: require('../../../../assets/images/img-work-item-4.jpg'),
               },
               {
                  img: require('../../../../assets/images/img-work-item-5.png'),
               },
            ],
         }
      },
      components: {
         Swiper,
         SwiperSlide,
         ModalVideo
      },
      setup() {
         const onSwiper = (swiper) => {
            // console.log(swiper);
            };
            const onSlideChange = () => {
            // console.log('slide change');
            };
            const prev1 = ref(null);
            const next1 = ref(null);
            const pagination1 = ref(null);
            const prev2 = ref(null);
            const next2 = ref(null);
            const pagination2 = ref(null);
            const prev3 = ref(null);
            const next3 = ref(null);
            const pagination3 = ref(null);
            const prev4 = ref(null);
            const next4 = ref(null);
            const pagination4 = ref(null);
            return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
            prev1,
            next1,
            pagination1,
            prev2,
            next2,
            pagination2,
            prev3,
            next3,
            pagination3,
            prev4,
            next4,
            pagination4,
            };
      },
      methods: {
         
         
      },
      computed: {    
         videoChannel: function () {
            return 'youtube'
         },
      },
   }
</script>

<style>

</style>