<template>
  <section class="mobileApp" id="mobileApp">
    <div class="container">
      <div class="mobileApp__body">
        <h2 class="mobileApp__body-title">Мобильное приложение в подарок</h2>
        <p class="mobileApp__body-subtitle">При покупке платформы <br>
 мобильное приложение совершенно бесплатно</p>
        <a :href="deviceLink" class="mobileApp__btn" @click="deviceDetection()" target="_blank">Получить приложение</a>
      </div>
    </div>
    <img src="../../../../assets/illustrations/ill-mobileApp-1.png" alt="" class="mobileApp__img-elem1" style="">
    <img src="../../../../assets/illustrations/ill-mobileApp-2.png" alt="" class="mobileApp__img-elem2" style="">
    <img src="../../../../assets/illustrations/ill-mobileApp-3.png" alt="" class="mobileApp__img-gif anim-items">
    <img src="../../../../assets/images/img-mobile-app-1.webp" alt="" class="mobileApp__img-phone anim-items" >
  </section>
</template>

<script>
   export default {
      data() {
         return {
            deviceLink: '',
         }
      },
      methods: {
         deviceDetection() {
            if(navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false){
            this.deviceLink = 'https://apps.apple.com/kz/app/educenter/id1543607494';
            }

            var ua = navigator.userAgent.toLowerCase();
            if( ua.indexOf("android") > -1 ){
            this.deviceLink = 'https://play.google.com/store/apps/details?id=com.buginsoft.educenter';
            } 
         },
      },
      mounted() {
         const animItems = document.querySelectorAll('.anim-items');
         if (animItems.length > 0) {
            window.addEventListener('scroll', animOnScroll);
            function animOnScroll(params) {
                  for (let index = 0; index < animItems.length; index++) {
                     const animItem = animItems[index];
                     const animItemHeight = animItem.offsetHeight;
                     const animItemOffset = offset(animItem).top;
                     const animStart = 4;

                     let animItemPoint = window.innerHeight - animItemHeight / animStart;
                     if (animItemHeight > window.innerHeight) {
                        animItemPoint = window.innerHeight - window.innerHeight / animStart;
                     }

                     if ((pageYOffset > animItemOffset - animItemPoint) && pageYOffset < (animItemOffset + animItemHeight)) {
                        animItem.classList.add('__active');
                     } 
                     else {
                        animItem.classList.remove('__active');
                     }
                  }
            }

            function offset(el) {
                  const rect = el.getBoundingClientRect(),
                     scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                     scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                  return { top: rect.top + scrollTop, left: rect.left + scrollLeft}
            }
            animOnScroll();
         }
      }
   }
</script>

<style>


</style>